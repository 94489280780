import {useState, useEffect} from "react";
import _throttle from "lodash.throttle";

let supportsPassive = false;
const isBrowser = typeof window !== "undefined";

try {
  var opts = Object.defineProperty({}, "passive", {
    get: function() {
      supportsPassive = true;
      return supportsPassive;
    }
  });
  
  if (isBrowser) {
    window.addEventListener("testPassive", (e: Event) => {
      return opts;
    });
    window.removeEventListener("testPassive", (e: Event) => {
      return opts;
    });
  }
} catch (e) {}

let getPosition = () => ({
  x: isBrowser ? window.pageXOffset : 0,
  y: isBrowser ? window.pageYOffset : 0,
})

let defaultOptions = {
  throttle: 100,
}

export function useWindowScrollPositionSSR(options: any) {
  let opts = Object.assign({}, defaultOptions, options);

  let [position, setPosition] = useState(getPosition());

  useEffect(() => {
    let handleScroll = _throttle(() => {
      setPosition(getPosition());
    }, opts.throttle)

    if (isBrowser) {
        window.addEventListener(
            "scroll",
            handleScroll,
            supportsPassive ? { passive: true } : false
        );
    }

    return () => {
      handleScroll.cancel();
      window.removeEventListener("scroll", handleScroll);
    }
  }, [opts.throttle]);

  return position;
}