import {createContext, useEffect, useState} from "react";
import {isSettingItemActive} from "./utilsGeneral";
import webConfig from "../config";

interface DevModeProps {
    isDevMode: boolean;
    setDevMode: (mode: boolean) => void;
};

export const DevModeContext = createContext<DevModeProps>({
    isDevMode: false,
    setDevMode: (mode: boolean) => {}
});

const DevModeContextProvider = ({children}: {children: React.ReactNode}) => {
  const [isDevModeEnabled, setDevModeEnabled] = useState(false);

  useEffect(() => {
    const checkDevModeState = async () => {
        const devModeState = await isSettingItemActive(webConfig.enableDevModeID);
        setDevModeEnabled(devModeState);
    };

    checkDevModeState();
  }, []);

  const devModeHandler = (mode: boolean) => {
    setDevModeEnabled(mode);
  };

  const contextValue: DevModeProps = {
    isDevMode: isDevModeEnabled,
    setDevMode: devModeHandler
  };

  return (
    <DevModeContext.Provider value={contextValue}>
        {children}
    </DevModeContext.Provider>
  );
};

export default DevModeContextProvider;