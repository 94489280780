import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import PublicModal from "./Modals/PublicModal";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {getCurrentUserInfo, isUserLoginError, getUserLoginErrorMsg, clearUserInfo} from "../../redux-data/user/userReducer";
import {useSelector, useDispatch} from "react-redux";
import {checkAuthToken, getCompanyYear} from "../../utils/utilsGeneral";
import {getEagentToken, clearEagentToken} from "../../redux-data/misc/miscReducer";

const FooterComponent: React.FC = (props) => {
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const isLoginError = useSelector(isUserLoginError);
  const userLoginErrorMsg = useSelector(getUserLoginErrorMsg);
  const eAgentToken = useSelector(getEagentToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("public");
  const [socials, setSocials] = useState<any[]>([]);
  const [socialErrorMsg, setSocialErrorMsg] = useState("");
  const [isAuthToken, setAuthToken] = useState(false);

  const handleToggleModal = (e) => {
    e && e.preventDefault();
    setIsModalOpen((open) => !open);
    setAuthToken(checkAuthToken(eAgentToken));
  };

  const handleLogout = () => {
    setAuthToken(false);
    dispatch(clearEagentToken());
    dispatch(clearUserInfo());
    navigate("/");
  };

  useEffect(() => {
    setAuthToken(checkAuthToken(eAgentToken));   
  }, [isAuthToken, eAgentToken]);

  useEffect(() => {
    fetchAllSocials();
  }, []);

   const fetchAllSocials = async () => {
    try {
        const socialRes = await dbAxiosInstance.get("/social");
        const dbSocialData = socialRes.data;

        if (dbSocialData && dbSocialData.length > 0) {
            setSocials(dbSocialData);
            setSocialErrorMsg("");
        } else {
            setSocials([]);
            setSocialErrorMsg("Помилка БД! Не вдалося завантажити соц. мережі.");
        }
    } catch (error: any) {
        setSocials([]);
        setSocialErrorMsg("Помилка БД! Не вдалося завантажити соц. мережі.");
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 footer-content">
            <ul className="footer-menu">
              <PublicModal isOpen={isModalOpen} onCLose={handleToggleModal} type={modalType} />
              <li>
                <Link to="/public-offer">Публічна оферта</Link>
              </li>
              <li>
                <Link to="/reviews">Відгуки та пропозиції</Link>
              </li>
              <li>
                <Link to="/about">Про компанію</Link>
              </li>
              <li>
                <Link to="/faq">Часті запитання</Link>
              </li>
              <li>
                <Link to="/contact">Контакти</Link>
              </li>
              {isAuthToken && !isLoginError ?
                <>
                  {currentUserInfo?.permissions.salePointAdmin &&
                    <li>
                    {
                      <Link to={{pathname: `/admin/dashboard/${currentUserInfo?.id}/${currentUserInfo?.externalId}`}}>Панель адміністратора</Link>
                    }
                    </li>
                  }
                  <li>
                    <Link to="#"
                      onClick={(e) => {
                        handleLogout();
                      }}>
                      Вихід ({`${currentUserInfo?.lastName} ${currentUserInfo?.firstName} | ${currentUserInfo?.email}`})
                    </Link>
                  </li>
                </>
               :
                <li>
                  <Link
                    to="#"
                    onClick={(e) => {
                      handleToggleModal(e);
                      setModalType("login");
                    }}>
                    Вхід
                  </Link>
                </li>
               }
            </ul>
            {isLoginError && userLoginErrorMsg && <div className="dashboard-error">{userLoginErrorMsg}</div>}
            <div className="copyrightWrapper">
              <div className="copyrightContainer">
                <div className="social">
                {socials.length > 0 ? socials.map((social) =>
                    <li className="social-link" key={social.id}>
                      <a href={social.url} key={social.id} aria-label={social.name} target="_blank" rel="noopener noreferrer"><i className={social.icon_name}></i></a>
                    </li>
                  ) : <div className="social-error">{socialErrorMsg}</div>
                }
                </div>
                <div className="copyrightInfo">
                  <p className="copyright">Copyright &copy; {getCompanyYear()} ПЕСС - Перший Електронний Страховий Сервіс. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
