import React from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import {useMediaQueryContext} from "../../utils/MediaQueryProvider";
import {useParams, Navigate} from "react-router-dom";
import {useStepsContext} from "../../utils/StepsProvider";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import ReactSteps from "rc-steps";
import {InsuranceTypes} from "../../redux-data/offer/offerTypes";

const Steps = (props: any) => {
  const {getStepByID, getFirst} = useStepsContext() as any;
  const {mobileView} = useMediaQueryContext() as any;
  const {id, name} = useParams<{id: string; name: string}>();
  const step = getStepByID(id);

  if (!id) {
      const firstStepID = getFirst();

      if (firstStepID) {
          return <Navigate to={`/${name}/${firstStepID}`} />
      }
  }

  return (
    <div className="home-page-non-title">
      <section id="calc">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <section className="section-white">
                    <section className="section-osago">
                      <p className="subtitle">Більше ніяких папірців, поліс на e-mail <span className="subtitle-green">за 5 хвилин</span></p>
                      {!mobileView &&
                        <ReactSteps labelPlacement="vertical" direction="horizontal" current={step - 1}>
                          <ReactSteps.Step title="Введіть дані автомобіля" />
                          <ReactSteps.Step title="Виберіть пропозицію" />
                          <ReactSteps.Step title="Виберіть документ" />
                          <ReactSteps.Step title="Заповніть документ" />
                          <ReactSteps.Step title="Сплачуйте онлайн" />
                        </ReactSteps>
                      }
                      {step === 1 && <StepOne type={name as InsuranceTypes} />}
                      {step === 2 && <StepTwo type={name as InsuranceTypes} />}
                      {step === 3 && <StepThree type={name as InsuranceTypes} />}
                      {step === 4 && <StepFour type={name as InsuranceTypes} />}
                      {step === 5 && <StepFive type={name as InsuranceTypes} />}
                    </section>
              </section>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </section>
    </div>
  );
};

export default Steps;