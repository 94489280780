import React, {useEffect, useState, useContext} from "react";
import {DevModeContext} from "../../utils/DevModeProvider";
import NumberAutoForm from "../Forms/NumberAutoForm";
import CalculatorForm from "../Forms/CalculatorForm";
import GreenCardDataForm from "../Forms/GreenCardDataForm";
import {useSelector, useDispatch} from "react-redux";
import {getCurrentUser, getCurrentUserInfo} from "../../redux-data/user/userReducer";
import {useNavigate} from "react-router-dom";
import CustomLoader from "../Forms/common/CustomLoader";
import webConfig from "../../config";
import {isSettingItemActive, navToEpolicyValidation, navToGreencardValidation} from "../../utils/utilsGeneral";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {setAnalyticsId} from "../../redux-data/insurance/insuranceReducer";
import {getCurrentDateTime} from "../../utils/utilsDate";
import {axiosInstance} from "../../core/configAxios";

const StepOne = ({type}: {type: "epolicy" | "greencard"}) => {
 const devCtx = useContext(DevModeContext);
 const currentUser = useSelector(getCurrentUser);
 const currentUserInfo = useSelector(getCurrentUserInfo);
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const [numberFormVisibility, setNumberFormVisibility] = useState(true);
 const [calcFormVisiblity, setCalcFormVisibility] = useState(true);
 const [greenCardVisibility, setGreenCardVisibility] = useState(true);
 const [vehicleNum, setVehicleNum] = useState("");
 const [authInProgress, setAuthInProgress] = useState(false);
 const [isEpolicyDisabled, setEpolicyDisabled] = useState(false);
 const [isGreencardDisabled, setGreencardDisabled] = useState(false);

 useEffect(() => {
   const checkCustomerAuth = () => {
      if (currentUser) {
          setAuthInProgress(false);

          if (!currentUser.sessionId) {
              navigate("/");
          }
      } else {
          setAuthInProgress(true);
      }
    };
    
    checkCustomerAuth();
  }, [currentUser, navigate]);

 useEffect(() => {
   const checkEpolicyState = async () => {
      const isEpolicyEnabled = await isSettingItemActive(webConfig.epolicyItemID);
      setEpolicyDisabled(!isEpolicyEnabled);
   };

   const checkGreencardState = async () => {
      const isGreencardEnabled = await isSettingItemActive(webConfig.greencardItemID);
      setGreencardDisabled(!isGreencardEnabled);
   };

   const addAnalyticsData = async () => {
      try {
        const geolocationRes = await axiosInstance.get("/customer/geolocation");
        const userGeolocation = geolocationRes.data;
        const analytics = {...userGeolocation, insuranceStep: 1, insuranceType: type, isPhoneNumber: false, isEmail: false, isVehicle: false, isCustomer: false, added: getCurrentDateTime()};
        const analyticsRes = await dbAxiosInstance.post("/add-customer/analytics", analytics);
        dispatch(setAnalyticsId(analyticsRes.data.insertID));
      } catch (error: any) {}
   };

   addAnalyticsData();
   type === "epolicy" ? checkEpolicyState() : checkGreencardState();
 }, [dispatch, type]);


  const handleOnNumChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
    setVehicleNum(event.target.value);

    if (event.target.name === "register_auto" && event.target.value.length > 0) {
        setNumberFormVisibility(true);
        setCalcFormVisibility(false);
        setGreenCardVisibility(false);
    }
  };

  const handleCalcChange = (event) => {
    if (event.value > 0) {
        setCalcFormVisibility(true);
        setNumberFormVisibility(false);
        setGreenCardVisibility(false);
    }
  };

  const handleGreenCardChange = () => {
    setGreenCardVisibility(true);
    setCalcFormVisibility(false);
    setNumberFormVisibility(false);
  };

  const showCalcForm = () => {
    setCalcFormVisibility(true);
    setNumberFormVisibility(false);
    setGreenCardVisibility(false);
  };

  const showNumForm = () => {
    setNumberFormVisibility(true);
    setCalcFormVisibility(false);
    setGreenCardVisibility(false);
  };

  const showGreenCardForm = () => {
    setGreenCardVisibility(true);
    setNumberFormVisibility(false);
    setCalcFormVisibility(false);
  };

  return (
    <>
      {authInProgress ?
        <CustomLoader className="status-request" isSquare={false} isInfoOn={false} isLoading={authInProgress} />
       :
        type === "epolicy" ?
          isEpolicyDisabled || (devCtx.isDevMode && currentUser && currentUserInfo && currentUser.sessionId && !currentUserInfo?.permissions.salePointAdmin) ?
            <div className="dev-notification"><i className="fa fa-cog fa-spin dev-notification-icon"></i>Страхуваня автоцивілки в даний час недоступно! Тривають технічні роботи. Сервіс буде відновлено найближчим часом.</div>
          :
            <div className={`${numberFormVisibility && greenCardVisibility ? "row" : "row-centered"}`}>
              <div className={`col-md-6 column-left ${numberFormVisibility ? "number-form-visible" : "number-form-hidden"}`}>
                  <div className="column-left_inner">
                      <h4>Розрахувати за номером авто</h4>
                      <p className="column-subtitle">Для зареєстрованих в Україні</p>
                      <NumberAutoForm handleChange={handleOnNumChange} vehicleNumber={vehicleNum} insuranceType={type} />
                      {numberFormVisibility && !calcFormVisiblity && <button className="btn btn-primary" onClick={showCalcForm}>Розрахувати за характеристиками</button>}
                      <button className="btn btn-primary" onClick={navToEpolicyValidation}>Перевірка чинності полісу</button>
                  </div>
              </div>
              <div className={`col-md-6 column-right ${calcFormVisiblity ? "calc-form-visible" : "calc-form-hidden"}`}>
                  <div className="column-right_inner">
                      <h4>Розрахувати за характеристиками авто</h4>
                      <p className="column-subtitle">Введіть характеристики автомобіля</p>
                      <CalculatorForm handleSelect={handleCalcChange} insuranceType={type} />
                      {!numberFormVisibility && calcFormVisiblity && <button className="btn btn-primary" onClick={showNumForm}>Розрахувати за номером</button>}
                      <button className="btn btn-primary" onClick={navToEpolicyValidation}>Перевірка чинності полісу</button>
                  </div>
              </div>
            </div>       
        :
          isGreencardDisabled || (devCtx.isDevMode && currentUser && currentUserInfo && currentUser.sessionId && !currentUserInfo?.permissions.salePointAdmin) ?
              <div className="dev-notification"><i className="fa fa-cog fa-spin dev-notification-icon"></i>Страхуваня зеленої карти в даний час недоступно! Тривають технічні роботи. Сервіс буде відновлено найближчим часом.</div>
            :
              <div className={`${numberFormVisibility && greenCardVisibility ? "row" : "row-centered"}`}>
                <div className={`col-md-6 column-left ${numberFormVisibility ? "number-form-visible" : "number-form-hidden"}`}>
                      <div className="column-left_inner">
                          <h4>Розрахувати за номером авто</h4>
                          <p className="column-subtitle">Для зареєстрованих в Україні</p>
                          <NumberAutoForm handleChange={handleOnNumChange} vehicleNumber={vehicleNum} insuranceType={type} />
                          {numberFormVisibility && !greenCardVisibility && <button className="btn btn-primary" onClick={showGreenCardForm}>Розрахувати за характеристиками</button>}
                          <button className="btn btn-primary" onClick={navToGreencardValidation}>Перевірка чинності полісу</button>
                      </div>
                  </div>
                <div className={`col-md-6 column-right ${greenCardVisibility ? "calc-form-visible" : "calc-form-hidden"}`}>
                    <div className="column-right_inner">
                        <h4>Параметри для розрахунку</h4>
                        <p className="column-subtitle">Заповніть обов'язкові поля</p>
                        <GreenCardDataForm handleSelect={handleGreenCardChange} />
                        {!numberFormVisibility && greenCardVisibility && <button className="btn btn-primary" onClick={showNumForm}>Розрахувати за номером</button>}
                        <button className="btn btn-primary" onClick={navToGreencardValidation}>Перевірка чинності полісу</button>
                    </div>
                </div>
              </div>
      }
   </>
  );
};

export default StepOne;
