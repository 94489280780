import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {IVehicleActions, IVehicleState, IVehicleData} from "./vehicleTypes";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {getKindByType} from "../../core/typeAuto";
import {findVehicleBrandByModel, isElectricMotorVehicle} from "../../utils/utilsGeneral";
import serviceEAgent from "../../service/serviceEAgent";

const init: IVehicleState = {
  data: null, 
  error: false, 
  isLoading: false
};

const creator = new CreatorReducer<IVehicleActions, IVehicleState>("vehicle");
creator.addAction("SET_VEHICLE_SPECS", 
(state, action) => {
  return {...state, data: action.payload}
});
creator.addAction("VEHICLE_CLEAR", (state) => {
  return init;
});

const {setLoading, setData, setError, setVehicleSpecs, vehicleClear} = creator.createActions();

const getVehicle = (regNumber: string) => async (dispatch: any) => {
  dispatch(setLoading(true));

  try {
    const vehicleRes = await serviceEAgent.getVehicle(regNumber);

    if (vehicleRes && vehicleRes.length > 0) {
        const [vehicle] = vehicleRes;

        if (vehicle.model) {
            const vehicleData: IVehicleData | null = {
              kind: vehicle.model?.kind,
              type: vehicle.category,
              regNumber: vehicle.stateNumber.toUpperCase(),
              vin: vehicle.bodyNumber,
              description: (vehicle.description || "").trim(),
              modelName: vehicle.model.name,
              brandName: `${vehicle.autoMaker} ${vehicle.autoModel}`,
              name: vehicle.model!.autoMaker!.name,
              year: vehicle.year,
              id: vehicle.model!.autoMaker!.id,
              engineVolume: vehicle.engineVolume,
              autoColor: vehicle.autoColor,
              dontHaveBodyNumber: vehicle.dontHaveBodyNumber,
              lastModified: vehicle.model.lastModified,
              curbWeight: vehicle.curbWeight,
              grossWeight: vehicle.grossWeight,
              seatingCapacity: vehicle.seatingCapacity,
              electricMotorPower: isElectricMotorVehicle(vehicle.category) && vehicle.electricMotorPower ? vehicle.electricMotorPower : 0,
              registrationPlace: vehicle.registrationPlace ? vehicle.registrationPlace : null
            };

            dispatch(setVehicleSpecs(vehicleData));
        } else {
            if (vehicle.autoMaker && vehicle.autoModel) {
                const brandName = `${vehicle.autoMaker} ${vehicle.autoModel}`
                const vehicleBrand = await findVehicleBrandByModel(brandName);

                if (vehicleBrand) {
                    const parsedBrand = JSON.parse(vehicleBrand.auto_maker);

                    const vehicleData: IVehicleData | null = {
                      kind: getKindByType(vehicle.category),
                      type: vehicle.category,
                      regNumber: vehicle.stateNumber.toUpperCase(),
                      vin: vehicle.bodyNumber,
                      description: (vehicle.description || "").trim(),
                      modelName: vehicle.autoModel,
                      brandName: brandName,
                      name: parsedBrand.name,
                      year: vehicle.year,
                      id: parsedBrand.id,
                      engineVolume: vehicle.engineVolume,
                      autoColor: vehicle.autoColor,
                      dontHaveBodyNumber: vehicle.dontHaveBodyNumber,
                      lastModified: parsedBrand.lastModified,
                      curbWeight: vehicle.curbWeight,
                      grossWeight: vehicle.grossWeight,
                      seatingCapacity: vehicle.seatingCapacity,
                      electricMotorPower: isElectricMotorVehicle(vehicle.category) && vehicle.electricMotorPower ? vehicle.electricMotorPower : 0,
                      registrationPlace: vehicle.registrationPlace ? vehicle.registrationPlace : null
                    }

                    dispatch(setVehicleSpecs(vehicleData));
                } else {
                    dispatch(setError("Транспортний засіб не знайдено, скористайтесь розрахунком за характеристиками"));
                }
          } else {
              dispatch(setError("Транспортний засіб не знайдено, скористайтесь розрахунком за характеристиками"));
          }
       }
    } else {
        const dbVehicleRes = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(regNumber)}`);
        const dbVehicleData = dbVehicleRes.data;

        if (dbVehicleData && dbVehicleData.length > 0) {
            const [dbVehicle] = dbVehicleData;
            const vehicleRegLocationRes = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(dbVehicle.reg_place_id)}/${encodeURIComponent(dbVehicle.reg_place_code)}`);
            const [regPlaceData] = vehicleRegLocationRes.data;

            const vehicleData: IVehicleData | null = {
              kind: dbVehicle.kind,
              type: dbVehicle.category,
              regNumber: dbVehicle.state_number,
              vin: dbVehicle.vin,
              description: "",
              modelName: dbVehicle.model,
              brandName: dbVehicle.model_text,
              name: dbVehicle.brand,
              year: dbVehicle.year,
              id: dbVehicle.id,
              engineVolume: dbVehicle.engine_volume,
              autoColor: dbVehicle.auto_color,
              dontHaveBodyNumber: dbVehicle.dont_have_vin,
              lastModified: dbVehicle.last_modified,
              curbWeight: dbVehicle.curb_weight,
              grossWeight: dbVehicle.gross_weight,
              seatingCapacity: dbVehicle.seating_capacity,
              electricMotorPower: dbVehicle.electric_motor_power,
              registrationPlace: {
                id: regPlaceData?.id,
                placeCode: regPlaceData?.place_code,
                zone: regPlaceData?.zone, 
                nameFull: regPlaceData?.name_full, 
                country: regPlaceData?.country,
                name: regPlaceData?.name,
                nameRus: regPlaceData?.name_rus, 
                nameFullRus: regPlaceData?.name_full_rus, 
                cdbMtibu: regPlaceData?.cdb_mtibu,
                cdbMtibuCode: regPlaceData?.cdb_mtibu_code,
                lastModified: regPlaceData?.last_modified
              }
            }

            dispatch(setVehicleSpecs(vehicleData));
        } else {
            dispatch(setError("Транспортний засіб не знайдено, скористайтесь розрахунком за характеристиками"));
        }
    }
  } catch (e: any) {
    dispatch(setError(e.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const getVehicleData = (state: RootState) => state.vehicle.data!;
const getVehicleLoading = (state: RootState) => state.vehicle.isLoading;
const getVehicleAutoType = (state: RootState) => state.vehicle.data!.type;
const getVehicleError = (state: RootState) => state.vehicle.error;
const getVehicleMark = (state: RootState) => state.vehicle.data!.brandName;
const getVehicleModel = (state: RootState) => state.vehicle.data!.modelName;
const getVehicleAutoKind = (state: RootState) => state.vehicle.data!.kind;

export {
  setData,
  getVehicle,
  getVehicleData,
  getVehicleLoading,
  getVehicleAutoType,
  getVehicleError,
  getVehicleMark,
  getVehicleModel,
  getVehicleAutoKind,
  setVehicleSpecs,
  vehicleClear
};
export default creator.createReducerFetch();