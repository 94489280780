import React from "react";
import {dialPhoneNumber, formatPhoneNumber, formatEmail} from "../../utils/utilsGeneral";
import webConfig from "../../config";

const Contact = () => {
  const adminPhoneNumbers = webConfig.adminPhoneNumbers!.split(", ");

  return (
    <div className="container">
      <div className="section-white">
        <h3>Адреса:</h3>
        <br />
        <div>03055, проспект Перемоги 33, офіс 2, м. Київ, Україна</div>
        <br />
        <h3>Телефон: </h3>
        <br />
        <div>
        <div dangerouslySetInnerHTML={{__html: dialPhoneNumber(formatPhoneNumber(adminPhoneNumbers[0]))}} />
        <br />
        <div dangerouslySetInnerHTML={{__html: dialPhoneNumber(formatPhoneNumber(adminPhoneNumbers[1]))}} />
        <br />
        <div dangerouslySetInnerHTML={{__html: dialPhoneNumber(formatPhoneNumber(adminPhoneNumbers[2]))}} />
        </div>
        <br />
        <h3>E-mail:</h3>
        <br />
        <div dangerouslySetInnerHTML={{__html: formatEmail("pessorgua@gmail.com")}} />
        <br />
        <h3>Графік роботи:</h3>
        <br />
        <div>Понеділок - П'ятниця з 09:00 до 18:00</div>
        <br />
        <div>Субота та Неділя - вихідні</div>
      </div>
    </div>
  );
};

export default Contact;
