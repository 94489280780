import moment from "moment";

export const getMaxDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${date.getFullYear()}-${month}-${day}`;
};

export const getShortDate = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return ` ${day}.${month}.${date.getFullYear()} `;
};

export const getDay = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};

export const getTodayDay = () => {
  const date = new Date();
  date.setDate(date.getDate());
  return date;
};

export const getTodayDate = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${date.getFullYear()}-${month}-${day}`;
};

export const getCorrectDate = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${date.getFullYear()}-${month}-${day}`;
};

export const getCorrectDateDTO = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate() + 1).padStart(2, "0");
  return `${date.getFullYear()}-${month}-${day}T00:00:00.000+0000`;
};

export const getCorrectDateTo = (date: Date) => {
  const year = date.getFullYear() + 1;
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate() - 1).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getCorrectDateFromFull = (date: Date) => {
  const validDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  return moment.utc(validDate).utcOffset("+0000").format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
};

export const getCorrectDateToFull = (date: Date) => {
  const validDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  return moment.utc(validDate).utcOffset("+0000").format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
};

export const getInsuranceStartFromDate = () => {
  return getCorrectDate(getDay());
};

export const getExpirationDate = () => {
  return getCorrectDate(getTodayDay());
};
 
export const getStartFromDate = () => {
  return getCorrectDate(getTodayDay());
};

export const getFinishToDate = () => {
  return getCorrectDateTo(getDay());
};

export const getDaysDate = (date: Date, days: number) => {
  const current = new Date(date);
  current.setDate(date.getDate() + days);
  return current;
};

export const getMonthsDate = (date: Date, months: number) => {
  const current = new Date(date);
  current.setMonth(date.getMonth() + months);
  return current;
};

export const getYearsDate = (date: Date, years: number) => {
  const current = new Date(date);
  current.setFullYear(date.getFullYear() + years);
  return current;
};

export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const getCurrentDateTime = () => {
  return moment().format("YYYY-MM-DD HH:mm:ss.SSS");
};

export const isDateValid = (date) => {
  return moment().isSameOrBefore(date, "day");
};

export const validateDBDate = (date) => {
  const dbDate = moment(date);
  return dbDate.isValid() ? dbDate.format("YYYY-MM-DD") : "";
};

export const validateDBDateTime = (date) => {
  const dbDateTime = moment(date);
  return dbDateTime.isValid() ? dbDateTime.format("YYYY-MM-DD HH:mm:ss.SSS") : "";
};

export const formatDBDate = (date) => {
  const options: Intl.DateTimeFormatOptions = {year: "numeric", month: "long", day: "numeric"};
  const formattedDate = new Date(date).toLocaleDateString("uk-UA", options);
  return formattedDate;
};

export const formatDBDateTime = (datetime) => {
  const options: Intl.DateTimeFormatOptions = {year: "numeric", month: "long", day: "numeric"};
  const formattedDateTime = new Date(datetime).toLocaleTimeString("uk-UA", options);
  return formattedDateTime;
};

export const getPolicyExpirationDate = (date) => {
  const expirationDate = moment(date);
  return expirationDate.isValid() ? expirationDate.diff(moment().startOf("day"), "days") : null;
};

export const getCurrentUtcDate = () => {
  return Date.now();
};

export const compareOtkDate = (date) => {
  const currentDate = moment();
  const selectedDate = moment(date);
  return selectedDate >= currentDate;
};

export const getYearFromDate = (date) => {
  return moment(date).year();
};

export const validateDate = (date: string) => {
  let docDate = moment(date, "DD.MM.YYYY", true);

  if (!docDate.isValid()) {
      docDate = moment(date);
  }

  return docDate;
};

export const validateDateTime = (datetime: string) => {
  const lastDotIndex = datetime.lastIndexOf(".");
  const hasFractionalPart = lastDotIndex !== -1;
  const fractionalPart = hasFractionalPart ? datetime.substring(lastDotIndex + 1) : "";
  const format = hasFractionalPart ? (fractionalPart.length > 2 ? "DD.MM.YYYY HH:mm:ss.SSS" : "DD.MM.YYYY HH:mm:ss.SS") : "DD.MM.YYYY HH:mm:ss";
  let docDateTime = moment(datetime, format, true);

  if (!docDateTime.isValid()) {
      docDateTime = moment(datetime);
  }

  return docDateTime;
};

export const validateAndFormatDate = (date: string) => {
  return date ? validateDate(date).format("YYYY-MM-DD") : "";
};

export const validateAndFormatDateTime = (datetime: string) => {
  return datetime ? validateDateTime(datetime).format(datetime.split(".")[1].length > 2 ? "YYYY-MM-DD HH:mm:ss.SSS" : "YYYY-MM-DD HH:mm:ss.SS") : "";
};

export const getCurrentEAgentDate = () => {
  const currentDate = moment().format("DD.MM.YYYY");
  return `${currentDate}р.`;
};

export const getDateFromUtc = (utc: string) => {
  const utcDateTime = parseInt(utc, 10);
  return moment(utcDateTime).format("YYYY-MM-DDTHH:mm:ss");
};

export const getLiqPayExpirationDate = () => {
  return moment().format("YYYY-MM-DD 23:57:59");
};

export const getPortmoneExpirationDate = () => {
  return moment().format("YYYYMMDD235759");
};

export const calculatePolicyDate = (date: Date, type: string, value: number) => {
    let policyDate: Date;

    switch (type) {
      case "DAYS":
        policyDate = getDaysDate(date, value);
        break;
      case "MONTHS":
        policyDate = getMonthsDate(date, value);
        break;
      default:
        policyDate = new Date();
    }

    return policyDate;
};