import React, {useEffect, useState, useCallback} from "react";
import {useNavigate, useParams, Link} from "react-router-dom"; 
import {formatPhoneNumber, getInsuranceTypeName, filterRecords, countRecords, validatePhoneNumber, isMatchCaseSearch} from "../../../utils/utilsGeneral";
import {insuranceTypes} from "../../../mock-data/insuranceTypes";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import FilterPagination from "../../../utils/FilterPagination";
import webConfig from "../../../config";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import DataFilter from "../../../utils/DataFilter";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import {ICustomer} from "../../../redux-data/user/userType";

const CustomersGreencardPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [customersCount, setCustomersCount] = useState(0);
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [customersErrorMsg, setCustomersErrorMsg] = useState("");
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);
    const greencardType = insuranceTypes[1].value;

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const res = await dbAxiosInstance.get(`/customers/${encodeURIComponent(greencardType)}`);
                const dbCustomersData = res.data;

                if (dbCustomersData) {
                    if (dbCustomersData.length > 0) {
                        const customers: ICustomer[] = dbCustomersData.map((customer) => {
                            return {
                                id: customer.id,
                                phoneNumber: customer.phone_number,
                                email: customer.email,
                                firstName: customer.first_name,
                                middleName: customer.middle_name,
                                lastName: customer.last_name,
                                birthDate: customer.birth_date,
                                innCode: customer.inn_code,
                                dontHaveInnCode: customer.dont_have_inn_code,
                                isPrivilege: customer.is_privilege,
                                addressSettlementName: customer.address_settlement_name,
                                addressStreet: customer.address_street,
                                addressHouseNumber: customer.address_house_number,
                                addressApartmentNumber: customer.address_apartment_number,
                                vehicleStateNumber: customer.vehicle_state_number,
                                insuranceType: customer.insurance_type,
                                lastModified: customer.last_modified,
                                authCode: customer.auth_code
                            };
                        });
                        setCustomers(customers);
                        setFilteredItems(customers);
                        setCustomersErrorMsg("");
                    } else {
                        setCustomers([]);
                        setFilteredItems([]);
                        setCustomersErrorMsg("Немає доданих даних клієнтів. Натисніть на кнопку, щоб додати дані клієнта.");
                    }
                } else {
                    setCustomers([]);
                    setFilteredItems([]);
                    setCustomersErrorMsg("Помилка БД! Не вдалося завантажити дані клієнтів.");
                }
            } catch (error: any) {
                setCustomers([]);
                setFilteredItems([]);
                setCustomersErrorMsg("Помилка БД! Не вдалося завантажити дані клієнтів.");
            }
        };

        const countCustomers = async () => {
            try {
                const res = await dbAxiosInstance.get(`/count-customers/${encodeURIComponent(greencardType)}`);
                const dbCountCustomersData = res.data;

                if (dbCountCustomersData) {
                    if (dbCountCustomersData.length > 0) {
                        setCustomersCount(dbCountCustomersData[0].counter);
                    } else {
                        setCustomersCount(0);
                    }
                } else {
                    setCustomersCount(0);
                }
            } catch (error: any) {
                setCustomersCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };

        fetchCustomers();
        countCustomers();
        checkSearchType();
    }, [greencardType]);

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/customer/${encodeURIComponent(id)}`);
            await dbAxiosInstance.delete(`/vehicle-owner/customer/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            setFilteredItems(filterRecords(filteredItems, id));
            setCustomers(filterRecords(customers, id));
            const allRecords = countRecords(customersCount);
            setCustomersCount(allRecords);

            if (allRecords === 0) {
                setCustomersErrorMsg("Немає доданих даних клієнтів. Натисніть на кнопку, щоб додати дані клієнта.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setCustomersErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setCustomers([]);
        }
    };

    const handleRecordsOnPage = useCallback((records) => {
        setRecordsOnPage(records);
    }, []);

    const navToAddCustomersPage = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/add-customer/${greencardType}`);
    };

    const handleDataLoaded = useCallback((state: boolean) => {
        setFilterCompleted(state);
    }, []);

    const handleFilterState = useCallback((state: boolean) => {
        setProcessingFilter(state);
    }, []);

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                        <CustomTooltip msg="Перейти до Клієнтів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers`}}>Клієнти</Link></CustomTooltip> &laquo; Зелена карта</h1>
                    <div className="itemsCounter">
                        <button type="button" className="admin-btn" onClick={navToAddCustomersPage}><i className="fa fa-plus notification-icon"></i>Додати клієнта</button>
                        <p>Клієнтів ({getInsuranceTypeName(greencardType)}): <span className="font-bold">{customersCount}</span></p>
                    </div>
                    <DataFilter items={customers} matchCase={isMatchCaseFilter} placeholderText="Введіть дані клієнта для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом такого клієнта не знайдено!" />
                    <div className="dashboard">
                        {customers.length > 0 && customersCount > 0 ?
                            <div className="customers-wrapper">
                                <table className="customers">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Телефон</th>
                                        <th>Email</th>
                                        <th>Прізвище</th>
                                        <th>Iм'я</th>
                                        <th>Номер ТЗ</th>
                                        <th>Управління</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordsOnPage.map((customer, index) =>
                                        <tr key={index}>
                                            <td>{customer.id}</td>
                                            <td><CustomTooltip msg="Натисність, щоб переглянути деталі клієнта"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customer/${greencardType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/view`}}>{formatPhoneNumber(customer.phoneNumber)}</Link></CustomTooltip></td>
                                            <td><CustomTooltip msg="Натисність, щоб переглянути деталі клієнта"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customer/${greencardType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/view`}}>{customer.email}</Link></CustomTooltip></td>
                                            <td><CustomTooltip msg="Натисність, щоб переглянути деталі клієнта"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customer/${greencardType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/view`}}>{customer.lastName}</Link></CustomTooltip></td>
                                            <td><CustomTooltip msg="Натисність, щоб переглянути деталі клієнта"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customer/${greencardType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/view`}}>{customer.firstName}</Link></CustomTooltip></td>
                                            <td><CustomTooltip msg="Натисність, щоб переглянути деталі клієнта"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customer/${greencardType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/view`}}>{customer.vehicleStateNumber}</Link></CustomTooltip></td>
                                            <td>
                                                <div className="manage-item-container">
                                                    <CustomTooltip msg="Редагувати">
                                                        <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/customer/${greencardType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/edit`}}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Link>
                                                    </CustomTooltip>
                                                    <CustomTooltip msg="Переглянути деталі клієнта">
                                                        <Link className="view-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/customer/${greencardType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/view`}}>
                                                            <i className="fa fa-circle-info"></i>
                                                        </Link>
                                                    </CustomTooltip>
                                                    <CustomTooltip msg="Видалити"><Link to="#" className="delete-item" onClick={() => handleItemDelete(customer.id)}><i className="fa fa-trash"></i></Link></CustomTooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                </table>
                                <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={handleDataLoaded} isFilterProcessing={processingFilter} setFilterState={handleFilterState} />
                            </div>
                            : <div>
                                <div className="dashboard-item">
                                    <CustomTooltip msg="Додати"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/add-customer`}}><i className="fa fa-plus"></i></Link></CustomTooltip>
                                </div>
                                <div className="no-dashboard-item">{customersErrorMsg}</div>
                            </div>
                        }
                    </div>
                </div>
                </>
            }
            </div>
        </div>
    );
};

export default CustomersGreencardPage;