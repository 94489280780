import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getCurrentUser, getCurrentUserInfo} from "../redux-data/user/userReducer";
import {getEagentToken} from "../redux-data/misc/miscReducer";
import {checkAuthToken, handleLogout} from "../utils/utilsGeneral";
import {NavigateFunction} from "react-router-dom";
import serviceEAgent from "../service/serviceEAgent";

const useDashboardAuth = (dashboardNavigate: NavigateFunction) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dispatch = useDispatch();
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = async () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    const userDataRes = await serviceEAgent.getCustomerInfo(currentUser.user.id!);
                    setAuthInProgress(false);

                    if (!userDataRes.permissions.salePointAdmin) {
                        handleLogout(dispatch, dashboardNavigate);
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo, dashboardNavigate, eAgentToken, dispatch]);

    return authInProgress;
};

export default useDashboardAuth;