import {axiosInstance} from "../core/configAxios";
import {insuranceProxyAxiosInstance} from "../core/configInsuranceProxyAxios";

 const fetchToken = async () => {
   const diiaResult = await axiosInstance.get("/api/diia/token");
   return diiaResult.data;
 };

 const fetchBranchID = async (token, selectedDocs) => {
    const diiaResult = await axiosInstance.post("/api/diia/branch", {
        "token": token,
        "documents": selectedDocs
    });
    return diiaResult.data;
 };

 const fetchOfferID = async (token, branchID, insuranceType, selectedDocs) => {
   const diiaResult = await axiosInstance.post("/api/diia/offer", {
        "token": token,
        "branchID": branchID,
        "insuranceType": insuranceType,
        "documents": selectedDocs
    });
    return diiaResult.data;
 };

 const fetchDeepLink = async (token, branchID, offerID, requestID) => {
   const diiaResult = await axiosInstance.post("/api/diia/deeplink", {
      "token": token,
      "branchID": branchID,
      "offerID": offerID,
      "requestID": requestID
   });
   return diiaResult.data;
 };

 const fetchDocs = async (diiaTokenSourceRef) => {
   const diiaResult = await insuranceProxyAxiosInstance.get(`/api/diia/retrieve-data`, {
      cancelToken: diiaTokenSourceRef.current.token
   });
   return diiaResult.data;
 };

const serviceDiia = {
   fetchToken,
   fetchBranchID,
   fetchOfferID,
   fetchDeepLink,
   fetchDocs
};

export default serviceDiia;