import React, {useEffect} from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="section-white">
        <div>
          Компанія «Перший електронний страховий сервіс» - це унікальна страхова
          платформа, котра надає можливість швидко та зручно оформити
          страхування вашого транспортного засобу всього за декілька хвилин!
        </div>
        <br />
        <h3>Про можливості та переваги електронного страхування</h3>
        <br />
        <div>
          Починаючи з 2018 року українським законодавством передбачена
          можливість оформлювати страхові поліси транспортних засобів в
          електронній формі.
        </div>
        <br />
        <div>
          Це дає змогу уникнути зайвої бюрократичної роботи зі старими
          паперовими полісами , значно заощадити Ваш час, а також завжди мати
          страховий поліс у Вашому смартфоні!
        </div>
        <br />
        <h3>Широкий асортимент страхових компаній</h3>
        <br />
        <div>
          Одна з головних переваг електронного страхування на нашій платформі -
          можливість самостійно обирати страхову компанію, котрій Ви довіряєте
          найбільше і чиї умови та вартість Вас влаштує!
        </div>
        <br />
        <div>
          Платформа пропонує до Вашого вибору більше 20-ти страхових компаній.
        </div>
        <br />
        <h3>Гарантія та форма полісу</h3>
        <br />
        <div>
          Поліс формується в форматі PDF і володіє унікальним номером, який
          фіксується в базі МТСБУ. Після покупки діюча страховка висилається на
          e-mail
        </div>
        <br />
        <h3>Як це працює?</h3>
        <br />
        <div>
          Для оформлення страхового полісу клієнту потрібно лише ввести
          реєстраційний номер автомобіля, після чого сервіс самостійно обробляє
          всі данні Вашого транспортного засобу. Після цього залишиться ввести
          деякі персональні контактні данні, оплатити страховий поліс та
          отримати його на електронну адресу. Весь процес займає не більше
          декількох хвилин!
        </div>
        <br />
        <h3>Оплата</h3>
        <br />
        <div>
          Оплата електронного страхового полісу здійснюється після того, як
          клієнт обирає страховий поліс та компанію-страховика і вказує свої
          контактні дані.
        </div>
        <br />
        <div>
          Оплатити страховий поліс можна за допомогою банківської карти.
        </div>
        <br />
        <h3>Зворотній зв’язок</h3>
        <br />
        <div>
          Фахівці страхової платформи «Перший електронний страховий сервіс»
          готові з ентузіазмом допомагати Вам як онлайн, так і в телефонному
          режимі у випадку виникнення у Вас будь-яких складнощів при оформленні
          страхового полісу!
        </div>
        <br />
        <h3>Будь у тренді!</h3>
        <br />
        <div>
          Ми живемо в еру високих цифрових технологій, а відтак давно вже час
          забути про паперові страхові поліси та почати користуватися новими
          можливостями!
        </div>
      </div>
    </div>
  );
};

export default About;
