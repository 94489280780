import React, {useEffect, useState} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {navToAdminPanel, navToEpolicyCompaniesPage, navToGreencardCompaniesPage, refreshPage, navToEpolicyCompany} from "../../../utils/utilsGeneral";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {companyImage, companyImages} from "../../../constants/companyImages";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {IEpolicyFranchise} from "../../../typings/IDataOffers";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const ViewEpolicyFranchise = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, epolicyID, productID, franchiseID} = useParams<{
        id: string; 
        externalID: string; 
        epolicyID: string;
        productID: string; 
        franchiseID: string;
    }>();
    const [epolicyFranchiseData, setEpolicyFranchiseData] = useState<IEpolicyFranchise[]>([]);
    const [epolicyFranchiseErrorMsg, setEpolicyFranchiseErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);

    useEffect(() => {
        const fetchEpolicyFranchise = async () => {
            try {
                const res = await dbAxiosInstance.get(`/epolicy/franchise/${encodeURIComponent(productID!)}/${encodeURIComponent(franchiseID!)}`);
                const dbEpolicyFranchiseData = res.data;

                if (dbEpolicyFranchiseData) {
                    if (dbEpolicyFranchiseData.length > 0) {
                        const epolicyFranchises: IEpolicyFranchise[] = dbEpolicyFranchiseData.map((item) => {
                            return {
                                id: item.id, 
                                companyName: item.company_name,
                                franchiseName: item.franchise_name,
                                productID: item.product_id,
                                franchise: item.franchise,
                                franchiseID: item.franchise_id,
                                isHidden: Boolean(item.is_hidden)
                            };
                        });

                        setEpolicyFranchiseData(epolicyFranchises);
                        setEpolicyFranchiseErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setEpolicyFranchiseData([]);
                        setEpolicyFranchiseErrorMsg("Немає такої франшизи страхової компанії автоцивілки.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setEpolicyFranchiseData([]);
                    setEpolicyFranchiseErrorMsg("Помилка БД! Не вдалося завантажити франшизу страхової компанії автоцивілки.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setEpolicyFranchiseData([]);
                setEpolicyFranchiseErrorMsg("Помилка БД! Не вдалося завантажити франшизу страхової компанії автоцивілки.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchEpolicyFranchise();
    }, [franchiseID, productID]);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editEpolicyFranchise = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/epolicy/company/edit-franchise/${epolicyID}/${productID}/${franchiseID}`);
    };

    const deleteEpolicyFranchise = async (prodID, id) => {
        try {
            await dbAxiosInstance.delete(`/epolicy/franchise/${encodeURIComponent(prodID)}/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/epolicy/view-company/${prodID}/${epolicyID}`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setEpolicyFranchiseErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setEpolicyFranchiseData([]);
        }
    };

    const epolicyFranchiseImg = companyImages.filter((company: companyImage) => epolicyFranchiseData.find((item) => company.id === item.productID));

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    {epolicyFranchiseData.length > 0 && epolicyFranchiseData.map((epolicyFranchise) => (
                        <div className="company-info-container" key={epolicyFranchise.id}>
                            <h1 className="company-title">Франшиза &#8212; {epolicyFranchise.franchiseName}</h1>
                            <div className="company-logo">
                                <img src={epolicyFranchiseImg.length > 0 ? require(`../../../assets/img/logos/${epolicyFranchiseImg[0].key}`).default : "\\img\\base.webp"} alt="" />
                            </div>
                            <p className="item-title">Назва компанії: <span className="font-bold">{epolicyFranchise.companyName}</span></p>
                            <p className="item-title">Назва франшизи: <span className="font-bold">{epolicyFranchise.franchiseName}</span></p>
                            <p className="item-title">Ідентифікатор продукту: <span className="font-bold">{epolicyFranchise.productID}</span></p>
                            <p className="item-title">Франшиза: <span className="font-bold">{`${epolicyFranchise.franchise} грн`}</span></p>
                            <p className="item-title">Ідентифікатор франшизи: <span className="font-bold">{epolicyFranchise.franchiseID}</span></p>
                            <p className="item-title">Є прихованою: <span className="font-bold">{epolicyFranchise.isHidden ? <span className="company-hidden">Так</span> : <span className="company-visible">Ні</span>}</span></p>
                        </div>
                    ))}
                    <div className="manage-company">
                        <button type="submit" className="admin-btn" onClick={editEpolicyFranchise}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => navToEpolicyCompany(dashboardNavigate, id, externalID, productID, epolicyID)}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути компанію</button>
                        <button type="button" className="admin-btn" onClick={() => deleteEpolicyFranchise(productID, franchiseID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={() => navToEpolicyCompaniesPage(dashboardNavigate, id, externalID)}>Автоцивілка</button>
                        <button type="button" className="admin-btn" onClick={() => navToGreencardCompaniesPage(dashboardNavigate, id, externalID)}>Зелена карта</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                </div>
                {!hideErrorNotification && epolicyFranchiseErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{epolicyFranchiseErrorMsg}</div>}
                </>
            }
            </div>
        </div>
    );
};

export default ViewEpolicyFranchise;