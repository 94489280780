import React, {useEffect} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import HeaderComponent from "./components/common/HeaderComponent";
import FooterComponent from "./components/common/FooterComponent";
import routes from "./routes";

const App = (props: any) => {
  const {pathname} = useLocation();

  useEffect(() => {
    if (pathname === "/" || !window || (window && !window.document)) {
        return;
    }
    
    const el = window && window.document.querySelector(".section-main")!;

    if (el) {
        window.scroll({
          behavior: "smooth",
          top: el.getBoundingClientRect().height + 90
        });
    }
  }, [pathname]);

  return (
    <div className="App">
      <HeaderComponent {...props} />
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={React.createElement(route.component)} />
          ))}
        </Routes>
      <FooterComponent/>
    </div>
  );
};

export default App;