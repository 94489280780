import React, {useEffect, useState, useCallback} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {
    getInsuranceType, 
    getPrivilegeType, 
    navToAdminPanel, 
    refreshPage, 
    navToCustomersPage, 
    navToCustomersEpolicyPage, 
    navToCustomersGreencardPage,
    checkValue,
    validateINNCode,
    convertINNToBirthDate,
    validatePhoneNumber
} from "../../../utils/utilsGeneral";
import CustomSelect from "../../../components/common/CustomSelect";
import CustomField from "../../../components/MainPage/CustomField";
import useForm from "react-hook-form";
import {patternEmail} from "../../../utils/getInstanceError";
import {insuranceTypes, privilegeTypes, websiteDocuments} from "../../../mock-data/insuranceTypes";
import {getMaxDate, getCurrentDateTime, validateDBDate} from "../../../utils/utilsDate";
import PassportPartForm from "../../../components/MainPage/PassportPartForm";
import IDCardPartForm from "../../../components/MainPage/IDCardPartForm";
import DriverLicensePartForm from "../../../components/MainPage/DriverLicensePartForm";
import PensionCertificateForm from "../../../components/MainPage/PrivilegeForms/PensionCertificate";
import EPensionCertificateForm from "../../../components/MainPage/PrivilegeForms/EPensionCertificate";
import DisabilityCertificateForm from "../../../components/MainPage/PrivilegeForms/DisabilityCertificate";
import VeteranCertificateForm from "../../../components/MainPage/PrivilegeForms/VeteranCertificate";
import ChernobylCertificateForm from "../../../components/MainPage/PrivilegeForms/ChernobylCertificate";
import {CustomerDocument, ICustomer} from "../../../redux-data/user/userType";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import webConfig from "../../../config";
import SwitchComponent from "react-switch";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import ExternalPassportPartForm from "../../../components/MainPage/ExternalPassportPartForm";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CombatCertificateForm from "../../../components/MainPage/PrivilegeForms/CombatCertificate";
import WarDisabilityCertificateForm from "../../../components/MainPage/PrivilegeForms/WarDisabilityCertificate";
import RevolutionCertificateForm from "../../../components/MainPage/PrivilegeForms/RevolutionCertificate";

const AddCustomerPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, insuranceType} = useParams<{
        id: string;
        externalID: string;
        insuranceType: string;
    }>();
    const [currentInsuranceType, setCurrentInsuranceType] = useState(getInsuranceType(insuranceType!) || "");
    const [isPrivilegeChecked, setPrivilegeChecked] = useState(false);
    const [selectedCustomerDocument, setSelectedCustomerDocument] = useState("PASSPORT");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [customerErrorMsg, setCustomerErrorMsg] = useState("");
    const [customerSuccessMsg, setCustomerSuccessMsg] = useState("");
    const [isCustomerAdded, setCustomerAdded] = useState(false);
    const [customer, setCustomer] = useState<ICustomer>({
        phoneNumber: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
        innCode: "",
        dontHaveInnCode: false,
        addressSettlementName: "",
        addressStreet: "",
        addressHouseNumber: "",
        addressApartmentNumber: "",
        insuranceType: currentInsuranceType.value,
        lastModified: null,
        authCode: null
    });
    const [customerDocument, setCustomerDocument] = useState<CustomerDocument>({
        type: selectedCustomerDocument,
        series: "",
        number: "",
        issuedDate: null,
        expirationDate: null,
        issuedBy: ""
    });
    const [customerBirthDate, setCustomerBirthDate] = useState("");
    const [invalidInnMsg, setInvalidInnMsg] = useState("");
    const [isINNInputDisabled, setINNInputDisabled] = useState(false);

    const {register, errors, clearError} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const handleInsuranceTypesChange = (type) => {
        setCurrentInsuranceType(type);
        setCustomer((prev) => ({...prev, insuranceType: checkValue(type.value)}));
    };

    const handlePhoneNumberChange = (event) => {
        setCustomer((prev) => ({...prev, phoneNumber: checkValue(event.target.value)}));
    };

    const handleEmailChange = (event) => {
        setCustomer((prev) => ({...prev, email: checkValue(event.target.value)}));
    };

    const handleLastNameChange = (event) => {
        setCustomer((prev) => ({...prev, lastName: checkValue(event.target.value)}));
    };

    const handleNameChange = (event) => {
        setCustomer((prev) => ({...prev, firstName: checkValue(event.target.value)}));
    };

    const handleMiddleNameChange = (event) => {
        setCustomer((prev) => ({...prev, middleName: checkValue(event.target.value)}));
    };

    const handleBirthDateChange = useCallback((name, date) => {
        setCustomer((prev) => ({...prev, birthDate: validateDBDate(date)}));
        clearError(name);
    }, [clearError]);

    const handleInnCodeChange = (event) => {
        const innCode = event.target.value;
    
        if (innCode.length === Number(webConfig.customerINNCodeLength)) {
            if (validateINNCode(innCode)) {
                const {year, month, day} = convertINNToBirthDate(innCode);
                const birthDateFromInn = `${year}-${month}-${day}`;
                setCustomerBirthDate(birthDateFromInn);
                setInvalidInnMsg("");
                setCustomer((prev) => ({...prev, innCode: checkValue(innCode), dontHaveInnCode: false, birthDate: validateDBDate(birthDateFromInn)}));
            } else {
                setInvalidInnMsg("Невірний ідентифікаційний номер");
            }
        } else {
            setInvalidInnMsg("");
        }
    };

    const handlePrivilegeChange = (event) => {
        const isPrivilegeChecked = event.target.checked;
        setPrivilegeChecked(isPrivilegeChecked);

        if (!isPrivilegeChecked) {
            setSelectedCustomerDocument("PASSPORT");
        }
    };

    const handlePrivilegeTypeChange = (type) => {
        setSelectedCustomerDocument(type.value);
        setCustomerDocument((prev) => ({...prev, type: checkValue(type.value)}));
    };

    const handleDocumentChange = (event) => {
        setSelectedCustomerDocument(event.target.value);
        setCustomerDocument((prev) => ({...prev, type: checkValue(event.target.value), isPrivilege: false}));
    };

    const handleDocumentSeriesChange = (event) => {
        setCustomerDocument((prev) => ({...prev, series: checkValue(event.target.value)}));
    };

    const handleDocumentNumberChange = (event) => {
        setCustomerDocument((prev) => ({...prev, number: checkValue(event.target.value)}));
    };

    const handleDocumentIssuedByChange = (event) => {
        setCustomerDocument((prev) => ({...prev, issuedBy: checkValue(event.target.value)}));
    };

    const handleDocumentIssuedDateChange = (name, date) => {
        setCustomerDocument((prev) => ({...prev, issuedDate: validateDBDate(date)}));
        clearError(name);
    };

    const handleDocumentExpirationDateChange = (name, date) => {
        setCustomerDocument((prev) => ({...prev, expirationDate: validateDBDate(date)}));
        clearError(name);
    };

    const handleSettlementNameChange = (event) => {
        setCustomer((prev) => ({...prev, addressSettlementName: checkValue(event.target.value)}));
    };

    const handleAddressStreetChange = (event) => {
        setCustomer((prev) => ({...prev, addressStreet: checkValue(event.target.value)}));
    };

    const handleAddressHouseNumberChange = (event) => {
        setCustomer((prev) => ({...prev, addressHouseNumber: checkValue(event.target.value)}));
    };

    const handleAddressApartmentNumberChange = (event) => {
        setCustomer((prev) => ({...prev, addressApartmentNumber: checkValue(event.target.value)}));
    };

    const handleVehicleStateNumberChange = (event) => {
        setCustomer((prev) => ({...prev, vehicleStateNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleCustomerSubmit = async (event) => {
        event.preventDefault();
        
        if (Object.keys(errors).length === 0 && customer.phoneNumber.length > 0 && customer.email.length > 0 && customer.firstName.length > 0 && customer.lastName.length > 0 && customer.birthDate && 
            ((currentInsuranceType.value === "epolicy" && customer.middleName.length > 0 && customer.innCode.length > 0 && validateINNCode(customer.innCode)) || 
            (currentInsuranceType.value === "greencard" && !isINNInputDisabled && customer.innCode.length > 0 && validateINNCode(customer.innCode)) || (currentInsuranceType.value === "greencard" && isINNInputDisabled)) && 
            customerDocument.type.length > 0 && customerDocument.number.length > 0 && customer.addressSettlementName.length > 0 && customer.addressStreet.length > 0 && customer.addressHouseNumber.toString().length > 0 && 
            customer.addressApartmentNumber.toString().length > 0 && customer.insuranceType.length > 0 && customer.lastModified) {
            try {
                const addCustomerRes = await dbAxiosInstance.post("/add-customer", customer);
                const customerData = addCustomerRes.data;
                const customerStatus = addCustomerRes.status;

                const document = {
                    ...customerDocument,
                    insuranceType: customer.insuranceType,
                    customerID: customerData.id
                };

                setCustomer((prev) => ({...prev, id: customerData.id}));
                const addDocumentRes = await dbAxiosInstance.post("/add-document", document);
                const documentStatus = addDocumentRes.status;

                if (customerStatus && documentStatus) {
                    setCustomerSuccessMsg(customerData.message);
                    setCustomerAdded(true);
                    setCustomerErrorMsg("");
                    setSuccessNotificationHidden(false);
                    setErrorNotificationHidden(true);
                } else {
                    setCustomerErrorMsg("Не вдалось додати дані клієнта!");
                    setCustomerAdded(false);
                    setCustomerSuccessMsg("");
                    setSuccessNotificationHidden(true);
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                const userDataError = error.toJSON();
                setCustomerErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setCustomerAdded(false);
                setCustomerSuccessMsg("");
                setSuccessNotificationHidden(true);
                setErrorNotificationHidden(false);
            }
        } else {
            setCustomerErrorMsg("Будь ласка, заповніть усі поля, щоб додати дані клієнта!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        setCustomerErrorMsg("");
        setCustomerSuccessMsg("");
        setSuccessNotificationHidden(true);
        setErrorNotificationHidden(true);
        setCustomerAdded(false);
        setCustomer({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, addressSettlementName: "", addressStreet: "", addressHouseNumber: "", addressApartmentNumber: "", 
                     insuranceType: "", lastModified: null, authCode: null});
        setCurrentInsuranceType({label: "", value: ""});
        setPrivilegeChecked(false);         
        setSelectedCustomerDocument("PASSPORT");
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const handleInnStateChange = (isInnDisabled) => {
        setINNInputDisabled(isInnDisabled);
        setCustomer((prev) => ({...prev, innCode: isInnDisabled ? "" : customer.innCode, dontHaveInnCode: isInnDisabled}));
    };

    const backToViewCustomer = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/customer/${customer.insuranceType}/${validatePhoneNumber(customer.phoneNumber)}/${customer.id}/view`);
    };

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
             :
                <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                    <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                        <CustomTooltip msg="Перейти до Клієнтів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers`}}>Клієнти</Link></CustomTooltip> &laquo;&nbsp; Додати дані клієнта</h1>
                    {isCustomerAdded ?
                            <>
                            {!hideSuccessNotification && customerSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerSuccessMsg}</div>}
                            <div>
                                <button type="button" className="admin-btn" onClick={backToViewCustomer}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути</button>
                                <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardNavigate, id, externalID)}>Клієнти</button>
                                <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardNavigate, id, externalID)}>Клієнти (Автоцивілка)</button>
                                <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardNavigate, id, externalID)}>Клієнти (Зелена карта)</button>
                                <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                            </div>
                            </>
                        :
                        <div className="row">
                            <div className="col-md-4">
                            <div className="form-group">
                                <label>Тип страхування</label>
                                <CustomSelect
                                    options={insuranceTypes}
                                    handleChange={handleInsuranceTypesChange}
                                    value={currentInsuranceType}
                                    isCreatable={false}
                                    isDisabled={false}
                                    placeholder="Тип страхування"
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: /[+]\d{12}/,
                                    message: "Введіть вірний телефон",
                                },
                                maxLength: {
                                    value: 13,
                                    message: "Введіть вірний телефон",
                                }
                            })}
                            errors={errors}
                            name="phone_number"
                            label="Номер телефона"
                            defaultValue="+38"
                            readOnly={false}
                            onChange={handlePhoneNumberChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: patternEmail,
                                    message: "Введіть вірний email"
                                }
                            })}
                            errors={errors}
                            name="email"
                            label="Email"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleEmailChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                    message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                }
                            })}
                            errors={errors}
                            name="surname"
                            label="Прізвище"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleLastNameChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                    message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                }
                            })}
                            errors={errors}
                            name="name"
                            label="Iм'я"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleNameChange}
                            />
                        </div>
                        {currentInsuranceType.value === "epolicy" &&
                            <div className="col-md-4">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                        message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                    }
                                })}
                                errors={errors}
                                name="middle_name"
                                label="По батькові"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleMiddleNameChange}
                                />
                            </div>
                        }
                        <div className="col-md-4">
                            {((currentInsuranceType.value === "epolicy") || (currentInsuranceType.value === "greencard" && !isINNInputDisabled)) &&
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: /\d{10}/,
                                        message: "Ідентифікаційний номер містить 10 чисел"
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "Ідентифікаційний номер містить 10 чисел"
                                    }
                                })}
                                errors={errors}
                                name="INN"
                                label="Ідентифікаційний номер"
                                defaultValue=""
                                placeholder="1234567890"
                                mask="9999999999"
                                readOnly={false}
                                onChange={handleInnCodeChange}
                                />
                            }
                            {currentInsuranceType.value === "greencard" &&
                                <div className="switch-wrapper">
                                    <label className="switch-container">
                                        <SwitchComponent onChange={handleInnStateChange} checked={isINNInputDisabled} onColor="#1BA876" />
                                        <div className="switch-text">У мене немає ІПН</div>
                                    </label>
                                </div>
                            }
                            {invalidInnMsg !== "" && <div className="item-error">{invalidInnMsg}</div>}
                        </div>
                        <div className="col-md-4">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове"
                            })}
                            errors={errors}
                            name="date_of_birth"
                            label="Дата народження"
                            type="date"
                            placeholder="РРРР.ММ.ДД"
                            max={getMaxDate()}
                            autoComplete="off"
                            dateReadOnly={false}
                            dateValue={customerBirthDate}
                            onDateChange={handleBirthDateChange}
                            />
                        </div>
                        {currentInsuranceType.value === "epolicy" &&
                            <div className="col-md-4">
                                <div className="privilege-checkbox">
                                    <div className="form-group form-check">
                                        <input
                                            ref={register}
                                            name="privilege"
                                            id="benefit"
                                            type="checkbox"
                                            hidden
                                            checked={isPrivilegeChecked}
                                            onChange={handlePrivilegeChange}
                                        />
                                        <label htmlFor="benefit">Є пільга</label>
                                    </div>
                                </div>
                            </div>
                        }
                        {currentInsuranceType.value === "epolicy" && isPrivilegeChecked ?
                            <>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Тип пільги</label>
                                    <CustomSelect
                                        options={privilegeTypes}
                                        handleChange={handlePrivilegeTypeChange}
                                        value={getPrivilegeType(selectedCustomerDocument)}
                                        isCreatable={false}
                                        isDisabled={false}
                                        placeholder="Тип пільги"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                {privilegeTypes[0].value === selectedCustomerDocument && (
                                    <PensionCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                        isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                )}
                                {privilegeTypes[1].value === selectedCustomerDocument && (
                                    <EPensionCertificateForm errors={errors} register={register} numberClass="col-md-8" expirationDateClass="col-md-8" isReadOnly={false} documentNumberValue="" documentExpirationDateValue="" 
                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                                )}
                                {(privilegeTypes[2].value === selectedCustomerDocument || privilegeTypes[3].value === selectedCustomerDocument) && (
                                    <DisabilityCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={false} documentSeriesValue="" 
                                        documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                                        onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                )}
                                {privilegeTypes[4].value === selectedCustomerDocument && (
                                    <VeteranCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={false} documentSeriesValue="" 
                                        documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                                        onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                )}
                                {privilegeTypes[5].value === selectedCustomerDocument && (
                                    <ChernobylCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={false} documentSeriesValue="" 
                                        documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                                        onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                )}
                                {privilegeTypes[6].value === selectedCustomerDocument && (
                                    <CombatCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" 
                                        documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                                        onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                )}
                                {privilegeTypes[7].value === selectedCustomerDocument && (
                                    <WarDisabilityCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" 
                                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                )}
                                {privilegeTypes[8].value === selectedCustomerDocument && (
                                    <RevolutionCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" 
                                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                )}
                            </div>
                            </>
                            :
                            <>
                                <div className="col-md-10">
                                    <div className="customer-document-type-wrapper">
                                    {websiteDocuments.map((document) => (
                                        <div className="policy-document-type" key={document.value}>
                                            <div className="form-group form-radio">
                                                <input
                                                    ref={register}
                                                    onChange={handleDocumentChange}
                                                    type="radio"
                                                    id={document.value}
                                                    value={document.value}
                                                    checked={document.value === selectedCustomerDocument}
                                                    hidden
                                                    disabled={false}
                                                    name="document_type"
                                                />
                                                <label htmlFor={document.value}>{document.label}</label>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    {websiteDocuments[0].value === selectedCustomerDocument && <PassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={false} 
                                                                                                documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                                onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                    }
                                    {websiteDocuments[1].value === selectedCustomerDocument && <IDCardPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" expirationDateClass="col-md-8" 
                                                                                                issuedByClass="col-md-8" isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedByValue="" documentIssuedDateValue="" 
                                                                                                documentExpirationDateValue="" onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                                                                                                onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} 
                                                                                                onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                                    }
                                    {websiteDocuments[2].value === selectedCustomerDocument && <DriverLicensePartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                                isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                                onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                    }
                                    {websiteDocuments[3].value === selectedCustomerDocument && <ExternalPassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                                isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                                onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                    }
                                </div>
                            </>
                        }
                        <div className="col-md-6">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'.\s]+$/i : /^[a-z\s]+$/i,
                                    message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                }
                            })}
                            errors={errors}
                            name="settlementName"
                            label="Назва населеного пункта"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleSettlementNameChange}
                            />
                        </div>
                        <div className="col-md-6">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї.'\s]+$/i : /^[a-z.\s]+$/i,
                                    message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                }
                            })}
                            errors={errors}
                            name="address_street"
                            label="Вулиця"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleAddressStreetChange}
                            />
                        </div>
                        <div className="col-md-3">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: /\d/,
                                    message: "Тільки цифри"
                                }
                            })}
                            errors={errors}
                            name="address_house_number"
                            label="Номер будинку"
                            placeholder="12"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleAddressHouseNumberChange}
                            />
                        </div>
                        <div className="col-md-3">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: /\d/,
                                    message: "Тільки цифри"
                                }
                            })}
                            errors={errors}
                            name="address_apartment_number"
                            label="Номер квартири"
                            placeholder="12"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleAddressApartmentNumberChange}
                            />
                        </div>
                        <div className="col-md-3">
                            <CustomField
                            register={register({
                                required: "Це поле обов'язкове",
                                pattern: {
                                    value: /[A-zА-я-І-і-Ї-ї0-9]/,
                                    message: "Реєстраційний номер ТЗ не відповідає правилам перевірки"
                                }
                            })}
                            errors={errors}
                            name="vehicle_state_number"
                            label="Державний номер ТЗ"
                            placeholder="AA1234BB"
                            defaultValue=""
                            readOnly={false}
                            onChange={handleVehicleStateNumberChange}
                            />
                        </div>
                        <div className="col-md-12">
                            <button type="submit" className="submit-btn" onClick={handleCustomerSubmit}><i className="fa fa-plus notification-icon"></i>Додати</button>
                            <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                            <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                            <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardNavigate, id, externalID)}>Клієнти</button>
                            <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardNavigate, id, externalID)}>Клієнти (Автоцивілка)</button>
                            <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardNavigate, id, externalID)}>Клієнти (Зелена карта)</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                        </div>
                        {!hideErrorNotification && customerErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerErrorMsg}</div>}
                    </div>
                    }
                </div>
                </>
            }
            </div>
        </div>
    );
};

export default AddCustomerPage;