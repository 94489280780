import React, {useEffect, useState, useCallback} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import FilterPagination from "../../../utils/FilterPagination";
import webConfig from "../../../config";
import {filterRecords, countRecords, isMatchCaseSearch} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import DataFilter from "../../../utils/DataFilter";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const VehiclesPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [vehicles, setVehicles] = useState<any[]>([]);
    const [vehiclesErrorMsg, setVehiclesErrorMsg] = useState("");
    const [vehiclesCount, setVehiclesCount] = useState(0); 
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                const res = await dbAxiosInstance.get("/vehicles");
                const dbVehiclesData = res.data;

                if (dbVehiclesData) {
                    if (dbVehiclesData.length > 0) {
                        setVehicles(dbVehiclesData);
                        setFilteredItems(dbVehiclesData);
                        setVehiclesErrorMsg("");
                    } else {
                        setVehicles([]);
                        setFilteredItems([]);
                        setVehiclesErrorMsg("Немає доданих транспортних засобів. Натисніть на кнопку, щоб додати ТЗ.");
                    }
                } else {
                    setVehicles([]);
                    setFilteredItems([]);
                    setVehiclesErrorMsg("Помилка БД! Не вдалося завантажити транспортні засоби.");
                }
            } catch (error: any) {
                setVehicles([]);
                setFilteredItems([]);
                setVehiclesErrorMsg("Помилка БД! Не вдалося завантажити транспортні засоби.");
            }
        };

        const countAllVehicles = async () => {
            try {
                const res = await dbAxiosInstance.get("/count-vehicles");
                const dbCountVehiclesData = res.data;

                if (dbCountVehiclesData) {
                    if (dbCountVehiclesData.length > 0) {
                        setVehiclesCount(dbCountVehiclesData[0].counter);
                    } else {
                        setVehiclesCount(0);
                    }
                } else {
                    setVehiclesCount(0);
                }
            } catch (error: any) {
                setVehiclesCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };
        
        fetchVehicles();
        countAllVehicles();
        checkSearchType();
    }, []);

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/vehicle/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            setFilteredItems(filterRecords(filteredItems, id));
            setVehicles(filterRecords(vehicles, id));
            const allRecords = countRecords(vehiclesCount);
            setVehiclesCount(allRecords);

            if (allRecords === 0) {
                setVehiclesErrorMsg("Немає доданих транспортних засобів. Натисніть на кнопку, щоб додати ТЗ.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setVehiclesErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setVehicles([]);
        }
    };

    const handleRecordsOnPage = useCallback((records) => {
        setRecordsOnPage(records);
    }, []);

    const handleDataLoaded = useCallback((state: boolean) => {
        setFilterCompleted(state);
    }, []);

    const handleFilterState = useCallback((state: boolean) => {
        setProcessingFilter(state);
    }, []);

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Транспортні засоби</h1>
                    <div className="itemsCounter">
                        <p>Всього: <span className="font-bold">{vehiclesCount}</span></p>
                    </div>
                    <DataFilter items={vehicles} matchCase={isMatchCaseFilter} placeholderText="Введіть дані ТЗ для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом такого ТЗ не знайдено!" />
                    <div className="dashboard">
                        <ul className="dashboard-items">
                            <li>
                                <div className="dashboard-item">
                                    <CustomTooltip msg="Додати"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/add-vehicle`}}><i className="fa fa-plus"></i></Link></CustomTooltip>
                                </div>
                            </li>
                            {recordsOnPage.length > 0 ? recordsOnPage.map((vehicleData, index) =>
                                <li key={index}>
                                    <div className="manage-item-wrapper">
                                        <div className="manage-item-container">
                                            <CustomTooltip msg="Редагувати">
                                                <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/edit-vehicle/${vehicleData.state_number.toLowerCase()}/${vehicleData.id}`}}>
                                                    <i className="fa fa-pencil-alt"></i>
                                                </Link>
                                            </CustomTooltip>
                                            <CustomTooltip msg="Видалити"><Link to="#" className="delete-item" onClick={() => handleItemDelete(vehicleData.id)}><i className="fa fa-trash"></i></Link></CustomTooltip>
                                        </div>
                                    </div>
                                    <div className="dashboard-item">
                                        <Link to={{pathname: `/admin/dashboard/${id}/${externalID}/view-vehicle/${vehicleData.state_number.toLowerCase()}/${vehicleData.id}`}} key={vehicleData.id}>{vehicleData.state_number}</Link>
                                    </div>
                                </li>) : <li><div className="dashboard-error">{vehiclesErrorMsg}</div></li>
                            }
                        </ul>
                    </div>
                    <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={handleDataLoaded} isFilterProcessing={processingFilter} setFilterState={handleFilterState} />
                </div>
                </>
            }
            </div>
        </div>
    );
};

export default VehiclesPage;