import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {checkValue} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";

const AddSocialItem = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const [socialItem, setSocialItem] = useState({
        name: "",
        url: "",
        iconName: ""
    });
    const [socialItemError, setSocialItemError] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const nameInput = useRef<HTMLInputElement>(null);
    const urlInput = useRef<HTMLInputElement>(null);
    const iconNameInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const handleInputChange = (event) => {
        setSocialItem((prev) => ({...prev, [event.target.name]: checkValue(event.target.value)}));
    };

    const handleItemSubmit = async (event) => {
        event.preventDefault();

        if (socialItem.name.trim().length > 0 && socialItem.url.trim().length > 0 && socialItem.iconName.trim().length > 0) {
            try {
                await dbAxiosInstance.post("/social-items", socialItem);
                setSocialItemError("");
                setErrorNotificationHidden(true);
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/socials`);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setSocialItemError(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            } 
        } else {
            setSocialItemError("Будь ласка, заповніть усі поля, щоб додати соціальну мережу!");
            setErrorNotificationHidden(false);
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const clearFields = (event) => {
        event.preventDefault();
        setSocialItem({name: "", url: "", iconName: ""});
        
        if (nameInput.current) {
            nameInput.current.value = "";
        }

        if (urlInput.current) {
            urlInput.current.value = "";
        }

        if (iconNameInput.current) {
            iconNameInput.current.value = "";
        }
    };

    return (
        <div className="container">
            <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                    :
                    <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Додати соціальну мережу</h1>
                        <div className="add-dashboard-item-form">
                            <CustomInput type="text" name="name" label="Назва" placeholder="Назва" onDataChange={handleInputChange} ref={nameInput} />
                            <CustomInput type="url" name="url" label="Посилання" placeholder="Посилання" onDataChange={handleInputChange} ref={urlInput} />
                            <CustomInput type="text" name="iconName" label="Назва зображення" placeholder="Назва зображення" onDataChange={handleInputChange} ref={iconNameInput} />
                        </div>
                        <button type="submit" className="submit-btn" onClick={handleItemSubmit}><i className="fa fa-plus notification-icon"></i>Додати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                    </div>
                    {!hideErrorNotification && socialItemError && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{socialItemError}</div>}
                    </>
                }
            </div>
        </div>
    );
};

export default AddSocialItem;