import React, {useEffect, useState, useMemo, useCallback} from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import {isMobile} from "react-device-detect";
import { 
    getInsuranceType, 
    refreshPage, 
    checkEditAction, 
    checkValue, 
    navToAdminPanel, 
    navToCustomersPage,
    navToCustomersEpolicyPage,
    navToCustomersGreencardPage,
    validateINNCode,
    convertINNToBirthDate,
    checkRequestStatus,
    validatePhoneNumber,
    findWebsiteDocumentLabelByType,
    findCustomerDocumentIDByType
} from "../../../utils/utilsGeneral";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import CustomField from "../../../components/MainPage/CustomField";
import useForm from "react-hook-form";
import {patternEmail} from "../../../utils/getInstanceError";
import CustomSelect from "../../../components/common/CustomSelect";
import {insuranceTypes, privilegeTypes, websiteDocuments} from "../../../mock-data/insuranceTypes";
import {getMaxDate, validateDBDateTime, formatDBDateTime, getCurrentDateTime, validateDBDate} from "../../../utils/utilsDate";
import PassportPartForm from "../../../components/MainPage/PassportPartForm";
import IDCardPartForm from "../../../components/MainPage/IDCardPartForm";
import DriverLicensePartForm from "../../../components/MainPage/DriverLicensePartForm";
import PensionCertificateForm from "../../../components/MainPage/PrivilegeForms/PensionCertificate";
import EPensionCertificateForm from "../../../components/MainPage/PrivilegeForms/EPensionCertificate";
import DisabilityCertificateForm from "../../../components/MainPage/PrivilegeForms/DisabilityCertificate";
import VeteranCertificateForm from "../../../components/MainPage/PrivilegeForms/VeteranCertificate";
import ChernobylCertificateForm from "../../../components/MainPage/PrivilegeForms/ChernobylCertificate";
import {ICustomer, CustomerDocumentOptions, CustomerDocument} from "../../../redux-data/user/userType";
import {IVehicleFound} from "../../../redux-data/vehicle/vehicleTypes";
import SwitchComponent from "react-switch";
import webConfig from "../../../config";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import ExternalPassportPartForm from "../../../components/MainPage/ExternalPassportPartForm";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import TabsComponent from "../../../utils/TabsComponent";
import {TabProp} from "../../../utils/tab";

const ManageCustomerPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, insuranceType, phoneNumber, customerID, action} = useParams<{
        id: string;
        externalID: string;
        insuranceType: string;
        phoneNumber: string; 
        customerID: string; 
        action: string
    }>();
    const [editCustomerData, setCustomerDataEdited] = useState<ICustomer>({
        phoneNumber: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
        innCode: "",
        dontHaveInnCode: false,
        addressSettlementName: "",
        addressStreet: "",
        addressHouseNumber: "",
        addressApartmentNumber: "",
        insuranceType: "",
        lastModified: null,
        authCode: null
    });
    const [editCustomerDocuments, setEditCustomerDocuments] = useState<CustomerDocumentOptions[]>([]);
    const [editSelectedDocument, setEditSelectedDocument] = useState<CustomerDocument>({
        type: "",
        series: "",
        number: "",
        issuedDate: null,
        expirationDate: null,
        issuedBy: ""
    });
    const [customerErrorMsg, setCustomerErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [vehicleData, setVehicleData] = useState<IVehicleFound[]>([]);
    const [selectedVehicleData, setSelectedVehicleData] = useState<IVehicleFound>();
    const [currentInsuranceType, setCurrentInsuranceType] = useState(getInsuranceType(insuranceType!));
    const [lastModified, setLastModified] = useState("");
    const [isEditMode, setEditMode] = useState(checkEditAction(action!));
    const [customerBirthDate, setCustomerBirthDate] = useState("");
    const [invalidInnMsg, setInvalidInnMsg] = useState("");
    const [isINNInputDisabled, setINNInputDisabled] = useState(false);
    const [customerInnCode, setCustomerInnCode] = useState("");
    const [documentTabs, setDocumentTabs] = useState<TabProp[]>([]);
    const [currentDocumentTab, setCurrentDocumentTab] = useState(1);
    
    const {register, errors, clearError} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    const fetchCustomer = useCallback(async () => {
        try {
            const customerResponse = await dbAxiosInstance.get(`/customer/${encodeURIComponent(phoneNumber!)}/${encodeURIComponent(customerID!)}`);
            const customer = customerResponse.data[0];

            const customerDocumentsRes = await dbAxiosInstance.get(`/document/${encodeURIComponent(customer.insurance_type)}/${encodeURIComponent(customer.id)}`);
            const customerDocuments = customerDocumentsRes.data;

            const vehiclesOwnerRes = await dbAxiosInstance.get(`/vehicle-owner/customer/${encodeURIComponent(customer.id)}`);
            const vehiclesOwner = vehiclesOwnerRes.data;
            const vehicleIDs = vehiclesOwner.map((vehicleOwner) => vehicleOwner.vehicle_id).join(",");

            const vehiclesResponse = await dbAxiosInstance.get(`/fetch-vehicle/${encodeURIComponent(vehicleIDs)}`);
            const customerVehicles = vehiclesResponse.data;

            if (customer) {
                setCustomerDataEdited({phoneNumber: customer.phone_number, email: customer.email, firstName: customer.first_name, middleName: customer.middle_name, lastName: customer.last_name, birthDate: validateDBDate(customer.birth_date), 
                                       innCode: customer.inn_code, dontHaveInnCode: Boolean(customer.dont_have_inn_code), addressSettlementName: customer.address_settlement_name, addressStreet: customer.address_street, 
                                       addressHouseNumber: customer.address_house_number, addressApartmentNumber: customer.address_apartment_number, insuranceType: customer.insurance_type, lastModified: validateDBDateTime(customer.last_modified), 
                                       authCode: customer.auth_code});
                setLastModified(customer.last_modified);
                setCurrentInsuranceType(getInsuranceType(customer.insurance_type));
                setCustomerBirthDate(validateDBDate(customer.birth_date));
                setCustomerInnCode(customer.inn_code);
                setINNInputDisabled(Boolean(customer.dont_have_inn_code));
                setCustomerErrorMsg("");
                setErrorNotificationHidden(true);
            } else {
                setCustomerDataEdited({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, addressSettlementName: "", addressStreet: "", addressHouseNumber: "", 
                                       addressApartmentNumber: "", insuranceType: "", lastModified: null, authCode: null});
                setCustomerErrorMsg("Помилка БД! Не вдалося завантажити дані клієнта.");
                setErrorNotificationHidden(false);
            }

            if (customerDocuments) {
                const customerDocsOptions: CustomerDocumentOptions[] = customerDocuments.map((document) => {
                    return {
                        id: document.id,
                        value: document.type,
                        label: findWebsiteDocumentLabelByType(document.type),
                        isDisabled: false,
                        type: document.type,
                        series: document.series,
                        number: document.number,
                        issuedDate: document.issued_date,
                        expirationDate: document.expiration_date,
                        issuedBy: document.issued_by
                    };
                });
                setEditCustomerDocuments(customerDocsOptions);
                const [documents] = customerDocsOptions;
                setEditSelectedDocument({type: documents.type, series: documents.series, number: documents.number, issuedDate: documents.issuedDate, expirationDate: documents.expirationDate, issuedBy: documents.issuedBy});
            }

            if (customerVehicles) {
                const vehicles: IVehicleFound[] = customerVehicles.map((vehicle) => ({
                    id: vehicle.id,
                    label: `${vehicle.model_text} - ${vehicle.state_number}`,
                    description: vehicle.id,
                    disabled: false,
                    value: vehicle.state_number
                }));
                setVehicleData(vehicles);
                setCustomerErrorMsg("");
                setErrorNotificationHidden(true);
            } else {
                setVehicleData([]);
                setCustomerErrorMsg("Помилка БД! Не вдалося завантажити транспортний засіб.");
                setErrorNotificationHidden(false);
            }
        } catch (error: any) {
            setCustomerDataEdited({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, addressSettlementName: "", addressStreet: "", addressHouseNumber: "", 
                                   addressApartmentNumber: "", insuranceType: "", lastModified: null, authCode: null});
            setVehicleData([]);
            setCustomerErrorMsg("Помилка БД! Не вдалося завантажити дані клієнта і ТЗ.");
            setErrorNotificationHidden(false);
        }
    }, [customerID, phoneNumber]);
    
    useEffect(() => {
        fetchCustomer();
    }, [fetchCustomer]);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]); 

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const handleInsuranceTypesChange = (type: any) => {
        if (insuranceType === type.value && editCustomerData.insuranceType === type.value) {
            setCurrentInsuranceType(type);
            setCustomerDataEdited((prev) => ({...prev, insuranceType: type.value}));
        }
    };

    const handleDocumentChange = (type: string) => {
        const selectedCustomerDocument = editCustomerDocuments.find((document) => document.type === type);
        setEditSelectedDocument((prev) => ({...prev, type: checkValue(selectedCustomerDocument?.type), series: checkValue(selectedCustomerDocument?.series), number: checkValue(selectedCustomerDocument?.number), 
                                            issuedDate: checkValue(selectedCustomerDocument?.issuedDate), expirationDate: checkValue(selectedCustomerDocument?.expirationDate), issuedBy: checkValue(selectedCustomerDocument?.issuedBy)}));
    };

    const handleBirthDateChange = useCallback((name, date) => {
        setCustomerDataEdited((prev) => ({...prev, birthDate: checkValue(date)}));
        clearError(name);
    }, [clearError]);

    const handleDocumentIssuedDateChange = useCallback((name, date) => {
        setEditSelectedDocument((prev) => ({...prev, issuedDate: checkValue(date)}));
        clearError(name);
    }, [clearError]);

    const handleDocumentExpirationDateChange = useCallback((name, date) => {
        setEditSelectedDocument((prev) => ({...prev, expirationDate: checkValue(date)}));
        clearError(name);
    }, [clearError]);

    const handleCustomerEdit = async (event) => {
        event.preventDefault();

        if (Object.keys(errors).length === 0 && editCustomerData.phoneNumber.length > 0 && editCustomerData.email.length > 0 && editCustomerData.firstName.length > 0 && editCustomerData.birthDate && 
            ((insuranceType === "epolicy" && editCustomerData.middleName.length > 0 && editCustomerData.innCode.length > 0 && validateINNCode(editCustomerData.innCode)) || 
            (insuranceType === "greencard" && !isINNInputDisabled && editCustomerData.innCode.length > 0 && validateINNCode(editCustomerData.innCode)) || (insuranceType === "greencard" && isINNInputDisabled)) && 
            editSelectedDocument.type.length > 0 && editSelectedDocument.number.length > 0 && editCustomerData.addressSettlementName.length > 0 && editCustomerData.addressStreet.length > 0 && 
            editCustomerData.addressHouseNumber.toString().length > 0 && editCustomerData.addressApartmentNumber.toString().length > 0 && editCustomerData.insuranceType.length > 0 && 
            editCustomerData.lastModified) {
            try {
                const editCustomerRes = await dbAxiosInstance.put(`/edit-customer/${encodeURIComponent(customerID!)}`, editCustomerData);
                const editCustomerStatus = editCustomerRes.status;

                const editDocumentID = findCustomerDocumentIDByType(editCustomerDocuments, editSelectedDocument.type);
                const editCustomerDocumentRes = await dbAxiosInstance.put(`/edit-document/${encodeURIComponent(editCustomerData.insuranceType)}/${encodeURIComponent(customerID!)}/${encodeURIComponent(editSelectedDocument.type)}/${encodeURIComponent(editDocumentID)}`, editSelectedDocument);
                const editCustomerDocumentStatus = editCustomerDocumentRes.status;

                if (editCustomerStatus && editCustomerDocumentStatus) {
                    dashboardNavigate(`/admin/dashboard/${id}/${externalID}/customer/${insuranceType}/${validatePhoneNumber(editCustomerData.phoneNumber)}/${customerID}/item/edited`, {
                        state: {
                            isSuccess: checkRequestStatus(editCustomerStatus),
                            status: editCustomerStatus,
                            successMsg: editCustomerRes.data,
                            errorMsg: ""
                        }
                    });
                    setCustomerErrorMsg("");
                    setSuccessNotificationHidden(false);
                    setErrorNotificationHidden(true);
                } else {
                    setCustomerErrorMsg("Не вдалось оновити дані клієнта!");
                    setSuccessNotificationHidden(true);
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                const userDataError = error.toJSON();
                setCustomerErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setSuccessNotificationHidden(true);
                setErrorNotificationHidden(false);
            }
        } else {
            setCustomerErrorMsg("Будь ласка, заповніть усі поля, щоб оновити дані клієнта!");
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    };

    const handlePhoneNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, phoneNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleEmailChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, email: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleLastNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, lastName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, firstName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleMiddleNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, middleName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleInnCodeChange = (event) => {
        const innCode = event.target.value;
    
        if (innCode.length === Number(webConfig.customerINNCodeLength)) {
            if (validateINNCode(innCode)) {
                const {year, month, day} = convertINNToBirthDate(innCode);
                setCustomerBirthDate(`${year}-${month}-${day}`);
                setInvalidInnMsg("");
                setCustomerDataEdited((prev) => ({...prev, innCode: checkValue(innCode), dontHaveInnCode: false, lastModified: getCurrentDateTime()}));
            } else {
                setInvalidInnMsg("Невірний ідентифікаційний номер");
            }
        } else {
            setInvalidInnMsg("");
        }
    };

    const handleSettlementNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressSettlementName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleAddressStreetChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressStreet: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleAddressHouseNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressHouseNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleAddressApartmentNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressApartmentNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const deleteCustomer = async (id) => {
        try {
            await dbAxiosInstance.delete(`/customer/${encodeURIComponent(id)}`);
            await dbAxiosInstance.delete(`/vehicle-owner/customer/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/customers/${insuranceType}`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setCustomerErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setCustomerDataEdited({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, addressSettlementName: "", addressStreet: "", addressHouseNumber: "", addressApartmentNumber: "", 
                                   insuranceType: "", lastModified: null, authCode: null});
        }
    };

    const handleDocumentSeriesChange = (event) => {
        setEditSelectedDocument((prev) => ({...prev, series: checkValue(event.target.value)}));
    };

    const handleDocumentNumberChange = (event) => {
        setEditSelectedDocument((prev) => ({...prev, number: checkValue(event.target.value)}));
    };

    const handleDocumentIssuedByChange = (event) => {
        setEditSelectedDocument((prev) => ({...prev, issuedBy: checkValue(event.target.value)}));
    };

    const handleEditModeChange = (mode) => {
        setEditMode(mode);
    };

    const handleInnStateChange = (isInnDisabled) => {
        setINNInputDisabled(isInnDisabled);
        setCustomerDataEdited((prev) => ({...prev, innCode: isInnDisabled ? "" : customerInnCode, dontHaveInnCode: isInnDisabled, lastModified: getCurrentDateTime()}));
    };

    const handleVehicleChange = (data: any) => {
        setSelectedVehicleData(data);
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/view-vehicle/${data.value.toLowerCase()}/${data.id}`);
    };

    const tabs = useMemo(() => {
        return editCustomerDocuments.map((customerDocument, index) => {
            const tabIndex = ++index;
            return {
                index: tabIndex,
                label: customerDocument.label,
                value: customerDocument.value,
                isDisabled: customerDocument.isDisabled,
                children: <div className="col-md-8">
                    {customerDocument.value === privilegeTypes[0].value && <PensionCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                        isReadOnly={!isEditMode} documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedDateValue={customerDocument.issuedDate} documentIssuedByValue={customerDocument.issuedBy} 
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    }
                    {customerDocument.value === privilegeTypes[1].value && <EPensionCertificateForm errors={errors} register={register} numberClass="col-md-8" expirationDateClass="col-md-8" isReadOnly={!isEditMode} 
                        documentNumberValue={customerDocument.number} documentExpirationDateValue={customerDocument.expirationDate} onDocumentNumberChange={handleDocumentNumberChange} onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                    }
                    {customerDocument.value === privilegeTypes[2].value && <DisabilityCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                        isReadOnly={!isEditMode} documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedDateValue={customerDocument.issuedDate} documentIssuedByValue={customerDocument.issuedBy}
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    }
                    {customerDocument.value === privilegeTypes[3].value && <VeteranCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                        isReadOnly={!isEditMode} documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedDateValue={customerDocument.issuedDate} documentIssuedByValue={customerDocument.issuedBy}
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    }
                    {customerDocument.value === privilegeTypes[4].value && <ChernobylCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                        isReadOnly={!isEditMode} documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedDateValue={customerDocument.issuedDate} documentIssuedByValue={customerDocument.issuedBy}
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    }
                    {customerDocument.value === websiteDocuments[0].value && <PassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                        documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedDateValue={customerDocument.issuedDate} documentIssuedByValue={customerDocument.issuedBy} 
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    }
                    {customerDocument.value === websiteDocuments[1].value && <IDCardPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" expirationDateClass="col-md-8" 
                        issuedByClass="col-md-8" isReadOnly={!isEditMode} documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedByValue={customerDocument.issuedBy} 
                        documentIssuedDateValue={customerDocument.issuedDate} documentExpirationDateValue={customerDocument.expirationDate} onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                        onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                    }
                    {customerDocument.value === websiteDocuments[2].value && <DriverLicensePartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                        isReadOnly={!isEditMode} documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedDateValue={customerDocument.issuedDate} documentIssuedByValue={customerDocument.issuedBy} 
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    }
                    {customerDocument.value === websiteDocuments[3].value && <ExternalPassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                        isReadOnly={!isEditMode} documentSeriesValue={customerDocument.series} documentNumberValue={customerDocument.number} documentIssuedDateValue={customerDocument.issuedDate} documentIssuedByValue={customerDocument.issuedBy} 
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    }
                </div>
            };
        });
    }, [editCustomerDocuments, isEditMode, errors, handleDocumentExpirationDateChange, handleDocumentIssuedDateChange, register]);

    useEffect(() => {
        if (tabs && tabs.length > 0) {
            setDocumentTabs(tabs);
        }
    }, [tabs]);

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
             :
                <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                    <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                        <CustomTooltip msg="Перейти до Клієнтів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers`}}>Клієнти</Link></CustomTooltip> &laquo;&nbsp; 
                        <CustomTooltip msg={`Перейти до ${currentInsuranceType.label}`}><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers/${currentInsuranceType.value}`}}>{currentInsuranceType.label}</Link></CustomTooltip> &laquo; {phoneNumber}</h1>
                            {editCustomerData ?
                            <>
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="switch-wrapper">
                                            <label className="switch-container">
                                                <SwitchComponent onChange={handleEditModeChange} checked={isEditMode} onColor="#1BA876" />
                                                <div className="switch-text">Дозволити редагування</div>
                                            </label>
                                        </div>
                                        <p className="item-title">Додано/оновлено: <span className="font-bold">{formatDBDateTime(lastModified)}</span></p>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Тип страхування</label>
                                            <CustomSelect
                                                options={insuranceTypes}
                                                handleChange={handleInsuranceTypesChange}
                                                value={currentInsuranceType}
                                                isCreatable={false}
                                                isDisabled={!isEditMode}
                                                placeholder="Тип страхування"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <CustomField
                                        type="tel"
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: /[+]\d{12}/,
                                                message: "Введіть вірний телефон",
                                            },
                                            maxLength: {
                                                value: 13,
                                                message: "Введіть вірний телефон",
                                            }
                                        })}
                                        errors={errors}
                                        name="phone_number"
                                        label="Номер телефона"
                                        defaultValue={editCustomerData.phoneNumber}
                                        readOnly={!isEditMode}
                                        onChange={handlePhoneNumberChange}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomField
                                        type="email"
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: patternEmail,
                                                message: "Введіть вірний email"
                                            }
                                        })}
                                        errors={errors}
                                        name="email"
                                        label="Email"
                                        defaultValue={editCustomerData.email}
                                        readOnly={!isEditMode}
                                        onChange={handleEmailChange}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                                message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                            }
                                        })}
                                        errors={errors}
                                        name="surname"
                                        label="Прізвище"
                                        defaultValue={editCustomerData.lastName}
                                        readOnly={!isEditMode}
                                        onChange={handleLastNameChange}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                                message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                            }
                                        })}
                                        errors={errors}
                                        name="name"
                                        label="Iм'я"
                                        defaultValue={editCustomerData.firstName}
                                        readOnly={!isEditMode}
                                        onChange={handleNameChange}
                                        />
                                    </div>
                                    {insuranceType === "epolicy" &&
                                        <div className="col-md-4">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                                    message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                                }
                                            })}
                                            errors={errors}
                                            name="middle_name"
                                            label="По батькові"
                                            defaultValue={editCustomerData.middleName}
                                            readOnly={!isEditMode}
                                            onChange={handleMiddleNameChange}
                                            />
                                        </div>
                                    }
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Транспортні засоби власника</label>
                                            <CustomSelect
                                                options={vehicleData}
                                                handleChange={handleVehicleChange}
                                                value={selectedVehicleData}
                                                isCreatable={false}
                                                isDisabled={false}
                                                placeholder="Вибрати"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        {((insuranceType === "epolicy") || (insuranceType === "greencard" && !isINNInputDisabled)) &&
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: /\d{10}/,
                                                    message: "Ідентифікаційний номер містить 10 чисел"
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Ідентифікаційний номер містить 10 чисел"
                                                }
                                            })}
                                            errors={errors}
                                            name="INN"
                                            label="Ідентифікаційний номер"
                                            defaultValue={customerInnCode}
                                            placeholder="1234567890"
                                            mask="9999999999"
                                            readOnly={!isEditMode}
                                            onChange={handleInnCodeChange}
                                            />
                                        }
                                        {currentInsuranceType.value === "greencard" &&
                                            <div className="switch-wrapper">
                                                <label className="switch-container">
                                                    <SwitchComponent onChange={handleInnStateChange} checked={isINNInputDisabled} disabled={!isEditMode} onColor="#1BA876" />
                                                    <div className="switch-text">У мене немає ІПН</div>
                                                </label>
                                            </div>
                                        }
                                        {invalidInnMsg !== "" && <div className="item-error">{invalidInnMsg}</div>}
                                    </div>
                                    <div className="col-md-4">
                                        <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове"
                                        })}
                                        errors={errors}
                                        name="date_of_birth"
                                        label="Дата народження"
                                        type="date"
                                        placeholder="РРРР.ММ.ДД"
                                        max={getMaxDate()}
                                        autoComplete="off"
                                        dateReadOnly={!isEditMode}
                                        dateValue={customerBirthDate}
                                        onDateChange={handleBirthDateChange}
                                        />
                                    </div>
                                    <div className="customer-documents-wrapper">
                                        <h3 className="customer-documents-title">Документи</h3>
                                        <TabsComponent 
                                            tabs={documentTabs} 
                                            defaultTab={1} 
                                            activeTab={currentDocumentTab} 
                                            orientation={isMobile ? "vertical" : "horizontal"} 
                                            tabsDisabledMsg="Документи клієнта тимчасово недоступні!" 
                                            isContentPadding={false}
                                            onSelectedTab={(id) => setCurrentDocumentTab(id)}
                                            onSelectedValue={handleDocumentChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'.\s]+$/i : /^[a-z\s]+$/i,
                                                message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                            }
                                        })}
                                        errors={errors}
                                        name="settlementName"
                                        label="Назва населеного пункта"
                                        defaultValue={editCustomerData.addressSettlementName}
                                        readOnly={!isEditMode}
                                        onChange={handleSettlementNameChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: insuranceType === "epolicy" ? /^[а-яa-z-ієї.'\s]+$/i : /^[a-z.\s]+$/i,
                                                message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                            }
                                        })}
                                        errors={errors}
                                        name="address_street"
                                        label="Вулиця"
                                        defaultValue={editCustomerData.addressStreet}
                                        readOnly={!isEditMode}
                                        onChange={handleAddressStreetChange}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: /\d/,
                                                message: "Тільки цифри"
                                            }
                                        })}
                                        errors={errors}
                                        name="address_house_number"
                                        label="Номер будинку"
                                        placeholder="12"
                                        defaultValue={editCustomerData.addressHouseNumber}
                                        readOnly={!isEditMode}
                                        onChange={handleAddressHouseNumberChange}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове",
                                            pattern: {
                                                value: /\d/,
                                                message: "Тільки цифри"
                                            }
                                        })}
                                        errors={errors}
                                        name="address_apartment_number"
                                        label="Номер квартири"
                                        placeholder="12"
                                        defaultValue={editCustomerData.addressApartmentNumber}
                                        readOnly={!isEditMode}
                                        onChange={handleAddressApartmentNumberChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        {isEditMode && <button type="submit" className="edit-btn" onClick={handleCustomerEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>}
                                        <button type="button" className="admin-btn" onClick={() => deleteCustomer(customerID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                                        <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardNavigate, id, externalID)}>Клієнти</button>
                                        <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardNavigate, id, externalID)}>Клієнти (Автоцивілка)</button>
                                        <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardNavigate, id, externalID)}>Клієнти (Зелена карта)</button>
                                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                                    </div>
                                    {!hideErrorNotification && customerErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerErrorMsg}</div>}
                                    </div>
                            </>
                            :
                            <div>
                                {!hideErrorNotification && customerErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerErrorMsg}</div>}
                            </div>
                        }
                    </div>
                </>
            }
            </div>
        </div>
    );
};

export default ManageCustomerPage;