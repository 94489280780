import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {dbAxiosInstance} from "../../core/configDBAxios";
import CustomLoader from "../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../hooks/dashboard-auth";
import CustomInput from "../../components/MainPage/CustomInput";

const EditDashboardItem = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const [editDashboardItemData, setDashboardItemDataEdited] = useState({
        name: "",
        url: ""
    });
    const [dashboardItemError, setDashboardItemError] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const {id, externalID, itemID} = useParams<{
        id: string; 
        externalID: string; 
        itemID: string;
    }>();
    const editNameInput = useRef<HTMLInputElement>(null);
    const editUrlInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const fetchDashboardItem = async () => {
            try {
                const res = await dbAxiosInstance.get(`/dashboard-items/${encodeURIComponent(itemID!)}`);
                const dbDashboardData = res.data;

                if (dbDashboardData) {
                    if (dbDashboardData.length > 0) {
                        const [dashboardItem] = dbDashboardData;
                        setDashboardItemDataEdited({name: dashboardItem.name, url: dashboardItem.url});
                        setDashboardItemError("");
                    } else {
                        setDashboardItemDataEdited({name: "", url: ""});
                        setDashboardItemError("Немає такого елемента панелі керування. Ви можете додати його через панель адміністратора.");
                    }
                } else {
                    setDashboardItemDataEdited({name: "", url: ""});
                    setDashboardItemError("Помилка БД! Не вдалося завантажити елемент панелі керування.");
                }
            } catch (error: any) {
                setDashboardItemDataEdited({name: "", url: ""});
                setDashboardItemError("Помилка БД! Не вдалося завантажити елемент панелі керування.");
                setErrorNotificationHidden(false);
            }
        };

        fetchDashboardItem();
    }, [itemID]);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const handleEditInputChange = (event) => {
        setDashboardItemDataEdited((prev) => ({...prev, [event.target.name]: event.target.value}));
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editDashboardItemData.name.trim().length > 0 && editDashboardItemData.url.trim().length > 0) {
            try {
                await dbAxiosInstance.put(`/dashboard-items/${encodeURIComponent(itemID!)}`, editDashboardItemData);
                setDashboardItemError("");
                setErrorNotificationHidden(true);
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}`);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setDashboardItemError(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setDashboardItemError("Будь ласка, заповніть усі поля, щоб оновити елемент панелі керування!");
            setErrorNotificationHidden(false);
        }
    };

    const closeNotificationMsg = (event) => {
        setErrorNotificationHidden(true);
    };

    const clearFields = (event) => {
        event.preventDefault();

        if (editNameInput.current) {
            editNameInput.current.value = "";
        }

        if (editUrlInput.current) {
            editUrlInput.current.value = "";
        }

        setDashboardItemDataEdited({name: "", url: ""});
    };

    return (
         <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати елемент панелі керування</h1>
                        <div className="edit-dashboard-item-form">
                            <CustomInput type="text" name="name" label="Відредагувати назву" placeholder="Відредагувати назву" value={editDashboardItemData.name} onDataChange={handleEditInputChange} ref={editNameInput} />
                            <CustomInput type="url" name="url" label="Відредагувати посилання" placeholder="Відредагувати посилання" value={editDashboardItemData.url} onDataChange={handleEditInputChange} ref={editUrlInput} />
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                    </div>
                    {!hideErrorNotification && dashboardItemError && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{dashboardItemError}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditDashboardItem;