import React, {useEffect, useState} from "react";
import {getPayloadLiqpay} from "../utils/liqpay";
import {useSelector} from "react-redux";
import {getSelectedAmountItem} from "../redux-data/insurance/insuranceSelector";
import {getOfferId, getOfferEmail, getOfferCustomerFirstName, getOfferCustomerMiddleName, getOfferCustomerLastName} from "../redux-data/offer/offerReducer";
import {isSettingItemActive} from "../utils/utilsGeneral";
import webConfig from "../config";
import {liqPayAxiosInstance} from "../core/configLiqPayAxios";
import {getEagentToken} from "../redux-data/misc/miscReducer";
import {getCurrentUserInfo} from "../redux-data/user/userReducer";
import {getPromocodeName, getPromocodeIsActivated, getPromocodeSalePointID, getPromocodeDiscountedPayment} from "../redux-data/promocode/promocodeReducer";
import {getCurrentEAgentDate} from "../utils/utilsDate";

interface LiqPayPageProps {
  onClose?: (data: any) => any;
  onReady?: (data: any) => any;
  onCallback?: (data: any) => any;
}

const LiqPayPage = ({onCallback, onClose, onReady}: LiqPayPageProps) => {
  const offerId = useSelector(getOfferId)!;
  const offerEmail = useSelector(getOfferEmail)!;
  const policyAmount = useSelector(getSelectedAmountItem)!;
  const eAgentToken = useSelector(getEagentToken);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const promocodeName = useSelector(getPromocodeName);
  const promocodeIsActivated = useSelector(getPromocodeIsActivated);
  const promocodeSalePointID = useSelector(getPromocodeSalePointID);
  const promocodeDiscountedPayment = useSelector(getPromocodeDiscountedPayment);
  const customerFirstName = useSelector(getOfferCustomerFirstName);
  const customerMiddleName = useSelector(getOfferCustomerMiddleName);
  const customerLastName = useSelector(getOfferCustomerLastName);
  const [canLoadScript, setCanLoadScript] = useState(false);
  const productPrice = promocodeIsActivated && promocodeName && promocodeSalePointID === currentUserInfo?.salePoint.id ? promocodeDiscountedPayment : policyAmount.amount > policyAmount.discountedAmount ? policyAmount.discountedAmount : policyAmount.amount;
  const productDescription = `id ${currentUserInfo?.salePoint.id};Страховий платіж за договором страхування №${offerId} від ${getCurrentEAgentDate()} на суму ${productPrice} грн. без ПДВ, клієнт ${customerLastName} ${customerFirstName} ${customerMiddleName}`;

  useEffect(() => {
    prepareLiqpay();
  }, []);

  useEffect(() => {
    if (!canLoadScript) {
        return;
    }

    if (!window) {
        return;
    }
    
    const checkout = window.document.createElement("script");
    checkout.src = "https://static.liqpay.ua/libjs/checkout.js";
    checkout.id = "checkout";
    window.document.body.appendChild(checkout);

    return () => {
      checkout.remove();
    };
  }, [canLoadScript]);

  const getDataForLiqpay = async (isSandbox: boolean) => {
    try {
      const liqPayRes = await liqPayAxiosInstance.post("/api/v1/signature", {
        data: getPayloadLiqpay({
          amount: productPrice,
          order_id: offerId,
          isTest: isSandbox,
          customerEmail: offerEmail,
          description: productDescription,
          token: eAgentToken
        }),
      });
      return {
        success: true,
        data: liqPayRes.data
      };
    } catch (e) {
      console.error(">>> ERROR: ", e);
      return {
        success: false,
        data: null
      };
    }
  };

  const prepareLiqpay = async () => {
    const isSandboxMode = await isSettingItemActive(webConfig.enableLiqPaySandboxID);
    const res = await getDataForLiqpay(isSandboxMode);
    if (res.success) {
        const {data, signature} = res.data;
        window.LiqPayCheckoutCallback = function () {
          window.LiqPayCheckout.init({
            data,
            signature,
            embedTo: "#liqpay_checkout",
            language: "uk",
            mode: "embed", // embed || popup
          })
          .on("liqpay.callback", function (data) {
            onCallback && onCallback(data);
          })
          .on("liqpay.ready", function (data) {
            onReady && onReady(data);
          })
          .on("liqpay.close", function (data) {
            onClose && onClose(data);
          });
        };
        setCanLoadScript(true);
    }
  };

  return (
    <div className="pay">
      <div id="liqpay_checkout"></div>
    </div>
  );
};

export default LiqPayPage;
