import React, {useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux-data";
import useForm from "react-hook-form";
import {useStepsContext} from "../../utils/StepsProvider";
import {useId} from "react-id-generator";
import {setGreenCardDataOrder} from "../../redux-data/insurance/insuranceReducer";
import {areas, greencardPeriods, VehicleTypeList, PeriodsTypeList, AreasTypeList, vehicleTypes} from "../../core/insuranceData";
import {getVehicleCategoryByType} from "../../core/typeAuto";
import {AutoComplete} from "../MainPage/AutoComplete/AutoComplete";
import {hasCityZone, setData, setError} from "../../redux-data/city/cityReducer";
import RegCities from "./common/RegCities";
import {dbAxiosInstance} from "../../core/configDBAxios";
import CustomSelect from "../common/CustomSelect";

const GreenCardDataForm = (props) => {
    const data = useSelector(((state: RootState) => state.insurance.greenCardDataOrder));
    const {handleSubmit} = useForm({
        reValidateMode: "onChange",
        mode: "onChange",
        defaultValues: data
    });
    const [vehicleType, setVehicleType] = useState<VehicleTypeList>(data.vehicleType);
    const [coverageArea, setCoverageArea] = useState<AreasTypeList>(data.coverageArea);
    const [period, setPeriod] = useState<PeriodsTypeList>(data.period);
    const isCityZone = useSelector(hasCityZone);
    const {goTo} = useStepsContext() as any;
    const [formId] = useId(1, "form");
    const dispatch = useDispatch();
    const regLocationInput = useRef<HTMLInputElement>(null);
    const [city, setCity] = useState("");
    const [touch, setTouch] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [categoryError, setCategoryError] = useState(false);
    const [periodError, setPeriodError] = useState(false);
    const [coverageAreaError, setCoverageAreaError] = useState(false);
    const [category, setCategory] = useState(data.category);
    const [isVehicleTypeSelected, setIsVehicleTypeSelected] = useState(false);
    const feature = vehicleType ? getVehicleCategoryByType(vehicleType) : null;
    const featureOptions = feature ? feature.options : [];
    const featureName = feature ? feature.name : "";

    const onSubmit = async (data: any) => {
        if (city.length > 0 && isCityZone && category && vehicleType && coverageArea && period) {
            setTypeError(false);
            setCategoryError(false);
            setPeriodError(false);
            setCoverageAreaError(false);
            dispatch(setGreenCardDataOrder({
                ...data,
                vehicleType,
                coverageArea,
                category,
                period
            }));
            goTo(2);
        } else {
            if (!vehicleType) {
                setTypeError(true);
            }

            if (!category) {
                setCategoryError(true);
            }

            if (!coverageArea) {
                setCoverageAreaError(true);
            }

            if (!period) {
                setPeriodError(true);
            }

            if (regLocationInput.current) {
                regLocationInput.current.focus();

                if (regLocationInput.current.value.length === 0) {
                    setTouch(false);
                    dispatch(setError({
                        message: "Це поле обов'язкове"
                    }));
                }
            }
        }
    };

    const handleChangeCoverageArea = (e: any) => {
        setCoverageArea(e);
        setCoverageAreaError(false);
    };

    const handleChangeVehicleType = (e: any) => {
        setVehicleType(e);
        setCategory(getVehicleCategoryByType(e)!.options[0]);
        props.handleSelect();
        setTypeError(false);
        setCategoryError(false);
        setIsVehicleTypeSelected(true);

        if (regLocationInput.current) {
            regLocationInput.current.focus();
        }
    };

    const handleGreencardPeriodChange = (e: any) => {
        setPeriod(e);
        setPeriodError(false);
    };

    const handleCategoryChange = (e: any) => {
        setCategory(e);
        setCategoryError(false);
    };

    const setVechicleRegCity = async (event) => {
        const dataSuggestion = event.target.dataset.suggestion;

        if (regLocationInput.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
            regLocationInput.current.value = event.target.value;
            setCity(regLocationInput.current.value);
            setTouch(true);
            const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
            dispatch(setData(place.data[0]));
        } else {
            setCity("");
            setTouch(false);
        }
    };

    const handleVechileRegContent = (event, {newValue, method}) => {
        setCity(newValue);
        
        if (newValue !== "") {
            setTouch(true);
        } else {
            setTouch(false);
            dispatch(setError({
              message: "Це поле обов'язкове"
            }));
        }
    };

    const handleVechileRegContentBlur = () => {
        setTouch(true);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate={true} id={formId}>
            <div className="form-group">
                <CustomSelect
                    options={vehicleTypes}
                    isSearchable={false}
                    handleChange={handleChangeVehicleType}
                    value={vehicleType}
                    placeholder="Тип транспортного засобу"
                    isCreatable={false}
                    isError={typeError}
                />
            </div>
            {isVehicleTypeSelected &&
                <div className="form-group">
                    <CustomSelect
                        options={featureOptions}
                        isCreatable={false}
                        isSearchable={false}
                        handleChange={handleCategoryChange}
                        value={category}
                        placeholder={featureName}
                        isError={categoryError}
                    />
                </div>
            }
            <div className="form-group">
                <CustomSelect
                    options={greencardPeriods}
                    isCreatable={false}
                    isSearchable={false}
                    handleChange={handleGreencardPeriodChange}
                    value={period}
                    placeholder="Період дій договору"
                    isError={periodError}
                />
            </div>
            <div className="form-group">
                <CustomSelect
                    options={areas}
                    isCreatable={false}
                    isSearchable={false}
                    handleChange={handleChangeCoverageArea}
                    value={coverageArea}
                    placeholder="Територія покриття"
                    isError={coverageAreaError}
                />
            </div>
            <AutoComplete ref={regLocationInput} content={city} handleContent={handleVechileRegContent} isTouch={touch} handleContentBlur={handleVechileRegContentBlur} />
            <RegCities handleVechicleRegCity={setVechicleRegCity} />
            <button type="submit" className="btn btn-primary">порівняти ціни</button>
        </form>
    )
};

export default GreenCardDataForm;