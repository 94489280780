import React, {useState, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";

interface PaginationProps {
    data: any[];
    recordsPerPage: number;
    dataOnPage: (items: any) => void;
};

const Pagination = ({data, recordsPerPage, dataOnPage}: PaginationProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const numPages = Math.ceil(data.length / recordsPerPage);
    const [isDataLoaded, setDataLoaded] = useState(false);

    const getRecordsForCurrentPage = useCallback(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        return data.slice(firstIndex, lastIndex);
    }, [currentPage, data, recordsPerPage]);

    useEffect(() => {
        if (!isDataLoaded && data.length > 0) {
            dataOnPage(getRecordsForCurrentPage());
            setDataLoaded(true);
        }
    }, [data, currentPage, isDataLoaded, dataOnPage, getRecordsForCurrentPage]);

    const goToPage = (pageNum: number) => {
        if (currentPage !== pageNum) {
            setDataLoaded(false);
            setCurrentPage(pageNum);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            goToPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < numPages) {
            goToPage(currentPage + 1);
        }
    };

    const renderPagination = () => {
        const pages = Array.from({length: numPages}, (_, i) => i + 1);

        return (
            <ul className="pagination-container">
                {currentPage !== 1 && pages.length > 0 && (
                    <li className="pagination-page-item">
                        <Link to="#" className="pagination-page-link" onClick={prevPage}>Попередня</Link>
                    </li>
                )}
                {pages.length > 1 && pages.map((number, index) => (
                    <li className={`pagination-page-item ${currentPage === number ? "active" : ""}`} key={index}>
                        <Link to="#" className="pagination-page-link" onClick={() => goToPage(number)}>{number}</Link>
                    </li>
                ))}
                {currentPage !== numPages && pages.length > 0 && (
                    <li className="pagination-page-item">
                        <Link to="#" className="pagination-page-link" onClick={nextPage}>Наступна</Link>
                    </li>
                )}
            </ul>
        );
    };

    return (
        <div className="pagination-wrapper">
            {renderPagination()}
        </div>
    );
};

export default Pagination;
