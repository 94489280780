import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {DayPicker, MonthPicker, YearPicker} from "react-dropdown-date";
import Modal from "react-modal";
import {getShortDate, calculatePolicyDate} from "../../utils/utilsDate";
import {getEpolicyPeriod, getGreencardPeriod} from "../../redux-data/insurance/insuranceSelector";
import {useSelector} from "react-redux";

const date = new Date();
date.setDate(date.getDate() + 1);

const formatDate = (date) => {
  let d = new Date(date),
  month = "" + (d.getMonth() + 1),
  day = "" + d.getDate(),
  year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const InsurancePeriod = ({type, onSaveDate}: {type: "epolicy" | "greencard"; onSaveDate: any}) => {
  const [currentDate, setCurrentDate] = useState(date);
  const [nextDate, setNextDate] = useState(new Date());
  const [touchYear, setTouchYear] = useState(false);
  const [touchMonth, setTouchMonth] = useState(false);
  const [touchDay, setTouchDay] = useState(false);
  const [year, setYear] = useState<any>();
  const [month, setMonth] = useState<any>();
  const [day, setDay] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const nextEpolicyDate = new Date(currentDate);
  nextEpolicyDate.setDate(nextEpolicyDate.getDate() - 1);
  const nextGreencardDate = new Date(currentDate);
  nextGreencardDate.setDate(nextGreencardDate.getDate() - 1);
  const epolicyPeriod = useSelector(getEpolicyPeriod);
  const greencardPeriod = useSelector(getGreencardPeriod);

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const onClose = () => {
    onSaveDate(currentDate, nextDate);
    setIsOpen(false);
  };

  useEffect(() => {
    if (year && month && day) {
        setCurrentDate(new Date(year, month, day));
    }
  }, [year, month, day]);

  useEffect(() => {
    if (currentDate) {
        type === "epolicy" ? setNextDate(calculatePolicyDate(nextEpolicyDate, epolicyPeriod.type, epolicyPeriod.value)) : setNextDate(calculatePolicyDate(nextGreencardDate, greencardPeriod.type, greencardPeriod.value));
    }
  }, [currentDate]);

  const minDate = new Date();
  const maxDate = new Date();
  minDate.setFullYear(maxDate.getFullYear() - 100);
  maxDate.setFullYear(maxDate.getFullYear() + 3);

  return (
    <section className="search-result_one period">
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={{
          overlay: {
            zIndex: 2000,
          },
          content: {
            width: "26rem",
            height: "fit-content",
            margin: "auto",
            padding: "24px"
          },
        }}>
        <div>
          <div className="dateDropDown-container">
            <YearPicker
              defaultValue="Рік"
              start={+formatDate(minDate).split("-")[0]}
              end={+formatDate(maxDate).split("-")[0]}
              reverse
              required={true}
              value={year}
              onChange={(year) => {
                setYear(year);
                setTouchYear(true);
              }}
              id="year"
              name="year"
              classes={touchYear ? "dateDropDown-touch" : "dateDropDown"}
              optionClasses="dateDropDown-option"
            />
            <MonthPicker
              defaultValue="Місяць"
              numeric
              short
              caps
              endYearGiven
              year={year}
              required={true}
              value={month}
              onChange={(month) => {
                setMonth(month);
                setTouchMonth(true);
              }}
              id="month"
              name="month"
              classes={touchMonth ? "dateDropDown-touch" : "dateDropDown"}
              optionClasses="dateDropDown-option"
            />
            <DayPicker
              defaultValue="День"
              year={year}
              month={month}
              endYearGiven
              required={true}
              value={day}
              onChange={(day) => {
                setDay(day);
                setTouchDay(true);
              }}
              id="day"
              name="day"
              classes={touchDay ? "dateDropDown-touch" : "dateDropDown"}
              optionClasses="dateDropDown-option"
            />
          </div>
          <button onClick={() => onClose()} className="btn btn-link btn-primary btn-buy modal-insurance-save">
            Зберегти
          </button>
        </div>
      </Modal>
      <div>
        Період дії:
        <Link to="#" className="period_date" onClick={handleClick}>
          {getShortDate(currentDate)}{" "}
        </Link>
        ―{type === "epolicy" ? getShortDate(calculatePolicyDate(nextEpolicyDate, epolicyPeriod.type, epolicyPeriod.value)) : getShortDate(calculatePolicyDate(nextGreencardDate, greencardPeriod.type, greencardPeriod.value))}
        {type === "epolicy" ? `(${epolicyPeriod.label})` : `(${greencardPeriod.label})`}
      </div>
    </section>
  );
};

export default InsurancePeriod;
