import React, {useState, useEffect, useRef} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentDateTime} from "../../../utils/utilsDate";
import {checkValue, navToAdminPanel, isElectricMotorVehicle} from "../../../utils/utilsGeneral";
import {IVehicle} from "../../../redux-data/vehicle/vehicleTypes";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {AutoComplete} from "../../../components/MainPage/AutoComplete/AutoComplete";
import RegCities from "../../../components/Forms/common/RegCities";
import SwitchComponent from "react-switch";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";
import VehicleCategoryInfo from "./VehicleCategoryInfo";
import VehicleTypeInfo from "./VehicleTypeInfo";

const AddVehiclePage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [isVINChecked, setVINChecked] = useState(true);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [vehicleErrorMsg, setVehicleErrorMsg] = useState("");
    const [vehicleSuccessMsg, setVehicleSuccessMsg] = useState("");
    const [isVehicleAdded, setVehicleAdded] = useState(false);
    const [vehicle, setVehicle] = useState<IVehicle>({
        engineVolume: 0,
        year: 0,
        vin: "",
        category: "",
        kind: "",
        brand: "Назва - Skoda",
        model: "Octavia A7",
        modelText: "",
        stateNumber: "",
        dontHaveVIN: false,
        lastModified: "",
        autoColor: "",
        isForeignReg: false,
        grossWeight: 0,
        curbWeight: 0,
        seatingCapacity: 0,
        electricMotorPower: 0,
        registrationPlace: {
            id: 0,
            placeCode: 0,
            zone: 0, 
            nameFull: "", 
            country: "",
            name: "",
            nameRus: "", 
            nameFullRus: "", 
            cdbMtibu: false,
            cdbMtibuCode: 0,
            lastModified: ""
        }
    });
    const engineVolumeInput = useRef<HTMLInputElement>(null);
    const yearInput = useRef<HTMLInputElement>(null);
    const vinInput = useRef<HTMLInputElement>(null);
    const categoryInput = useRef<HTMLInputElement>(null);
    const kindInput = useRef<HTMLInputElement>(null);
    const brandInput = useRef<HTMLInputElement>(null);
    const modelInput = useRef<HTMLInputElement>(null);
    const modelTextInput = useRef<HTMLInputElement>(null);
    const stateNumberInput = useRef<HTMLInputElement>(null);
    const autoColorInput = useRef<HTMLInputElement>(null);
    const regPlaceIDInput = useRef<HTMLInputElement>(null);
    const regPlaceCodeInput = useRef<HTMLInputElement>(null);
    const vehicleRegLocationInput = useRef<HTMLInputElement>(null);
    const grossWeightInput = useRef<HTMLInputElement>(null);
    const curbWeightInput = useRef<HTMLInputElement>(null);
    const seatingCapacityInput = useRef<HTMLInputElement>(null);
    const electricMotorPowerInput = useRef<HTMLInputElement>(null);
    const [city, setCity] = useState("");
    const [touch, setTouch] = useState(false);
    const [isForeign, setForeign] = useState(false);

    const navToVehiclesPage = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/vehicles`);
    };

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const addVehicle = async (event) => {
        event.preventDefault();

        if (vehicle.engineVolume.toString().length > 0 && vehicle.year > 0 && vehicle.category.trim().length > 0 && vehicle.kind.trim().length > 0 && vehicle.brand.trim().length > 0 && vehicle.model!.trim().length > 0 && 
            vehicle.modelText!.trim().length > 0 && vehicle.stateNumber.trim().length > 0 && vehicle.lastModified.trim().length > 0) {
            try {
                const res = await dbAxiosInstance.post("/admin/add-vehicle", vehicle);
                setVehicleSuccessMsg(res.data);
                setVehicleAdded(true);
                setSuccessNotificationHidden(false);
                setVehicleErrorMsg("");
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setVehicleAdded(false);
                setVehicleSuccessMsg("");
                setVehicleErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setSuccessNotificationHidden(true);
                setErrorNotificationHidden(false);
            }
        } else {
            setVehicleAdded(false);
            setVehicleSuccessMsg("");
            setVehicleErrorMsg("Будь ласка, заповніть усі поля, щоб додати транспортний засіб!");
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
        setVehicleAdded(false);
        setVehicle((prev) => ({...prev, engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, autoColor: "", grossWeight: 0, curbWeight: 0, 
                               seatingCapacity: 0, electricMotorPower: 0, registrationPlace: {
            id: 0,
            placeCode: 0,
            zone: 0, 
            nameFull: "", 
            country: "",
            name: "",
            nameRus: "", 
            nameFullRus: "", 
            cdbMtibu: false,
            cdbMtibuCode: 0,
            lastModified: ""
        }}));
        
        if (engineVolumeInput.current) {
            engineVolumeInput.current.value = "";
        }

        if (yearInput.current) {
            yearInput.current.value = "";
        }

        if (vinInput.current) {
            vinInput.current.value = "";
        }

        if (categoryInput.current) {
            categoryInput.current.value = "";
        }

        if (kindInput.current) {
            kindInput.current.value = "";
        }

        if (brandInput.current) {
            brandInput.current.value = "";
        }

        if (modelInput.current) {
            modelInput.current.value = "";
        }

        if (modelTextInput.current) {
            modelTextInput.current.value = "";
        }

        if (stateNumberInput.current) {
            stateNumberInput.current.value = "";
        }

        if (autoColorInput.current) {
            autoColorInput.current.value = "";
        }

        if (regPlaceIDInput.current) {
            regPlaceIDInput.current.value = "";
        }

        if (regPlaceCodeInput.current) {
            regPlaceCodeInput.current.value = "";
        }

        if (grossWeightInput.current) {
            grossWeightInput.current.value = "";   
        }

        if (curbWeightInput.current) {
            curbWeightInput.current.value = "";
        }

        if (seatingCapacityInput.current) {
            seatingCapacityInput.current.value = "";
        }

        if (electricMotorPowerInput.current) {
            electricMotorPowerInput.current.value = "";
        }

        setVINChecked(true);
        setVehicleAdded(false);
        setVehicleSuccessMsg("");
        setCity("");
        setForeign(false);
    };

    const handleVINChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        const isVINCodeChecked = event.target.value.length <= 0 ? true : false;
        setVINChecked(isVINCodeChecked);
        setVehicle((prev) => ({...prev, vin: checkValue(event.target.value), dontHaveVIN: isVINCodeChecked}));
    };

    const handleDontHaveVINChange = (event) => {
        setVINChecked(event.target.checked);
    };

    const handleAutoColorChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicle((prev) => ({...prev, autoColor: checkValue(event.target.value)}));
    };

    const closeNotificationMsg = (event) => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const handleEngineVolumeChange = (event) => {
        setVehicle((prev) => ({...prev, engineVolume: checkValue(event.target.value)}));
    };

    const handleYearChange = (event) => {
        setVehicle((prev) => ({...prev, year: checkValue(event.target.value)}));
    };

    const handleCategoryChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicle((prev) => ({...prev, category: checkValue(event.target.value)}));
    };

    const handleKindChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicle((prev) => ({...prev, kind: checkValue(event.target.value)}));
    };

    const handleBrandChange = (event) => {
        const vehicleBrand = checkValue(event.target.value)
        setVehicle((prev) => ({...prev, brand: vehicleBrand, modelText: `${vehicleBrand} ${vehicle.model}`}));
    };

    const handleModelChange = (event) => {
        const vehicleModel = checkValue(event.target.value);
        setVehicle((prev) => ({...prev, model: vehicleModel, modelText: `${vehicle.brand} ${vehicleModel}`}));
    };

    const handleStateNumberChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicle((prev) => ({...prev, stateNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleVechileRegPlace = (event, {newValue, method}) => {
        setCity(newValue);
        
        if (newValue !== "") {
            setTouch(true);
        } else {
            setTouch(false);
        }
    };

    const handleVechileRegContentBlur = (event, {newValue, method}) => {
        setTouch(true);
    };

    const setVechicleRegCity = async (event) => {
        const dataSuggestion = event.target.dataset.suggestion;

        if (vehicleRegLocationInput.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
            vehicleRegLocationInput.current.value = event.target.value;
            setCity(vehicleRegLocationInput.current.value);
            setTouch(true);
            const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
            const regPlace = place.data[0];
            setVehicleRegLocation(regPlace);
        } else {
            setCity("");
            setTouch(false);
        }
    };

    const handleRegPlaceSuggestion = (suggestion) => {
        setVehicleRegLocation(suggestion);
    };

    const setVehicleRegLocation = (location) => {
        if (location) {
            setVehicle((prev) => ({...prev, registrationPlace: {
                id: location.id,
                placeCode: location.place_code,
                zone: location.zone, 
                nameFull: location.name_full, 
                country: location.country,
                name: location.name,
                nameRus: location.name_rus, 
                nameFullRus: location.name_full_rus, 
                cdbMtibu: location.cdb_mtibu,
                cdbMtibuCode: location.cdb_mtibu_code,
                lastModified: location.last_modified
            }}));
        } else {
            setVehicle((prev) => ({...prev, registrationPlace: {
                id: 0,
                placeCode: 0,
                zone: 0, 
                nameFull: "", 
                country: "",
                name: "",
                nameRus: "", 
                nameFullRus: "", 
                cdbMtibu: false,
                cdbMtibuCode: 0,
                lastModified: ""
            }}));
        }
    };

    const handleForeignStateChange = (state) => {
        setForeign(state);
        setVehicle((prev) => ({...prev, isForeignReg: state}));
    };

    const handleGrossWeightChange = (event) => {
        setVehicle((prev) => ({...prev, grossWeight: checkValue(event.target.value)}));
    };

    const handleCurbWeightChange = (event) => {
        setVehicle((prev) => ({...prev, curbWeight: checkValue(event.target.value)}));
    };

    const handleSeatingCapacityChange = (event) => {
        setVehicle((prev) => ({...prev, seatingCapacity: checkValue(event.target.value)}));
    };

    const handleElectricMotorPowerChange = (event) => {
        setVehicle((prev) => ({...prev, electricMotorPower: checkValue(event.target.value)}));
    };

    return (
        <div className="container">
            <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                    :
                    <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        {!isVehicleAdded ?
                        <>
                            <h1>Додати транспортний засіб</h1>
                            <div className="add-dashboard-item-form">
                                <CustomInput type="number" name="engineVolume" label="Об'єм двигуна (см3)" placeholder="Об'єм двигуна - 1598" onDataChange={handleEngineVolumeChange} ref={engineVolumeInput} />
                                <CustomInput type="number" name="year" label="Рік" placeholder="Рік - 2021" onDataChange={handleYearChange} ref={yearInput} />
                                <CustomInput type="text" name="vin" label="VIN код" placeholder="VIN код - TVBAN5NE7LB018349" onDataChange={handleVINChange} ref={vinInput} value={vehicle.vin} />
                                <CustomInput type="text" name="category" label="Категорія" placeholder="Категорія - B1, B2..." onDataChange={handleCategoryChange} ref={categoryInput} value={vehicle.category} />
                                <VehicleCategoryInfo />
                                {isElectricMotorVehicle(vehicle.category) && 
                                    <CustomInput 
                                        type="number" 
                                        name="electricMotorPower" 
                                        label="Потужність електродвигуна (кВт)" 
                                        placeholder="Потужність електродвигуна (кВт) - 600" 
                                        onDataChange={handleElectricMotorPowerChange} 
                                        ref={electricMotorPowerInput} 
                                        min={0}
                                    />
                                }
                                <CustomInput type="text" name="kind" label="Тип" placeholder="Тип - CAR, MOTO..." onDataChange={handleKindChange} ref={kindInput} value={vehicle.kind} />
                                <VehicleTypeInfo />
                                <CustomInput type="text" name="brand" label="Бренд" placeholder="Бренд - Skoda" onDataChange={handleBrandChange} ref={brandInput} />
                                <CustomInput type="text" name="model" label="Модель" placeholder="Модель - Octavia A7" onDataChange={handleModelChange} ref={modelInput} />
                                <CustomInput type="text" name="modelText" label="Назва" placeholder="Назва - Skoda Octavia A7" ref={modelTextInput} value={`${vehicle.brand} ${vehicle.model}`} isReadOnly={true} />
                                <CustomInput type="text" name="stateNumber" label="Державний номер" placeholder="Державний номер - AА0000AA" onDataChange={handleStateNumberChange} ref={stateNumberInput} value={vehicle.stateNumber} />
                                <div className="form-group form-check">
                                    <input name="dontHaveVIN" id="dont-have-vin" type="checkbox" hidden checked={isVINChecked} onChange={handleDontHaveVINChange} disabled />
                                    <label htmlFor="dont-have-vin">Немає VIN коду</label>
                                </div>
                                <CustomInput type="text" name="autoColor" label="Колір" placeholder="Колір - БІЛИЙ" onDataChange={handleAutoColorChange} ref={autoColorInput} value={vehicle.autoColor} />
                                <div className="switch-wrapper">
                                    <label className="switch-container">
                                        <SwitchComponent onChange={handleForeignStateChange} checked={isForeign} onColor="#1BA876" />
                                        <div className="switch-text">Iноземна реєстрація</div>
                                    </label>
                                </div>
                                {!isForeign &&
                                    <>
                                    <AutoComplete ref={vehicleRegLocationInput} content={city} handleContent={handleVechileRegPlace} handleContentSuggestion={handleRegPlaceSuggestion} handleContentBlur={handleVechileRegContentBlur} isTouch={touch} />
                                    <div className="vehicle-reg-location">
                                        <RegCities handleVechicleRegCity={setVechicleRegCity} />
                                    </div>
                                    <CustomInput type="number" name="regPlaceID" label="Ідентифікатор місця реєстрації" placeholder="Ідентифікатор місця реєстрації" ref={regPlaceIDInput} isReadOnly={true} value={vehicle.registrationPlace.id.toString()} />
                                    <CustomInput type="text" name="regPlaceCode" label="Код місця реєстрації" placeholder="Код місця реєстрації" ref={regPlaceCodeInput} isReadOnly={true} value={vehicle.registrationPlace.placeCode.toString()} />
                                    </>
                                }
                                <CustomInput type="number" name="grossWeight" label="Повна маса (кг)" placeholder="Повна маса - 2440" onDataChange={handleGrossWeightChange} ref={grossWeightInput} min={0} />
                                <CustomInput type="number" name="curbWeight" label="Маса без навантаження (кг)" placeholder="Маса без навантаження - 1940" onDataChange={handleCurbWeightChange} ref={curbWeightInput} min={0} />
                                <CustomInput type="number" name="seatingCapacity" label="Кількість місць (з водієм)" placeholder="Кількість місць (з водієм) - 5" onDataChange={handleSeatingCapacityChange} ref={seatingCapacityInput} min={0} />
                            </div>
                            <button type="submit" className="submit-btn" onClick={addVehicle}><i className="fa fa-plus notification-icon"></i>Додати</button>
                            <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        </>
                        :
                        <>
                            {!hideSuccessNotification && vehicleSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleSuccessMsg}</div>}
                        </>
                        }
                        <button type="button" className="admin-btn" onClick={navToVehiclesPage}>Транспортні засоби</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && vehicleErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleErrorMsg}</div>}
                    </>
                }
            </div>
        </div>
    );
};

export default AddVehiclePage;