import {AxiosResponse} from "axios";
import {dbAxiosInstance} from "../core/configDBAxios";
import {eAgentInstance} from "../core/configEAgentAxios";
import {ICompanyInsurance, IDataNumberOffer, IDataOffer, IEpolicyCompany, IEpolicyFranchise, IGreencardCashback, EAgentContractPayment} from "../typings/IDataOffers";
import {IDataOsagoPost} from "../typings/IDataOsagoPost";
import {parseEAgentErrorMessage} from "../utils/utilsGeneral";

const fetchToken = async () => {
  return await eAgentInstance.post("api/eagent/token", {
    apiKey: "xGvoHaPSiEFGxYxOA9I9Z9aufVyjpqWWkZuAUK0esFCO3Vshf9dv2UQD8Ese"
  });
};

const getCurrentCustomer = async () => {
  const res = await eAgentInstance.get("api/eagent/customer/current");
  return res.data;
};

const getLoginCustomerByEmail = async (email: string) => {
  const res = await eAgentInstance.get(`api/eagent/customer/login/${encodeURIComponent(email)}`);
  return res.data;
};

const getCustomerInfo = async (id: number) => {
  const res = await eAgentInstance.get(`api/eagent/customer/${encodeURIComponent(id)}`);
  return res.data;
};

const getVehicle = async (stateNumber: string) => {
  const res = await eAgentInstance.get(`api/eagent/vehicle/${encodeURIComponent(stateNumber)}`);
  return res.data;
};

const getCityCode = async (placeName: string) => {
  const res = await eAgentInstance.get(`api/eagent/place/country/${encodeURIComponent("UA")}/${encodeURIComponent(placeName)}`);
  return res.data;
};

const getCities = async () => {
  const res = await eAgentInstance.get(`api/eagent/place/full/country/${encodeURIComponent("UA")}`);
  return res.data;
};

const getOffers = async (type: "epolicy" | "greencard", data: IDataOffer) => {
  const epolicyCompanies: IEpolicyCompany[] = [];
  const epolicyFranchises: IEpolicyFranchise[] = [];
  const greencardCompanies: IGreencardCashback[] = [];

  if (type === "epolicy") {
      const epolicyCompaniesRes = await dbAxiosInstance.get("/epolicy/companies");
      epolicyCompaniesRes.data.forEach((epolicyCompany) => {
        epolicyCompanies.push({
          id: epolicyCompany.id,
          name: epolicyCompany.name,
          productID: epolicyCompany.product_id,
          isHidden: epolicyCompany.is_hidden
        });
      });

      const epolicyFranchisesRes = await dbAxiosInstance.get("/epolicy/franchises");
      epolicyFranchisesRes.data.forEach((epolicyFranchise) => {
        epolicyFranchises.push({
          id: epolicyFranchise.id,
          companyName: epolicyFranchise.company_name,
          franchiseName: epolicyFranchise.franchise_name,
          productID: epolicyFranchise.product_id,
          franchise: epolicyFranchise.franchise,
          franchiseID: epolicyFranchise.franchise_id,
          isHidden: epolicyFranchise.is_hidden
        });
      });
  }

  if (type === "greencard") {
      const greencardCompaniesRes = await dbAxiosInstance.get("/greencard/companies");
      greencardCompaniesRes.data.forEach((greencardCompany) => {
         greencardCompanies.push({
          id: greencardCompany.id,
          name: greencardCompany.name,
          companyID: greencardCompany.company_id,
          productCode: greencardCompany.product_code,
          isCashbackEnabled: greencardCompany.is_cashback_enabled,
          isHidden: greencardCompany.is_hidden,
          cashbackPercentageValue: greencardCompany.cashback_percentage
        });
      });
  }

  const res = await eAgentInstance.get(`api/eagent/tariff/choose/${encodeURIComponent(type)}`, {
    params: data
  });
  const model: any = res.data;
  const results: ICompanyInsurance[] = [];

  //todo delete fake
  let min: any = null;
  let max = 0;

  model.forEach(async (item: any, index: number) => {
    if (item.payment > 0 && !item.crossSell) {
        const company = results.find((result) => result.productId === item.tariff.insurer.id);
        const amount = +item.payment;
        const franchise = item.tariff.franchise ? +item.tariff.franchise : 0;
        const franchiseID = item.tariff.id ? +item.tariff.id : 0;

        if (min === null) {
            min = amount;
        }

        min = Math.min(min, amount);
        max = Math.max(max, amount);

        const isItemCashback = greencardCompanies.find((greencardCompany: IGreencardCashback) => greencardCompany.productCode === Number(item.tariff.insurer.code))?.isCashbackEnabled;
        const isGreencardItemHidden = greencardCompanies.find((greencardCompany: IGreencardCashback) => greencardCompany.productCode === Number(item.tariff.insurer.code))?.isHidden;
        const itemCashbackPercentage = greencardCompanies.find((greencardCompany: IGreencardCashback) => greencardCompany.productCode === Number(item.tariff.insurer.code))?.cashbackPercentageValue;
        const isEpolicyCompanyHidden = epolicyCompanies.find((epolicyCompany: IEpolicyCompany) => epolicyCompany.productID === Number(item.tariff.insurer.id))?.isHidden;
        const isEpolicyFranchiseHidden = epolicyFranchises.find((epolicyFranchise: IEpolicyFranchise) => epolicyFranchise.franchiseID === Number(item.tariff.id))?.isHidden;

        if (company) {
            company.data.push({
              amount,
              franchise,
              franchiseID,
              id: company.data.length,
              discountedAmount: item.discountedPayment,
              actionDescription: item.actionDescription,
              discountDescription: item.discountDescription,
              isCashback: isItemCashback ? isItemCashback : false,
              isHidden: (type === "greencard" ? isGreencardItemHidden : undefined) ?? (type === "epolicy" ? isEpolicyFranchiseHidden : false) ?? false,
              cashbackPercentage: itemCashbackPercentage ? itemCashbackPercentage : 0,
              productCode: item.tariff.insurer.code,
              crossSell: item.crossSell,
              onlyOwnInsurer: item.onlyOwnInsurer,
              name: item.tariff.name
            });
        } else {
            results.push({
              productId: item.tariff.insurer.id,
              contractorId: item.tariff.id,
              id: results.length,
              companyId: item.tariff.insurer.id,
              name: item.tariff.insurer.name,
              company: {
                id: item.tariff.company.id,
                type: item.tariff.company.type,
              },
              insurer: {
                id: item.tariff.insurer.id,
                type: item.tariff.insurer.type,
              },
              periods: item.tariff.periods && item.tariff.periods.map(item => item.period),
              data: [
                {
                  id: 0,
                  amount,
                  franchise,
                  franchiseID,
                  discountedAmount: item.discountedPayment,
                  actionDescription: item.actionDescription,
                  discountDescription: item.discountDescription,
                  isCashback: isItemCashback ? isItemCashback : false,
                  isHidden: (type === "greencard" ? isGreencardItemHidden : undefined) ?? (type === "epolicy" ? isEpolicyFranchiseHidden : false) ?? false,
                  cashbackPercentage: itemCashbackPercentage ? itemCashbackPercentage : 0,
                  productCode: item.tariff.insurer.code,
                  crossSell: item.crossSell,
                  onlyOwnInsurer: item.onlyOwnInsurer,
                  name: item.tariff.name
                }
              ],
              isHidden: (type === "epolicy" ? isEpolicyCompanyHidden : false) ?? false
            });
        }
    }
  });

  if (type === "epolicy") {
      const epolicyCompanyData: IEpolicyCompany[] = results.map((item) => {
        return {
          name: item.name,
          productID: Number(item.productId),
          isHidden: item.isHidden
        };
      });
      
      await dbAxiosInstance.post("/epolicy/add-companies", epolicyCompanyData);

      const epolicyFranchiseData: IEpolicyFranchise[] = results.flatMap((item) => {
        return item.data.map((dataItem) => ({
          companyName: item.name,
          franchiseName: dataItem.name,
          productID: Number(item.productId),
          franchise: dataItem.franchise,
          franchiseID: dataItem.franchiseID,
          isHidden: dataItem.isHidden
        }))
      });

      await dbAxiosInstance.post("/epolicy/company/add-franchise", epolicyFranchiseData);
  }

  if (type === "greencard") {
      const greencardData: IGreencardCashback[] = results.map((item) => {
        return {
          name: item.name,
          companyID: item.companyId,
          productCode: item.data[0].productCode,
          isCashbackEnabled: item.data[0].isCashback,
          cashbackPercentageValue: item.data[0].cashbackPercentage,
          isHidden: item.data[0].isHidden
        };
      });

      await dbAxiosInstance.post("/greencard/add-companies", greencardData);
  }

  return results;
};

const getOffersByNumber = async (data: IDataNumberOffer) => {
  try {
    const res = await eAgentInstance.get("api/eagent/tariff/choose/policy/statenumber", {
      params: data
    });
    const model: any = res.data;
    const results: ICompanyInsurance[] = [];
    const epolicyCompanies: IEpolicyCompany[] = [];
    const epolicyFranchises: IEpolicyFranchise[] = [];

    const epolicyCompaniesRes = await dbAxiosInstance.get("/epolicy/companies");
    epolicyCompaniesRes.data.forEach((epolicyCompany) => {
      epolicyCompanies.push({
        id: epolicyCompany.id,
        name: epolicyCompany.name,
        productID: epolicyCompany.product_id,
        isHidden: epolicyCompany.is_hidden
      });
    });

    const epolicyFranchisesRes = await dbAxiosInstance.get("/epolicy/franchises");
    epolicyFranchisesRes.data.forEach((epolicyFranchise) => {
      epolicyFranchises.push({
        id: epolicyFranchise.id,
        companyName: epolicyFranchise.company_name,
        franchiseName: epolicyFranchise.franchise_name,
        productID: epolicyFranchise.product_id,
        franchise: epolicyFranchise.franchise,
        franchiseID: epolicyFranchise.franchise_id,
        isHidden: epolicyFranchise.is_hidden
      });
    });

    //todo delete fake
    let min: any = null;
    let max = 0;

    model.forEach((item: any, index: number) => {
      if (item.payment > 0 && !item.crossSell) {
          const company = results.find((result) => result.productId === item.tariff.insurer.id);
          const amount = +item.payment;
          const franchise = item.tariff.franchise ? +item.tariff.franchise : 0;
          const franchiseID = item.tariff.id ? +item.tariff.id : 0;

          if (min === null) {
              min = amount;
          }

          min = Math.min(min, amount);
          max = Math.max(max, amount);

          const isEpolicyCompanyHidden = epolicyCompanies.find((epolicyCompany: IEpolicyCompany) => epolicyCompany.productID === Number(item.tariff.insurer.id))?.isHidden;
          const isEpolicyFranchiseHidden = epolicyFranchises.find((epolicyFranchise: IEpolicyFranchise) => epolicyFranchise.franchiseID === Number(item.tariff.id))?.isHidden;

          if (company) {
              company.data.push({
                amount,
                franchise,
                franchiseID,
                id: company.data.length,
                discountedAmount: item.discountedPayment,
                actionDescription: item.actionDescription,
                discountDescription: item.discountDescription,
                isCashback: false,
                cashbackPercentage: 0,
                isHidden: isEpolicyFranchiseHidden ? isEpolicyFranchiseHidden : false,
                productCode: item.tariff.insurer.code,
                crossSell: item.crossSell,
                onlyOwnInsurer: item.onlyOwnInsurer,
                name: item.tariff.name
              });
          } else {
              results.push({
                productId: item.tariff.insurer.id,
                contractorId: item.tariff.id,
                id: results.length,
                companyId: item.tariff.insurer.id,
                name: item.tariff.insurer.name,
                company: {
                  id: item.tariff.company.id,
                  type: item.tariff.company.type,
                },
                insurer: {
                  id: item.tariff.insurer.id,
                  type: item.tariff.insurer.type,
                },
                periods: item.tariff.periods && item.tariff.periods.map((item) => item.period),
                data: [
                  {
                    id: 0,
                    amount,
                    franchise, 
                    franchiseID,
                    discountedAmount: item.discountedPayment,
                    actionDescription: item.actionDescription,
                    discountDescription: item.discountDescription,
                    isCashback: false,
                    cashbackPercentage: 0,
                    isHidden: isEpolicyFranchiseHidden ? isEpolicyFranchiseHidden : false,
                    productCode: item.tariff.insurer.code,
                    crossSell: item.crossSell,
                    onlyOwnInsurer: item.onlyOwnInsurer,
                    name: item.tariff.name
                  },
                ],
                isHidden: isEpolicyCompanyHidden ? isEpolicyCompanyHidden : false
              });
          }
      }
    });

    const epolicyCompanyData: IEpolicyCompany[] = results.map((item) => {
      return {
        name: item.name,
        productID: Number(item.productId),
        isHidden: item.isHidden
      };
    });

    await dbAxiosInstance.post("/epolicy/add-companies", epolicyCompanyData);

    const epolicyFranchiseData: IEpolicyFranchise[] = results.flatMap((item) => {
      return item.data.map((dataItem) => ({
        companyName: item.name,
        franchiseName: dataItem.name,
        productID: Number(item.productId),
        franchise: dataItem.franchise,
        franchiseID: dataItem.franchiseID,
        isHidden: dataItem.isHidden
      }))
    });

    await dbAxiosInstance.post("/epolicy/company/add-franchise", epolicyFranchiseData);

    return results;
  } catch (ex: any) {
    throw new Error(ex.response.data.message);
  }
};

const createOffer = async (data: IDataOsagoPost) => {
  try {
    const res = await eAgentInstance.post("api/eagent/contract/save", data);

    if (res.status === 201 || res.status === 200) {
        return {
          success: true,
          id: res.data.id
        };
    } else {
        const parseData = JSON.parse(res.data.message);
        const first = Object.keys(parseData.errors)[0];
        const message = parseData.errors[first][0];

        return {
          success: false,
          message
        };
    }
  } catch (error: any) {
    const eAgentErrorMsg = parseEAgentErrorMessage(error);
    
    return {
      success: false,
      message: eAgentErrorMsg || "Виникла помилка на стороні сервісу страхування. Зверніться до нас за допомогою: <a href=\"mailto:pessorgua@gmail.com\">pessorgua@gmail.com</a>"
    };
  }
};

const getMarkCode = async () => {
  const res = await eAgentInstance.get("api/eagent/auto-model/makers");
  return res.data || [];
};

const getMarkCodeByName = async (name: string) => {
  if (!name) {
      return [];
  }

  const res = await eAgentInstance.get(`api/eagent/auto-model/makers/${encodeURIComponent(name)}`);
  return res.data || [];
};

const getModelCode = async (brandID: string) => {
  if (!brandID) {
      return [];
  }

  const res = await eAgentInstance.get(`api/eagent/auto-model/models/maker/${encodeURIComponent(brandID)}`);
  return res.data || [];
};

const mapGetModelCode = async (brandID: string) => {
  const res = await getModelCode(brandID);
  return res.map((item: any) => ({
    label: item.name,
    value: item.id,
    mtsbuId: item.mtsbuId
  }));
};

const manageOffer = async (id: string, state: string) => {
  return customFetch(() => eAgentInstance.post(`api/eagent/contract/${encodeURIComponent(id)}/${encodeURIComponent(state)}`));
};

const sendOPT = async (id: string) => {
  return customFetch(() => eAgentInstance.get(`api/eagent/contract/${encodeURIComponent(id)}/otp/send/customer`));
};

const confirmOPT = async ({id, password}: {id: string; password: string}) => {
  return customFetch(() => eAgentInstance.get(`api/eagent/contract/${encodeURIComponent(id)}/otp/customer/${encodeURIComponent(password)}`));
};

const createContractPayment = async (data: EAgentContractPayment) => {
  return customFetch(() => eAgentInstance.post("api/eagent/contract-payment/create", data));
};

const confirmContractPayment = async (data: EAgentContractPayment) => {
  return customFetch(() => eAgentInstance.post("api/eagent/contract-payment/confirm", data));
};

const applyPromocode = (salePointID: string, promocode: string, id: string) => {
  return customFetch(() => eAgentInstance.get(`api/eagent/promocode/apply/${encodeURIComponent(salePointID)}/${encodeURIComponent(promocode)}/${encodeURIComponent(id)}`));
};

const customFetch = async <T = any>(func: () => Promise<AxiosResponse<T>>) => {
  try {
    const res = await func();
    return {
      success: true,
      data: res.data as T,
      code: res.status,
    };
  } catch (e: any) {
    return {
      success: false,
      message: "Помилка сервера",
      code: e.response.status || 500,
      error: e
    };
  }
};

const serviceEAgent = {
    fetchToken,
    getCurrentCustomer,
    getLoginCustomerByEmail,
    getCustomerInfo,
    getVehicle,
    getCityCode,
    getCities,
    getOffers,
    getOffersByNumber,
    createOffer,
    getMarkCode,
    getMarkCodeByName,
    getModelCode,
    mapGetModelCode,
    manageOffer,
    sendOPT,
    confirmOPT,
    createContractPayment,
    confirmContractPayment,
    applyPromocode
};

export default serviceEAgent;