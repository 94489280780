import React from "react";
import CustomTooltip from "./CustomTooltip";
import webConfig from "../../../config";
import diiaAppStoreBadge from "../../../assets/img/logos/app-store-badge.svg";
import diiaGooglePlayBadge from "../../../assets/img/logos/google-play-badge.webp";

const GetDiiaAppComponent = () => {
    return (
        <li>Завантажити останню версію застосунку Дія можна тут:
            <div className="diia-downloads-container">
                <CustomTooltip msg="Завантажити застосунок Дія з App Store"><a href={webConfig.diiaAppStore} target="_blank" rel="noopener noreferrer"><img src={diiaAppStoreBadge} alt="diia-app-store" /></a></CustomTooltip>
                <CustomTooltip msg="Завантажити застосунок Дія з Google Play"><a href={webConfig.diiaGooglePlay} target="_blank" rel="noopener noreferrer"><img src={diiaGooglePlayBadge} alt="diia-google-play" /></a></CustomTooltip>
            </div>
        </li>
    );
};

export default GetDiiaAppComponent;