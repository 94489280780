import React from "react";
import CustomTooltip from "./CustomTooltip";
import {getCashback} from "../../../redux-data/insurance/insuranceSelector";

interface PolicyCashbackProps {
    isCashback: boolean;
    cashbackPercentage: number;
    franchiseID: number;
    amount: number;
}

const PolicyCashback = ({isCashback, cashbackPercentage, franchiseID, amount}: PolicyCashbackProps) => {
    return (
        <>
            {isCashback && cashbackPercentage > 0 && 
                <CustomTooltip msg="Гарантовано кешбек повертаємо протягом трьох робочих днів">
                <div key={franchiseID} className="cashback-item">Кешбек {cashbackPercentage}% на Вашу карту {getCashback(amount, cashbackPercentage).toFixed(2)} ₴</div>
                </CustomTooltip>
            }
        </>
    );
};

export default PolicyCashback;