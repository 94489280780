import React, {createContext, useContext, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useMatch} from "react-router-dom";
import {v4} from "uuid";
import {setIdToInsuranceStep, setInsuranceStepToId, getIDToInsuranceStep, getInsuranceStepToID} from "../redux-data/steps/stepsReducer";

const STEPS = 5;

const generateSteps = (steps: number = STEPS) => {
  const stepToId: any = {};
  const idToStep: any = {};

  for (let i = 1; i <= steps; i++) {
      const id = v4();
      stepToId[i] = id;
      idToStep[id] = i;
  }

  return [stepToId, idToStep];
};

export const StepsContext = createContext(null);

export default function StepsProvider({children}: any) {
  const storedStepToID = useSelector(getInsuranceStepToID);
  const idToStoredStep = useSelector(getIDToInsuranceStep);
  const navigate = useNavigate();
  const match = useMatch(`/:name/:id`);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeSteps = () => {
      if (Object.keys(storedStepToID).length === 0 && Object.keys(idToStoredStep).length === 0) {
          const [initialStepToID, initialIDToStep] = generateSteps();
          dispatch(setInsuranceStepToId(initialStepToID));
          dispatch(setIdToInsuranceStep(initialIDToStep));
      }
    }

    initializeSteps();
  }, [dispatch, storedStepToID, idToStoredStep]);

  const getStepByID = (id: any) => {
    return idToStoredStep ? idToStoredStep[id] || 1 : null;
  };

  const getFirst = useCallback(() => {
    return storedStepToID ? storedStepToID[1] : null;
  }, [storedStepToID]);

  const goTo = (step: number) => {
    navigate(`/${match?.params.name}/${storedStepToID[step]}`);
  };

  const navigateToStep = (type: string, step: number) => {
    navigate(`/${type}/${storedStepToID[step]}`);
  };

  const validateStep = (idToPersistedStep, currentStepID, stepNumber) => {
    return idToPersistedStep[currentStepID] === stepNumber;
  };

  const value: any = {
    getStepByID,
    goTo,
    navigateToStep,
    getFirst,
    validateStep
  };

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>;
}

export function useStepsContext() {
  return useContext(StepsContext);
};