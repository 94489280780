import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {portmoneAxiosInstance} from "../core/configPortmoneAxios";
import {getOfferId, getOfferEmail, getOfferCustomerFirstName, getOfferCustomerMiddleName, getOfferCustomerLastName} from "../redux-data/offer/offerReducer";
import {getSelectedAmountItem} from "../redux-data/insurance/insuranceSelector";
import {getCurrentUserInfo} from "../redux-data/user/userReducer";
import {getPromocodeDiscountedPayment, getPromocodeIsActivated, getPromocodeName, getPromocodeSalePointID} from "../redux-data/promocode/promocodeReducer";
import {getCurrentEAgentDate, getPortmoneExpirationDate} from "../utils/utilsDate";
import webConfig from "../config";

const PortmonePage = ({onError}: {onError?: (data: any) => void}) => {
    const offerId = useSelector(getOfferId)!;
    const offerEmail = useSelector(getOfferEmail)!;
    const policyAmount = useSelector(getSelectedAmountItem)!;
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const promocodeName = useSelector(getPromocodeName);
    const promocodeIsActivated = useSelector(getPromocodeIsActivated);
    const promocodeSalePointID = useSelector(getPromocodeSalePointID);
    const promocodeDiscountedPayment = useSelector(getPromocodeDiscountedPayment);
    const customerFirstName = useSelector(getOfferCustomerFirstName);
    const customerMiddleName = useSelector(getOfferCustomerMiddleName);
    const customerLastName = useSelector(getOfferCustomerLastName);
    const [pageUrl, setPageUrl] = useState("");
    const [portmoneError, setPortmoneError] = useState(false);
    const productPrice = promocodeIsActivated && promocodeName && promocodeSalePointID === currentUserInfo?.salePoint.id ? promocodeDiscountedPayment : policyAmount.amount > policyAmount.discountedAmount ? policyAmount.discountedAmount : policyAmount.amount;
    const productDescription = `id ${currentUserInfo?.salePoint.id};Страховий платіж за договором страхування №${offerId} від ${getCurrentEAgentDate()} на суму ${productPrice} грн. без ПДВ, клієнт ${customerLastName} ${customerFirstName} ${customerMiddleName}`;

    useEffect(() => {
        const handlePaymentPage = async () => {
            try {
                const portmoneRes = await portmoneAxiosInstance.post("/portmone/checkout", {
                    data: {
                        amount: productPrice,
                        shopOrderNumber: offerId,
                        emailRecipient: offerEmail,
                        expDate: getPortmoneExpirationDate(),
                        lang: webConfig.portmoneLang,
                        description: productDescription,
                        bill_currency: webConfig.portmoneCurrency
                    }
                });
                const paymentPage = portmoneRes.data.result.linkInvoice;

                if (paymentPage) {
                    setPageUrl(paymentPage);
                    setPortmoneError(false);
                } else {
                    setPageUrl("");
                    setPortmoneError(true);
                }
            } catch (error: any) {
                onError?.(error);
                setPageUrl("");
                setPortmoneError(false);
            }
        };

        handlePaymentPage();
    }, [offerEmail, offerId, productDescription, productPrice, onError]);

    return (
        <div className="portmone-checkout">
            {pageUrl && !portmoneError && <iframe src={pageUrl} className="portmone-page-container" title="portmonePageContainer" allow="payment"></iframe>}
            {!pageUrl && portmoneError && <div className="customer-error-notification">Не вдалось завантажити сторінку оплати Portmone!</div>}
        </div>
    );
};

export default PortmonePage;