import React from "react";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-owl-carousel2/lib/styles.css";
import "./assets/css/select2.min.css";
import "./assets/scss/styles.scss";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import configureAppStore from "./store";
//import {configEuaAxios} from "./core/configEuaAxios";
import {createRoot} from "react-dom/client";
import {configEAgentAxios} from "./core/configEAgentAxios";
import StepsProvider from "./utils/StepsProvider";
import MediaQueryProvider from "./utils/MediaQueryProvider";
import DevModeContextProvider from "./utils/DevModeProvider";

const {store, persistor} = configureAppStore(undefined);
//configEuaAxios(store);
configEAgentAxios(store);

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <StepsProvider>
          <MediaQueryProvider>
            <DevModeContextProvider>
              <App />
            </DevModeContextProvider>
          </MediaQueryProvider>
        </StepsProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);