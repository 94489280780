import React, {useEffect, useState} from "react";
import {useMatch} from "react-router-dom";
import {useStepsContext} from "../../utils/StepsProvider";
import {getInsuranceType, isSettingItemActive, checkAuthToken, checkGreencardINGOCompany, filterDiiaDocuments, filterWebsiteDocuments, filterBankIDDocuments} from "../../utils/utilsGeneral";
import DataOrder from "./DataOrder";
import {useDispatch, useSelector} from "react-redux";
import {setItemId} from "../../redux-data/insurance/insuranceReducer";
import {getOfferData, setData, setOfferBankIdServiceInitialized, setOfferDocumentServiceSelected, setOfferDocumentType, setOfferBankIdServiceAuthorized, setOfferIsTestBankIdDomain} from "../../redux-data/offer/offerReducer";
import useForm from "react-hook-form";
import {IOfferData} from "../../redux-data/offer/offerTypes";
import {getCashback, getDiscountPercentage, getPrivilege, getSelectedAmountItem, getSelectedItem, getAnalyticsId, getProductCode} from "../../redux-data/insurance/insuranceSelector";
import {bankIDDocuments, diiaDocuments, websiteDocuments, privilegeTypes} from "../../mock-data/insuranceTypes";
import webConfig from "../../config";
import CustomTooltip from "../Forms/common/CustomTooltip";
import {getCustomerPrivilege} from "../../redux-data/user/userSelector";
import CustomSelect from "../common/CustomSelect";
import {setPrivilegeType, getCurrentUser, getCurrentUserInfo} from "../../redux-data/user/userReducer";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {getEagentToken} from "../../redux-data/misc/miscReducer";
import bankIDLogo from "../../assets/img/logos/bankid.svg";
import diiaLogo from "../../assets/img/logos/diia.svg";
import pessLogo from "../../assets/img/logos/pess_icon.webp";

const StepThree = ({type}: {type: "epolicy" | "greencard"}) => {
  const dataInsurance = (useSelector(getOfferData) || {}) as IOfferData;
  const policy = useSelector(getSelectedItem);
  const policyAmount = useSelector(getSelectedAmountItem);
  const productCode = useSelector(getProductCode)!;
  const isPrivilege = useSelector(getPrivilege);
  const customerPrivilege = useSelector(getCustomerPrivilege);
  const currentUser = useSelector(getCurrentUser);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const analyticsID = useSelector(getAnalyticsId);
  const eAgentToken = useSelector(getEagentToken);
  const {goTo} = useStepsContext() as any;
  const match = useMatch(`/:name/:id`);
  const currentInsurance = getInsuranceType(match?.params.name!);
  const dispatch = useDispatch();
  const [policySelectedDocument, setPolicySelectedDocument] = useState("");
  const [errorPrivilege, setErrorPrivilege] = useState(false);
  const [privilegeTypeError, setPrivilegeTypeError] = useState(false);
  const [isDiiaDocumentSelected, setDiiaDocumentSelected] = useState(false);
  const [isBankIDDocumentSelected, setBankIDDocumentSelected] = useState(false);
  const [isWebsiteDocumentSelected, setWebsiteDocumentSelected] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isDiiaVisible, setDiiaVisible] = useState(false);
  const [isBankIDVisible, setBankIDVisible] = useState(false);
  const [isBankIDTestDomain, setBankIDTestDomain] = useState(false);

  useEffect(() => {
      window.scrollTo(0, 0);

      const checkAdminRights = () => {
          if (checkAuthToken(eAgentToken)) {
              if (currentUser && currentUserInfo) {
                  if (currentUser.sessionId && currentUserInfo?.permissions.salePointAdmin) {
                      setAdmin(true);
                  } else {
                      setAdmin(false);
                  }
              } else {
                 setAdmin(false);
              }
          } else {
              setAdmin(false);
          }
      };

      const checkDiiaVisiblity = async () => {
          const diiaState = await isSettingItemActive(webConfig.displayDiiaID);
          setDiiaVisible(diiaState);
      };

      const checkBankIDVisiblity = async () => {
          const bankIDState = await isSettingItemActive(webConfig.displayBankID);
          setBankIDVisible(bankIDState);
      };

      const checkBankIDDomain = async () => {
          const isTestBankID = await isSettingItemActive(webConfig.enableTestBankID);
          setBankIDTestDomain(isTestBankID);
      };

      const updateAnalyticsData = async () => {
        try {
          const analytics = {insurerName: policy!.name, franchise: `${policyAmount!.franchise} грн`, insuranceStep: 3};
          await dbAxiosInstance.put(`/update-analytics/insurance/company/${encodeURIComponent(analyticsID!)}`, analytics);
        } catch (error: any) {}
      };

      checkAdminRights();
      checkDiiaVisiblity();
      checkBankIDVisiblity();
      checkBankIDDomain();
      updateAnalyticsData();
  }, [analyticsID, currentUser, currentUserInfo, eAgentToken, policy, policyAmount]);

  const {getValues} = useForm<IOfferData>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: dataInsurance
  });
  
  const handleStepBack = () => {
    dispatch(setItemId(null));
    goTo(2);
  };

  const handleChangePolicy = () => {
    dispatch(setData(getValues()));
    dispatch(setItemId(null));
    goTo(2);
  };

  const handleChangeDataOrder = () => {
    dispatch(setData(getValues()));
    dispatch(setItemId(null));
    goTo(1);
  };

  const bankIDCustomerDocs = checkGreencardINGOCompany(type, productCode) ? [bankIDDocuments[2]] : filterBankIDDocuments(type);
  const diiaCustomerDocs = checkGreencardINGOCompany(type, productCode) ? [diiaDocuments[2]] : filterDiiaDocuments(type);
  const websiteCustomerDocs = checkGreencardINGOCompany(type, productCode) ? [websiteDocuments[3]] : filterWebsiteDocuments(type);
  const classPrivilege = !customerPrivilege && errorPrivilege ? "form-group form-group-error" : "form-group";
  
  const handleDocumentChange = (document) => {
    const selectedDocument = document.target.value;
    setPolicySelectedDocument(selectedDocument);
    dispatch(setOfferDocumentType(selectedDocument));
    goTo(4);
  };

  const handlePrivilegeTypeChange = (type) => {
    dispatch(setPrivilegeType(type));
    setErrorPrivilege(false);
    setPrivilegeTypeError(false);
    setPolicySelectedDocument(type.value);
    dispatch(setOfferDocumentType(type.value));
    dispatch(setOfferDocumentServiceSelected("PESS"));
    dispatch(setOfferBankIdServiceInitialized(false));
    dispatch(setOfferBankIdServiceAuthorized(false));
    dispatch(setOfferIsTestBankIdDomain(isBankIDTestDomain));
    goTo(4);
  };

  const handleBankIDDocSelection = () => {
    setPolicySelectedDocument("");
    setWebsiteDocumentSelected(false);
    setDiiaDocumentSelected(false);
    setBankIDDocumentSelected(!isBankIDDocumentSelected);
    dispatch(setOfferDocumentServiceSelected("BANKID"));
    dispatch(setOfferBankIdServiceInitialized(true));
    dispatch(setOfferBankIdServiceAuthorized(false));
    dispatch(setOfferIsTestBankIdDomain(isBankIDTestDomain));
  };

  const handleDiiaDocSelection = () => {
    setPolicySelectedDocument("");
    setWebsiteDocumentSelected(false);
    setBankIDDocumentSelected(false);
    setDiiaDocumentSelected(!isDiiaDocumentSelected);
    dispatch(setOfferDocumentServiceSelected("DIIA"));
    dispatch(setOfferBankIdServiceInitialized(false));
    dispatch(setOfferBankIdServiceAuthorized(false));
    dispatch(setOfferIsTestBankIdDomain(isBankIDTestDomain));
  };

  const handleWebsiteDocSelection = () => {
    setPolicySelectedDocument("");
    setDiiaDocumentSelected(false);
    setBankIDDocumentSelected(false);
    setWebsiteDocumentSelected(!isWebsiteDocumentSelected);
    dispatch(setOfferDocumentServiceSelected("PESS"));
    dispatch(setOfferBankIdServiceInitialized(false));
    dispatch(setOfferBankIdServiceAuthorized(false));
    dispatch(setOfferIsTestBankIdDomain(isBankIDTestDomain));
  };

  return (
    <>
       <div className="row pay-choose pay-policy">
          <div className="col-sm-8">
            <h4>Виберіть документ для договору страхування - Електронна {currentInsurance.label}</h4>
            <div className="pay-policy_form">
              <div className="select-policy-document">
                {isPrivilege ?
                  <div className="col-md-12">
                    <div className={classPrivilege}>
                      <label>Тип пільги</label>
                      <CustomSelect
                        options={privilegeTypes}
                        handleChange={handlePrivilegeTypeChange}
                        value={customerPrivilege}
                        isCreatable={false}
                        isSearchable={false}
                        placeholder="Тип пільги"
                        isError={privilegeTypeError}
                      />
                    </div>
                  </div>
                 :
                  <>
                    {
                      <>
                      {(isBankIDVisible || isAdmin) && type === "epolicy" &&
                        <div className="diia-document-wrapper">
                            <div className="diia-document-container">
                                <CustomTooltip msg="Виберіть документ через BankID">
                                    <div className="bankid-btn" onClick={handleBankIDDocSelection}>
                                        Вибрати документ через <img src={bankIDLogo} alt="bankIDDocumentSelection" />
                                    </div>
                                </CustomTooltip>
                            </div>
                            {isBankIDDocumentSelected &&
                              <>
                                <div className="policy-document-title">
                                  <h3>Виберіть документ, який Ви маєте</h3>
                                </div>
                                <div className="document-type-wrapper">
                                  {bankIDCustomerDocs.map((document) => (
                                    <div className="policy-document-type" key={document.value}>
                                      <div className="form-group form-radio">
                                        <input
                                          onChange={handleDocumentChange}
                                          type="radio"
                                          id={document.value}
                                          value={document.value}
                                          checked={document.value === policySelectedDocument}
                                          hidden
                                          disabled={document.isDisabled}
                                          name="documentType"
                                        />
                                        <label htmlFor={document.value}>{document.label}</label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                            </>
                            }
                        </div>
                      }
                      {(isDiiaVisible || isAdmin) &&
                        <div className="diia-document-wrapper">
                            <div className="diia-document-container">
                                <CustomTooltip msg="Виберіть документ через застосунок Дія">
                                    <div className="diia-btn" onClick={handleDiiaDocSelection}>
                                        Вибрати документ через <img src={diiaLogo} alt="diiaDocumentSelection" />
                                    </div>
                                </CustomTooltip>
                            </div>
                            {isDiiaDocumentSelected &&
                              <>
                                <div className="policy-document-title">
                                  <h3>Виберіть документ, який маєте у застосунку Дія</h3>
                                </div>
                                <div className="document-type-wrapper">
                                  {diiaCustomerDocs.map((document) => (
                                    <div className="policy-document-type" key={document.value}>
                                      <div className="form-group form-radio">
                                        <input
                                          onChange={handleDocumentChange}
                                          type="radio"
                                          id={document.value}
                                          value={document.value}
                                          checked={document.value === policySelectedDocument}
                                          hidden
                                          disabled={document.isDisabled}
                                          name="documentType"
                                        />
                                        <label htmlFor={document.value}>{document.label}</label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                            </>
                          }
                       </div>
                      }
                      <div className="document-wrapper">
                        <div className="document-container">
                            <CustomTooltip msg="Виберіть документ через сайт">
                                <div className="pess-btn" onClick={handleWebsiteDocSelection}>
                                    Вибрати документ через <img src={pessLogo} alt="websiteDocumentSelection" />
                                </div>
                            </CustomTooltip>
                        </div>
                        {isWebsiteDocumentSelected &&
                          <>
                            <div className="policy-document-title">
                              <h3>Виберіть документ, який Ви маєте</h3>
                            </div>
                            <div className="document-type-wrapper">
                              {websiteCustomerDocs.map((document) => (
                                <div className="policy-document-type" key={document.value}>
                                  <div className="form-group form-radio">
                                    <input
                                      onChange={handleDocumentChange}
                                      type="radio"
                                      id={document.value}
                                      value={document.value}
                                      checked={document.value === policySelectedDocument}
                                      hidden
                                      disabled={document.isDisabled}
                                      name="documentType"
                                    />
                                    <label htmlFor={document.value}>{document.label}</label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        }
                      </div>
                     </>
                    }
                 </>
                }
               </div>
               <div className="policy-controls">
                  <button type="button" className="insurance-btn" onClick={handleStepBack}><i className="fa-regular fa-arrow-left notification-icon"></i>Назад</button>
               </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pay-choose_info">
              <DataOrder type={type} handleChangeDataOrder={handleChangeDataOrder} />
              <div className="pay-choose_info-block">
                <p className="subtitle green">Поліс</p>
                <ul className="pay-choose_info-list">
                  <li>
                    <p>Назва поліса</p>
                    <div className="font-bold">{policy && policy.name}</div>
                  </li>
                  <li>
                    <p>Франшиза</p>
                    <div className="font-bold">{policyAmount && policyAmount!.franchise} грн</div>
                  </li>
                  <li>
                    <p>Вартість</p>
                    {policyAmount && policyAmount.amount > policyAmount.discountedAmount ? 
                      <div className="font-bold">
                        <div className="old-price">{policyAmount.amount.toFixed(2)} грн</div>
                      </div>
                      : 
                      <div className="font-bold">{policyAmount?.amount.toFixed(2)} грн</div>
                    }
                  </li>
                  {policyAmount && policyAmount.amount > policyAmount.discountedAmount &&
                    <>
                      <li>
                        <p>До оплати</p>
                        <div className="font-bold">{policyAmount!.discountedAmount.toFixed(2)} грн</div>
                      </li>
                      {type === "epolicy" &&
                        <li>
                          <p>Знижка</p>
                          <div className="font-bold">{getDiscountPercentage(policyAmount!.amount, policyAmount!.discountedAmount).toFixed(0)}%</div>
                        </li>
                      }
                    </>
                  }
                  {type === "greencard" && policyAmount?.isCashback &&
                    <>
                      <li>
                        <p>Кешбек</p>
                        <div className="font-bold">{policyAmount.cashbackPercentage.toFixed(0)}%</div>
                      </li>
                      <li>
                        <p>Сума до повернення на Вашу карту</p>
                        <div className="font-bold">{getCashback(policyAmount.amount, policyAmount.cashbackPercentage).toFixed(2)} ₴</div>
                      </li>
                    </>
                  }
                </ul>
                <button onClick={handleChangePolicy} className="btn btn-link btn-edit">
                  Змінити
                </button>
              </div>
            </div>
          </div>
       </div>
    </>
  );
};

export default StepThree;