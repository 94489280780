import React from "react";
import {formatEmail} from "../../utils/utilsGeneral";

const Reviews = () => {
    return (
        <div className="container">
            <div className="section-white">
                <div>Вибачте за тимчасові незручності! Свої відгуки та пропозиції просимо надсилати на наш E-mail: <span dangerouslySetInnerHTML={{__html: formatEmail("pessorgua@gmail.com")}} /></div>
            </div>
        </div>
    );
};

export default Reviews;