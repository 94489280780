import React, {useState, useEffect, useCallback, useContext} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {filterRecords, countRecords} from "../../../utils/utilsGeneral";
import {DevModeContext} from "../../../utils/DevModeProvider";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import SwitchComponent from "react-switch";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import Pagination from "../../../utils/Pagination";
import webConfig from "../../../config";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const GeneralSettingsPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const devCtx = useContext(DevModeContext);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [generalSettingsCount, setGeneralSettingsCount] = useState(0);
    const [generalSettingsErrorMsg, setGeneralSettingsErrorMsg] = useState("");
    const [settings, setSettings] = useState<any[]>([]);
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [settingState, setSettingState] = useState<any[]>([]);

    useEffect(() => {
        const fetchAllSettingsItems = async () => {
            try {
                const res = await dbAxiosInstance.get("/general-settings");
                const dbSettingsData = res.data;

                if (dbSettingsData) {
                    if (dbSettingsData.length > 0) {
                        setSettings(dbSettingsData);
                        const isItemActive = dbSettingsData.map((item, index) => {
                            return Boolean(item.is_active);
                        });
                        setSettingState(isItemActive);
                        setGeneralSettingsErrorMsg("");
                    } else {
                        setSettings([]);
                        setGeneralSettingsErrorMsg("Немає доданих елементів загальних налаштувань. Натисніть на кнопку, щоб додати елемент загальних налаштувань.");
                    }
                } else {
                    setSettings([]);
                    setGeneralSettingsErrorMsg("Помилка БД! Не вдалося завантажити елементи загальних налаштувань.");
                }
            } catch (error: any) {
                setSettings([]);
                setGeneralSettingsErrorMsg("Помилка БД! Не вдалося завантажити соц. мережі.");
            }
        };

        const countAllSettingsItems = async () => {
            try {
                const res = await dbAxiosInstance.get("/count-general-settings");
                const dbCountGeneralSettingsData = res.data;

                if (dbCountGeneralSettingsData) {
                    if (dbCountGeneralSettingsData.length > 0) {
                        setGeneralSettingsCount(dbCountGeneralSettingsData[0].counter);
                    } else {
                        setGeneralSettingsCount(0);
                    }
                } else {
                    setGeneralSettingsCount(0);
                }
            } catch (error: any) {
                setGeneralSettingsCount(0);
            }
        };
        
        fetchAllSettingsItems();
        countAllSettingsItems();
    }, []);

    const handleRecordsOnPage = useCallback((records) => {
        setRecordsOnPage(records);
    }, []);

    const handleSettingStateChange = async (position: number, id: number, itemID: number) => {
        try {
            const updatedSettingState = settingState.map((item, index) => {
                return index === position ? !item : item;
            });
            
            const settingItemState = {
                isActive: updatedSettingState[position]
            };

            await dbAxiosInstance.put(`/edit-general-setting-state/${encodeURIComponent(id)}`, settingItemState);
            setSettingState(updatedSettingState);

            if (itemID === Number(webConfig.enableDevModeID)) {
                devCtx.setDevMode(settingItemState.isActive);
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setGeneralSettingsErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setSettings([]);
        }
    };

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/general-setting/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            setSettings(filterRecords(settings, id));
            const allRecords = countRecords(generalSettingsCount);
            setGeneralSettingsCount(allRecords);

            if (allRecords === 0) {
                setGeneralSettingsErrorMsg("Немає доданих елементів загальних налаштувань. Натисніть на кнопку, щоб додати елемент загальних налаштувань.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setGeneralSettingsErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setSettings([]);
        }
    };

    return (
        <div className="container">
            <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                    :
                    <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Загальні налаштування</h1>
                        <div className="itemsCounter">
                            <p>Всього: <span className="font-bold">{generalSettingsCount}</span></p>
                        </div>
                        <div className="dashboard-item">
                            <CustomTooltip msg="Додати"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/add-general-setting`}}><i className="fa fa-plus"></i> Додати</Link></CustomTooltip>
                        </div>
                        <div className="settings-wrapper">
                            <div className="row">
                                {settings.length > 0 && generalSettingsCount > 0 ?
                                    <>
                                        {settings.map((generalSetting, index) =>
                                            <div className="col-md-6" key={index}>
                                                <div className="settings-switch-wrapper">
                                                    <div className="manage-setting-item-wrapper">
                                                        <div className="manage-setting-item-container">
                                                            <CustomTooltip msg="Редагувати">
                                                                <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/edit-general-setting/${generalSetting.id}`}}>
                                                                    <i className="fa fa-pencil-alt"></i>
                                                                </Link>
                                                            </CustomTooltip>
                                                            <CustomTooltip msg="Видалити"><Link to="#" className="delete-item" onClick={() => handleItemDelete(generalSetting.id)}><i className="fa fa-trash"></i></Link></CustomTooltip>
                                                        </div>
                                                        <div className="switch-wrapper">
                                                            <label className="switch-container">
                                                                <SwitchComponent onChange={() => handleSettingStateChange(index, generalSetting.id, generalSetting.item_id)} checked={settingState[index]} onColor="#1BA876" />
                                                                <div className="switch-text">{generalSetting.name}</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <Pagination data={settings} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} />
                                    </>
                                : <div className="no-dashboard-item">{generalSettingsErrorMsg}</div>
                                }
                            </div>
                        </div>
                    </div>
                    </>
                }
            </div>
        </div>
    );
};

export default GeneralSettingsPage;