import React, {useEffect, useState, useCallback} from "react";
import Modal from "react-modal";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {setCurrentUser, setData} from "../../../redux-data/user/userReducer";
import webConfig from "../../../../src/config";
import {encryptBase64} from "../../../utils/utilsGeneral";
import CustomLoader from "../../Forms/common/CustomLoader";
import CustomTooltip from "../../Forms/common/CustomTooltip";
import {patternEmail} from "../../../utils/getInstanceError";
import EmailTemplate from "../../../views/dashboard/newsletters/EmailTemplate";
import TabsComponent from "../../../utils/TabsComponent";
import {TabProp} from "../../../utils/tab";
import PartnerLoginComponent from "../PartnerLoginComponent";
import VerificationCode from "../VerificationCode";
import {setEagentToken} from "../../../redux-data/misc/miscReducer";
import serviceEAgent from "../../../service/serviceEAgent";

Modal.setAppElement("#modal-root");

interface IPublicModal {
  isOpen: boolean;
  onCLose: (event?: any) => void;
  type: string;
  title?: string;
  data?: any;
  onVerifCodeResend?: () => void;
  onVerifComplete?: (code: string) => void;
  isError?: boolean;
  errorMsg?: string;
  successMsg?: string;
  onCloseCustomerErrorNotifMsg?: () => void; 
}

const PublicModal = ({isOpen, onCLose, type, title = "", data = "", onVerifCodeResend, onVerifComplete, isError, errorMsg, successMsg, onCloseCustomerErrorNotifMsg}: IPublicModal) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState<any>("");
  const [loginErrorMsg, setLoginErrorMsg] = useState<any>();
  const [isLogingIn, setLogingIn] = useState(false);
  const [currentLoginTab, setCurrentLoginTab] = useState(1);
  const [htmlPreviewContent, setHtmlPreviewContent] = useState("");

  useEffect(() => {
      const errorNotifTimer = setTimeout(() => {
        setLoginErrorMsg("");
      }, 5000);

      return () => clearTimeout(errorNotifTimer);
  }, [loginErrorMsg]);

  const handleEmailContent = useCallback((content: any) => {
    setHtmlPreviewContent(content);
  }, []);

  const handleDlgClose = () => {
    window.scrollTo(0, 0);
    onCLose();
  };

  if (type === "login") {
      const onSubmit = async () => {
        if (email.length > 0 && patternEmail.test(email) && password.length > 0) {
            try {
                setLogingIn(true);
                setLoginErrorMsg("");
                dispatch(setEagentToken(`Basic ${encryptBase64(`${email}:${password}`)}`));
                const currentCustomerRes = await serviceEAgent.getCurrentCustomer();
                const userDataRes = await serviceEAgent.getCustomerInfo(currentCustomerRes.user.id);
                dispatch(setCurrentUser(currentCustomerRes));
                dispatch(setData(userDataRes));
                setLoginErrorMsg("");
                onCLose();
                navigate("/");
            } catch (error: any) {
                setLogingIn(false);
                setLoginErrorMsg(error.message || "Помилка авторизації!");
            } finally {
                setEmail("");
                setPassword("");
                setLogingIn(false);
            }
        } else {
            setLogingIn(false);
            setLoginErrorMsg("Введіть Ваш email і пароль правильно!");
        }
      };

      const closeNotificationMsg = () => {
        setLoginErrorMsg("");
      };

      const loginTabs: TabProp[] = [
        {
          index: 1,
          label: "Вхід для партнерів",
          isDisabled: false,
          children: <PartnerLoginComponent handleSubmit={onSubmit} handleClose={onCLose} errorMsg={loginErrorMsg} handleCloseNotifMsg={closeNotificationMsg} emailPattern={patternEmail} handleEmail={setEmail} handlePassword={setPassword} />
        },
        {
          index: 2,
          isDisabled: true,
          label: "Вхід для клієнтів",
          children: <div>Розділ в розробці!</div>
        }
      ];

      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onCLose}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: {
              zIndex: 2000
            },
            content: {
              maxWidth: "max-content",
              maxHeight: "max-content",
              width: "auto",
              height: "auto",
              margin: "auto",
              padding: "24px",
              inset: "10px"
            },
          }}>
          {isLogingIn ?
            <CustomLoader className="account-logging-in" isSquare={true} isInfoOn={true} isLoading={isLogingIn} infoMsg="Вхід у особистий кабінет" />
          :
            <>
              <TabsComponent 
                tabs={loginTabs} 
                defaultTab={1} 
                activeTab={currentLoginTab} 
                orientation={isMobile ? "vertical" : "horizontal"} 
                tabsDisabledMsg="Вхід в систему тимчасово недоступний!" 
                isContentPadding={false} 
                onSelectedTab={(id) => setCurrentLoginTab(id)} 
              />
            </>
          }
        </Modal>
      );
  } else if (type === "email-preview") {
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "1000px",
            maxHeight: "600px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => onCLose()}></i></CustomTooltip>
          <p className="dialog-title">{title}</p>
          <div dangerouslySetInnerHTML={{__html: htmlPreviewContent}} />
          <EmailTemplate content={data} setEmailContent={handleEmailContent} isEmail={true} isTemplateGeneration={isOpen} />
          <div className="dialog-controls">
            <button type="button" className="dialog-btn" onClick={() => onCLose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "diia") {
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "300px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={handleDlgClose}></i></CustomTooltip>
          <p className="dialog-title">{title}</p>
          <div className="dialog-content">
            <p>Копії документів успішно отримано</p>
          </div>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={handleDlgClose}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "customer-identification") {
    const expirationTime = 2 * 60 * 1000;
    const allowedCharacters = new RegExp("^[0-9]+$").source;
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "400px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Або можна заповнити дані власноруч"><i className="fa-solid fa-xmark dialog-close-icon" onClick={handleDlgClose}></i></CustomTooltip>
          <p className="dialog-title">{title}</p>
          <div className="dialog-content">
            {isError && errorMsg && <div className="dialog-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={onCloseCustomerErrorNotifMsg}></i></CustomTooltip>{errorMsg}</div>}
            <VerificationCode title="На Ваш email відправлено код авторизації:" inputType="number" inputMode="numeric" allowedChars={allowedCharacters} count={Number(webConfig.customerVerificationCodeLength)} name="customerIdentificationCode" expTime={expirationTime} 
              onVerificationCodeResend={onVerifCodeResend} onVerificationComplete={onVerifComplete} />
          </div>
          <div className={isError ? "dialog-controls" : "manage-dialog-controls"}>
            <button type="button" className="dialog-btn" onClick={handleDlgClose}><i className="fa-solid fa-xmark notification-icon"></i>Або можна заповнити дані власноруч</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "bankid-error") {
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "300px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Заповнити дані власноруч"><i className="fa-solid fa-xmark dialog-close-icon" onClick={handleDlgClose}></i></CustomTooltip>
          <p className="dialog-title-error">{title}</p>
          <div className="dialog-content">
            <i className="fa-light fa-circle-xmark dialog-error-icon"></i>
            <p>Не вдалось отримати дані авторизації BankID!</p>
          </div>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={handleDlgClose}><i className="fa-solid fa-xmark notification-icon"></i>Заповнити дані власноруч</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "promocode-verification") {
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "400px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={handleDlgClose}></i></CustomTooltip>
          <p className={`${successMsg ? "dialog-title" : "dialog-title-error"}`}>{title}</p>
          <div className="dialog-content">
            {successMsg ?
              <>
                <i className="fa-light fa-circle-check dialog-success-icon"></i>
                <p>{successMsg}</p>
              </>
             :
              <>
                <i className="fa-light fa-circle-xmark dialog-error-icon"></i>
                <p>{errorMsg}</p>
              </>
            }
          </div>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={handleDlgClose}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default PublicModal;
