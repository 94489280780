import React, {useState, useRef, useEffect, useCallback} from "react";
import useForm from "react-hook-form";
import {useNavigate, useParams, Link} from "react-router-dom";
import promocodeGenerator from "voucher-code-generator";
import {getMaxDate, getCurrentDateTime} from "../../../utils/utilsDate";
import CustomField from "../../../components/MainPage/CustomField";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {checkValue, navToAdminPanel, refreshPage} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import CustomTextarea from "../../../components/MainPage/CustomTextarea";
import {AddPromocode} from "../../../typings/IDataOffers";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";
import CustomSelect from "../../../components/common/CustomSelect";
import {salePoints} from "../../../mock-data/insuranceTypes";

const AddPromocodePage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const [promocodesData, setPromocodesData] = useState<AddPromocode>({
        promocodes: [],
        maxActivations: 1,
        validUntil: "",
        added: "",
        discount: 0,
        salePointID: 0
    });
    const [promocodesCount, setPromocodesCount] = useState(0);
    const promocodeCountInput = useRef<HTMLInputElement>(null);
    const promocodeMaxActivationInput = useRef<HTMLInputElement>(null);
    const promocodeDiscountInput = useRef<HTMLInputElement>(null);
    const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [isPromocodeAdded, setPromocodeAdded] = useState(false);
    const [promocodeSuccessMsg, setPromocodeSuccessMsg] = useState("");
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [isCopyBtnClicked, setCopyBtnClicked] = useState(false);
    const [selectedSalePoint, setSelectedSalePoint] = useState(null);
    const [salePointError, setSalePointError] = useState(false);

    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();

    const {register, errors, clearError} = useForm({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    useEffect(() => {
        const notifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
            setSuccessNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(notifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const handleCountChange = (event) => {
        setPromocodesCount(event.target.value);
    };

    const generatePromocodes = (event) => {
        event.preventDefault();

        if (promocodesCount > 0 && promocodesData.maxActivations > 0 && promocodesData.discount > 0 && promocodesData.validUntil && promocodesData.salePointID > 0) {
            const allPromocodes = promocodeGenerator.generate({
                length: 6,
                count: promocodesCount,
                charset: "0123456789",
                prefix: "PESS"
            });
            setPromocodesData((prev) => ({...prev, promocodes: allPromocodes}));
            addPromocodes(allPromocodes);
        } else {
            setPromocodeErrorMsg("Будь ласка, заповніть усі поля, щоб додати промокод!");
            setPromocodeSuccessMsg("");
            setErrorNotificationHidden(false);
            setSuccessNotificationHidden(true);
            checkSalePointError();           
        }
    };

    const addPromocodes = async (promocodes) => {
        try {
            const pessPromocodes = {
                ...promocodesData,
                promocodes
            };

            await dbAxiosInstance.post("/add-promocodes", pessPromocodes);
            setPromocodeErrorMsg("");
            setErrorNotificationHidden(true);
            setPromocodeAdded(true);
            setPromocodeSuccessMsg("Промокод(и) успішно додано. Натисність на кнопку \"Копіювати\", щоб зберегти промокод(и) в буфер обміну.");
            setSuccessNotificationHidden(false);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setPromocodeErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setPromocodeSuccessMsg("");
            setErrorNotificationHidden(false);
            setSuccessNotificationHidden(true);
            checkSalePointError();
        }
    };

    const checkSalePointError = () => {
        if (promocodesData.salePointID === 0) {
            setSalePointError(true);
        }
    };

    const handlePromocodesChange = (data) => {
        setPromocodesData((prev) => ({...prev, promocodes: checkValue(data)}));
    };

    const handleMaxActivationCountChange = (event) => {
        setPromocodesData((prev) => ({...prev, maxActivations: checkValue(event.target.value)}));
    };

    const handlePromocodeValidUntil = useCallback((name, date) => {
        const promocodeAdded = getCurrentDateTime();
        setPromocodesData((prev) => ({...prev, validUntil: date, added: promocodeAdded}));
        clearError(name);
    }, [clearError]);

    const clearFields = (event) => {
        event.preventDefault();
        setPromocodesData((prev) => ({...prev, promocodes: [], maxActivations: 0, validUntil: "", added: "", discount: 0}));
        setPromocodeErrorMsg("");
        setPromocodeSuccessMsg("");
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
        setSelectedSalePoint(null);
        setSalePointError(false);

        if (promocodeCountInput.current) {
            promocodeCountInput.current.value = "";
        }

        if (promocodeMaxActivationInput.current) {
            promocodeMaxActivationInput.current.value = "";
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const copyPromocodes = useCallback(() => {
        setPromocodeSuccessMsg("Промокод(и) успішно скопійовано до буферу обміну.");
        setSuccessNotificationHidden(false);
        setCopyBtnClicked(false);
    }, []);

    const navToPromocodesPage = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/promocodes`);
    };

    const handleDiscountChange = (event) => {
        setPromocodesData((prev) => ({...prev, discount: event.target.value}));
    };

    const handleSalePointChange = (salePoint) => {
        setSelectedSalePoint(salePoint);
        setPromocodesData((prev) => ({...prev, salePointID: salePoint.value}));
        setSalePointError(false);
    };

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    {!isPromocodeAdded ?
                        <>
                        <h1>Додати промокоди</h1>
                        <div className="add-dashboard-item-form">
                            <CustomInput type="number" name="count" label="Кількість промокодів" placeholder="Кількість промокодів" onDataChange={handleCountChange} ref={promocodeCountInput} />
                            <CustomInput 
                                type="number" 
                                name="maxActivations" 
                                label="Максимальна кількість активацій" 
                                placeholder="Максимальна кількість активацій" 
                                onDataChange={handleMaxActivationCountChange} 
                                ref={promocodeMaxActivationInput} 
                                value={promocodesData.maxActivations.toString()}
                                isReadOnly={true}
                            />
                            <div className="percentage-container">
                                <CustomInput type="number" name="discount" label="Знижка (%)" id="percentage-field" placeholder="0" onDataChange={handleDiscountChange} ref={promocodeDiscountInput} value={promocodesData.discount.toString()} min={0} />
                                <i className="fa-solid fa-percent percentage-icon"></i>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className={salePointError ? "select-error-label" : ""}>Вибрати точку продажу</label>
                                    <CustomSelect
                                        options={salePoints}
                                        handleChange={handleSalePointChange}
                                        value={selectedSalePoint}
                                        isCreatable={false}
                                        isDisabled={false}
                                        placeholder="Точка продажу"
                                        isError={salePointError}
                                    />
                                </div>
                            </div>
                            <CustomField register={register({required: "Це поле обов'язкове"})} errors={errors} name="validUntil" label="Дійсний до" type="date" placeholder="РРРР.ММ.ДД" max={getMaxDate()} autoComplete="off" 
                                onDateChange={handlePromocodeValidUntil} />
                        </div>
                        <button type="submit" className="submit-btn" onClick={generatePromocodes}><i className="fa fa-plus notification-icon"></i>Додати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        </>
                        :
                        <>
                        {!hideSuccessNotification && promocodeSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeSuccessMsg}</div>}
                        <CustomTextarea
                            name="promocodes"
                            label="Промокоди"
                            placeholder="Всі промокоди" 
                            value={promocodesData.promocodes.join(", ")} 
                            onDataChange={handlePromocodesChange}
                            isBBCode={false}
                            isFocus={false}
                            rows={6}
                            isCopy={isCopyBtnClicked}
                            onDataCopyToClipboard={copyPromocodes}
                        />
                        <button type="button" className="admin-btn" onClick={() => setCopyBtnClicked(true)}><i className="fa-regular fa-copy notification-icon"></i>Копіювати</button>
                        </>
                    }
                    <button type="button" className="admin-btn" onClick={navToPromocodesPage}>Промокоди</button>
                    <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                </div>
                {!hideErrorNotification && promocodeErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeErrorMsg}</div>}
                </>
            }
            </div>
        </div>
    );
};

export default AddPromocodePage;