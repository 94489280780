import {RootState} from "../redux-data";
import {IDataOsagoPost} from "../typings/IDataOsagoPost";
import {
  getCalculatorAutoType,
  getIsForeign,
  getIsTaxi,
  getPolicyId,
  getPrivilege,
  getRightCityId,
  getCoverageArea,
  getVehicleType,
  getPolicyCompany, 
  getPolicyInsurer,
  getDontHaveInnCode,
  getOtkDate,
  getAutoKind,
  getPolicyPeriod,
  getPolicyPayment,
  getPolicyDiscountedPayment
} from "../redux-data/insurance/insuranceSelector";
import {getOfferMark, getOfferModel, validateOfferStatus, StatusOffer} from "../redux-data/offer/offerReducer";
import {IOfferAddress, IOfferData} from "../redux-data/offer/offerTypes";
import {getSalePointCompanyId, getSalePointId, getUserId, getCustomerPrivilege} from "../redux-data/user/userSelector";
import {getCorrectDateFromFull, getCorrectDateToFull, getTodayDate, getTodayDay} from "./utilsDate";
import webConfig from "../config";
import {isVehicleOtkRequired} from "./utilsGeneral";

export class ConvertToOffer {
  constructor(private data: IOfferData, private state: RootState) {
      
  }
  
  private getAddress = (address: IOfferAddress, isLatin = false) => {
    return `${address.name}, ${address.street} ${address.number_house}, ${isLatin ? "apartment " : "кв."}${address.number_flat}`;
  };
  
  private getDocInstance = () => {
    switch (this.data.documentType) {
      case "PASSPORT":
        return this.data.passport;
      case "ID_PASSPORT":
        return this.data.idPassport;
      case "DRIVING_LICENSE":
        return this.data.driverLicense;
      case "EXTERNAL_PASSPORT":
        return this.data.externalPassport;
      case "PENSION_CERTIFICATE":
        return this.data.pensionCertificate;
      case "E_PENSION_CERTIFICATE":
        return this.data.ePensionCertificate;
      case "DISABILITY_CERTIFICATE":
        return this.data.disabilityCertificate;
      case "VETERAN_CERTIFICATE":
        return this.data.veteranCertificate;
      case "CHERNOBYL_CERTIFICATE":
        return this.data.chernobylCertificate;
      case "COMBAT_CERTIFICATE":
        return this.data.combatCertificate;
      case "WAR_DISABILITY_CERTIFICATE":
        return this.data.warDisabilityCertificate;
      case "REVOLUTION_CERTIFICATE":
        return this.data.revolutionCertificate;
      default:
        return null;
    }
  };

  private getDocNumber = () => {
    return this.getDocInstance()!.number;
  };

  private getDocRecord = () => {
    if (this.data.documentType === "ID_PASSPORT") {
        return this.getDocInstance()!.record;
    }

    return null;
  };

  private getDocSeries = () => {
    if (this.data.documentType === "ID_PASSPORT") {
        return null;
    }

    return this.getDocInstance()!.series;
  };

  private getIssuedBy	= () => {
    return this.getDocInstance()!.issued_by;
  };

  private getNumber	= () => {
    return this.getDocInstance()!.date_end;
  };

  private setRegistrationPlace = () => {
    let registrationPlace: any = null;

    if (getRightCityId(this.state)) {
        registrationPlace = {
          id: getRightCityId(this.state)
        };
    }

    return registrationPlace;
  };

  private setVehicleModel = () => {
    let vehicleModel: any = null;

    if (getOfferModel(this.state)!.value && getOfferMark(this.state)!.id) {
        vehicleModel = {
          id: getOfferModel(this.state)!.value,
          autoMaker: {
            id: getOfferMark(this.state)!.id
          }
        };
    }

    return vehicleModel;
  };

  private isPrivilegeDocumentType = () => {
    if (getPrivilege(this.state)) {
        return getCustomerPrivilege(this.state).description;
    }

    return null;
  };

  private isHaveINNCode = () => {
    if (getDontHaveInnCode(this.state)) {
        return null;
    } else {
        return this.data.INN;
    }
  };

  private getDontHaveStateNumber = () => {
    if (this.data.transport.chassis_number) {
        return false;
    }

    return true;
  };

  private getDocDate = () => {
    return this.getDocInstance()!.date;
  };

  private getType = () => {
    const vehicle = this.state.vehicle.data;

    if (vehicle && vehicle.type) {
        return vehicle.type;
    }

    return getCalculatorAutoType(this.state);
  };

  private getKind = () => {
    const vehicle = this.state.vehicle.data;

    if (vehicle && vehicle.kind) {
        return vehicle.kind;
    }

    return getAutoKind(this.state);
  };

  convert = (type: "epolicy" | "greencard") => {
    const isOtkRequired = isVehicleOtkRequired(this.getKind(), this.getType());

    const obj: IDataOsagoPost = {
      type: type === "epolicy" ? "epolicy2025" : "egreencard",
      sourceInfo: webConfig.pessWebsite!,
      salePoint: {
        id: getSalePointId(this.state),
        company: {
          type: "broker",
          id: getSalePointCompanyId(this.state)
        }
      },
      number: "",
      user: {
        id: getUserId(this.state)
      },
      tariff: {
        type: type === "epolicy" ? "epolicy2025" : "egreencard",
        id: getPolicyId(this.state),
        company: getPolicyCompany(this.state),
        insurer: getPolicyInsurer(this.state)
      },
      period: getPolicyPeriod(type, this.state)!,
      payment: getPolicyPayment(this.state)!,
      brokerDiscountedPayment: getPolicyDiscountedPayment(this.state)!,
      date: getTodayDate(getTodayDay()),
      dateFrom: getCorrectDateFromFull(this.data.startFrom ? new Date(this.data.startFrom) : getTodayDay()),
      dateTo: getCorrectDateToFull(this.data.expirationDate ? new Date(this.data.expirationDate) : getTodayDay()),
      customer: {
        code: this.isHaveINNCode(),
        dontHaveCode: getDontHaveInnCode(this.state),
        nameLast: this.data.surname,
        nameFirst: this.data.name,
        nameMiddle: this.data.middle_name,
        address: this.getAddress(this.data.address, type !== "epolicy"),
        phone: this.data.phone_number,
        email: this.data.email,
        birthDate: this.data.date_of_birth,
        document: {
          type: this.data.documentType,
          series: this.getDocSeries(),
          number: this.getDocNumber(),
          date: this.getDocDate(),
          issuedBy: this.getIssuedBy(),
          registrationType: "PERMANENT",
          otkDate: isOtkRequired ? getOtkDate(this.state) : null,
          dateEnd: this.getNumber() || "",
          record: this.getDocRecord()
        }
      },
      insuranceObject: {
          type: "auto",
          category: type === "epolicy" ? this.getType() : undefined,
          model: this.setVehicleModel(),
          autoMaker: getOfferMark(this.state)!.name,
          autoModel: getOfferModel(this.state)!.label,
          stateNumber: this.data.transport.global_number,
          bodyNumber: this.data.transport.chassis_number,
          outsideUkraine: getIsForeign(this.state),
          registrationType: "PERMANENT",
          otkDate: isOtkRequired ? getOtkDate(this.state) : null,
          year: this.data.transport.year_of_issue,
          registrationPlace: this.setRegistrationPlace(),
          engineVolume: this.data.transport.engine_volume,
          grossWeight: this.data.transport.grossWeight,
          curbWeight: this.data.transport.curbWeight,
          seatingCapacity: this.data.transport.seatingCapacity,
          dontHaveBodyNumber: this.getDontHaveStateNumber()
      },
      state: validateOfferStatus(StatusOffer.DRAFT),
      bonusMalus: 1,
      taxi: getIsTaxi(this.state),
      privilegeType: this.isPrivilegeDocumentType(),
      vehicleType: type === "greencard" ? getVehicleType(this.state).code : undefined,
      coverageArea: type === "greencard" ? getCoverageArea(this.state).value : undefined
    };
    
    return obj;
  }
}