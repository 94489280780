import React from "react";
import {useParams} from "react-router-dom";
import Steps from "../components/MainPage/Steps";
import {hasInsurance} from "../utils/utilsGeneral";
import NotFoundComponent from "./common/NotFoundPage";

const InsurancePage = (props: any) => {
  const {name} = useParams<{name: string}>();
  const instance = hasInsurance(name!);

  if (instance) {
      return (
        <Steps />
      );
  } else {
      return (
        <NotFoundComponent />
      );
  }
};

export default InsurancePage;