import React, {useEffect, useState, useCallback} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {countRecords, filterRecords, isMatchCaseSearch} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import DataFilter from "../../../utils/DataFilter";
import FilterPagination from "../../../utils/FilterPagination";
import webConfig from "../../../config";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {IEpolicyCompany} from "../../../typings/IDataOffers";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const EpolicyPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [epolicyData, setEpolicyData] = useState<IEpolicyCompany[]>([]);
    const [epolicyCompaniesCount, setEpolicyCompaniesCount] = useState(0);
    const [recordsOnPage, setRecordsOnPage] = useState<IEpolicyCompany[]>([]);
    const [hiddenState, setHiddenState] = useState<any[]>([]);
    const [filteredItems, setFilteredItems] = useState<IEpolicyCompany[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);
    const [epolicyErrorMsg, setEpolicyErrorMsg] = useState("");

    useEffect(() => {
        const fetchEpolicyProducts = async () => {
            try {
                const res = await dbAxiosInstance.get("/epolicy/companies");
                const dbEpolicyData = res.data;

                if (dbEpolicyData) {
                    if (dbEpolicyData.length > 0) {
                        const epolicyCompanies: IEpolicyCompany[] = dbEpolicyData.map((item) => {
                            return {
                                id: item.id, 
                                name: item.name, 
                                isHidden: Boolean(item.is_hidden), 
                                productID: item.product_id
                            };
                        });
                        setEpolicyData(epolicyCompanies);
                        setFilteredItems(epolicyCompanies);
                        const isCompanyHidden = epolicyCompanies.map((item, index) => {
                            return item.isHidden;
                        });
                        setHiddenState(isCompanyHidden);
                        setEpolicyErrorMsg("");
                    } else {
                        setEpolicyData([]);
                        setFilteredItems([]);
                        setEpolicyErrorMsg("Немає доданих страхових продуктів автоцивілки. Натисніть на кнопку придбати автоцивілку і загрузіть пропозиції, після чого страхові продукти автоцивілки будуть доступні в панелі адміністратора.");
                    }
                } else {
                    setEpolicyData([]);
                    setFilteredItems([]);
                    setEpolicyErrorMsg("Помилка БД! Не вдалося завантажити страхові продукти автоцивілки.");
                }
            } catch (error: any) {
                setEpolicyData([]);
                setFilteredItems([]);
                setEpolicyErrorMsg("Помилка БД! Не вдалося завантажити страхові продукти автоцивілки.");
            }
        };

        const countAllCompanies = async () => {
            try {
                const res = await dbAxiosInstance.get("/epolicy/count-companies");
                const dbCountEpolicyCompaniesData = res.data;

                if (dbCountEpolicyCompaniesData) {
                    if (dbCountEpolicyCompaniesData.length > 0) {
                        setEpolicyCompaniesCount(dbCountEpolicyCompaniesData[0].counter);
                    } else {
                        setEpolicyCompaniesCount(0);
                    }
                } else {
                    setEpolicyCompaniesCount(0);
                }
            } catch (error: any) {
                setEpolicyCompaniesCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };

        fetchEpolicyProducts();
        countAllCompanies();
        checkSearchType();
    }, []);

    const handleItemDelete = async (productID, id) => {
        try {
            await dbAxiosInstance.delete(`/epolicy/company/franchises/${encodeURIComponent(productID)}`);
            await dbAxiosInstance.delete(`/epolicy/company/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            const allRecords = countRecords(epolicyCompaniesCount);
            setEpolicyCompaniesCount(allRecords);

            if (allRecords === 0) {
                setEpolicyErrorMsg("Немає доданих страхових продуктів автоцивілки. Натисніть на кнопку придбати автоцивілку і завантажте пропозиції, після чого страхові продукти автоцивілки будуть доступні в панелі адміністратора.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setEpolicyErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setEpolicyData([]);
        }
    };

    const handleItemVisibility = async (position, id) => {
        try {
            const updatedHiddenState = hiddenState.map((item, index) => {
                return index === position ? !item : item;
            });
            await dbAxiosInstance.put(`/epolicy/edit-company-visibility/${encodeURIComponent(id)}`, {isHidden: updatedHiddenState[position]});
            setHiddenState(updatedHiddenState);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setEpolicyErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setEpolicyData([]);
        }
    };

    const handleRecordsOnPage = useCallback((records) => {
        setRecordsOnPage(records);
    }, []);

    const handleDataLoaded = useCallback((state: boolean) => {
        setFilterCompleted(state);
    }, []);

    const handleFilterState = useCallback((state: boolean) => {
        setProcessingFilter(state);
    }, []);

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><Link to="#" onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></Link></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Автоцивілка</h1>
                    <div className="itemsCounter">
                        <p>Всього: <span className="font-bold">{epolicyCompaniesCount}</span></p>
                    </div>
                    <DataFilter items={epolicyData} matchCase={isMatchCaseFilter} placeholderText="Введіть дані страхової компанії для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом такої компанії Автоцивілки не знайдено!" />
                    <div className="dashboard">
                        <ul className="dashboard-items">
                            {epolicyCompaniesCount > 0 && recordsOnPage.length > 0 ? recordsOnPage.map((epolicyCompany, index) =>
                                <li key={index}>
                                    <div className="manage-item-wrapper">
                                        <div className="manage-item-container">
                                            <CustomTooltip msg="Редагувати">
                                                <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/epolicy/edit-company/${epolicyCompany.productID}/${epolicyCompany.id}`}}>
                                                    <i className="fa fa-pencil-alt"></i>
                                                </Link>
                                            </CustomTooltip>
                                            <CustomTooltip msg={`${hiddenState[index] ? "Показати" : "Приховати"}`}><Link to="#" className="hide-item" onClick={() => handleItemVisibility(index, epolicyCompany.id)}><i className={`fa-solid ${hiddenState[index] ? "fa-eye-slash" : "fa-eye"}`}></i></Link></CustomTooltip>
                                            <CustomTooltip msg="Видалити"><Link to="#" className="delete-item" onClick={() => handleItemDelete(epolicyCompany.productID, epolicyCompany.id)}><i className="fa fa-trash"></i></Link></CustomTooltip>
                                        </div>
                                    </div>
                                    <div className="dashboard-item">                     
                                        <Link to={{pathname: `/admin/dashboard/${id}/${externalID}/epolicy/view-company/${epolicyCompany.productID}/${epolicyCompany.id}`}} key={epolicyCompany.id}>{epolicyCompany.name}</Link>
                                    </div>
                                </li>) : <li><div className="dashboard-error">{epolicyErrorMsg}</div></li>
                            }
                        </ul>
                    </div>
                    <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={handleDataLoaded} isFilterProcessing={processingFilter} setFilterState={handleFilterState} />
                </div>
                </>
            }
            </div>
        </div>
    );
};

export default EpolicyPage;